import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  PLATFORM_ID,
  provideAppInitializer,
} from '@angular/core';
import { NoPreloading, provideRouter, withPreloading } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideStore } from '@ngxs/store';
import { AuthState } from '@xspot-app/customer/auth';
import { UserState } from '@xspot-app/customer/users';
import {
  BasketsState,
  BookingMembersState,
  CALENDARS_STATE_TOKEN,
  CalendarsState,
  OFFERS_STATE_TOKEN,
  OffersState,
  PaymentsState,
  VOUCHER_STATE_TOKEN,
  VoucherState,
} from '@xspot-app/customer/shopping-path';
import { LocationsState } from '@xspot-app/customer/locations';
import { withNgxsResetPlugin } from 'ngxs-reset-plugin';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import {
  AUTH_INTERCEPTOR_CONFIG,
  Base64Util,
  environment,
  FlyspotPreset,
  GlobalErrorHandler,
  LOCAL_STORAGE,
  LocalStorageService,
  SESSION_STORAGE,
  SessionIdService,
  sessionInterceptor,
  TranslocoHttpLoader,
  UiContextService,
  ZeroOrTwoDecimalDigitsPipe,
} from '@xspot-app/common';
import { AuthInterceptor } from '@xspot-app/shared/auth';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
  AvailableCustomerLanguages,
  contextInterceptor,
  defaultLanguage,
  CurrencyState,
} from '@xspot-app/shared/ui';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { MessageService } from 'primeng/api';
import {
  DecimalPipe,
  isPlatformServer,
  registerLocaleData,
} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { StorageOption, withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { providePrimeNG } from 'primeng/config';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

registerLocaleData(localePl, 'pl');

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: FlyspotPreset,
        options: {
          darkModeSelector: false,
          cssLayer: true,
        },
      },
    }),
    {
      provide: LOCAL_STORAGE,
      useFactory: (platformId: object) => {
        if (isPlatformServer(platformId)) {
          return {};
        }
        return localStorage;
      },
      deps: [PLATFORM_ID],
    },
    {
      provide: SESSION_STORAGE,
      useFactory: (platformId: object) => {
        if (isPlatformServer(platformId)) {
          return {};
        }
        return sessionStorage;
      },
      deps: [PLATFORM_ID],
    },
    DecimalPipe,
    ZeroOrTwoDecimalDigitsPipe,
    UiContextService,
    SessionIdService,
    {
      provide: AUTH_INTERCEPTOR_CONFIG,
      useValue: {
        apiEndpointForRefreshToken: `${environment.apiUrl}/api/authorization/RefreshToken`,
      },
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    MessageService,
    provideRouter(appRoutes, withPreloading(NoPreloading)),
    provideHttpClient(
      withFetch(),
      withInterceptors([contextInterceptor, sessionInterceptor]),
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideOAuthClient(),
    provideAnimations(),
    provideStore(
      [
        AuthState,
        UserState,
        CalendarsState,
        OffersState,
        VoucherState,
        CurrencyState,
        PaymentsState,
        LocationsState,
        BasketsState,
        BookingMembersState,
      ],
      {
        developmentMode: isDevMode(),
      },
      withNgxsStoragePlugin({
        keys: [CALENDARS_STATE_TOKEN, OFFERS_STATE_TOKEN, VOUCHER_STATE_TOKEN],
        storage: StorageOption.SessionStorage,
        serialize: state => Base64Util.encode(state),
        deserialize: state => Base64Util.decode(state),
      }),
      withNgxsResetPlugin(),
      withNgxsReduxDevtoolsPlugin()
    ),
    importProvidersFrom(NgxsActionsExecutingModule.forRoot()),
    provideTransloco({
      config: {
        availableLangs: Object.values(AvailableCustomerLanguages),
        defaultLang: defaultLanguage,
        fallbackLang: defaultLanguage,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAppInitializer(() => {
      const translocoService = inject(TranslocoService);
      const localStorageService = inject(LocalStorageService);

      translocoService.setActiveLang(
        localStorageService.getItem('selectedLanguage') ?? defaultLanguage
      );
    }),
    provideEnvironmentNgxMask(),
  ],
};
