import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import { displayDateTime } from '../consts';
import { Nullable } from '../helpers';

@Pipe({
  name: 'localDate',
  standalone: true,
})
export class LocalDatePipe implements PipeTransform {
  public transform(
    value: string,
    format: string = displayDateTime,
    inputFormat: Nullable<string> = null
  ): string {
    const utcValue = inputFormat
      ? DateTime.fromFormat(value, inputFormat, {
          zone: 'utc',
        })
      : DateTime.fromISO(value, { zone: 'utc' });
    return utcValue.isValid ? utcValue.toLocal().toFormat(format) : value;
  }
}
