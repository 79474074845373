import { Injectable, inject } from '@angular/core';
import { environment, UiContextService } from '@xspot-app/common';
import { AuthConfig, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private oAuthService = inject(OAuthService);

  constructor(private uiContextService: UiContextService) {
    this.initConfiguration();
  }

  public login(redirectUri?: string): void {
    this.oAuthService.initLoginFlow(redirectUri);
  }

  public getAdditionalState(): string | undefined {
    return this.oAuthService.state;
  }

  public getProfile(): Record<string, unknown> {
    return this.oAuthService.getIdentityClaims();
  }

  public getIdToken(): string {
    return this.oAuthService.getIdToken();
  }

  public resetImplicitFlow(): void {
    this.oAuthService.resetImplicitFlow();
  }

  public events(): Observable<OAuthEvent> {
    return this.oAuthService.events;
  }

  private initConfiguration(): void {
    const context = this.uiContextService.currentContext$
      .getValue()
      .toLowerCase();
    const redirectUrl = `${window.location.origin}/${context}/googleauth`;
    const authConfig: AuthConfig = {
      issuer: 'https://accounts.google.com',
      strictDiscoveryDocumentValidation: false,
      clientId: environment.googleClientId,
      redirectUri: redirectUrl,
      scope: 'openid profile email',
    };

    this.oAuthService.configure(authConfig);
    this.oAuthService.loadDiscoveryDocumentAndTryLogin();
  }
}
