export const VoucherErrorCodes = {
  NotFound: 'NotFound',
  VoucherExpired: 'VoucherExpired',
  VoucherUsed: 'VoucherUsed',
  VouchersForMoreThanOneOffer: 'VouchersForMoreThanOneOffer',
  VouchersMinutesMaxValueExceeded: 'VouchersMinutesMaxValueExceeded',
  VouchersForDifferentLocations: 'VouchersForDifferentLocations',
} as const;

export const VoucherErrorMessages = {
  [VoucherErrorCodes.NotFound]: 'voucherNotFound',
  [VoucherErrorCodes.VoucherExpired]: 'voucherExpired',
  [VoucherErrorCodes.VoucherUsed]: 'voucherUsed',
  [VoucherErrorCodes.VouchersForMoreThanOneOffer]:
    'vouchersForMoreThanOneOffer',
  [VoucherErrorCodes.VouchersMinutesMaxValueExceeded]:
    'vouchersMinutesMaxValueExceeded',
  [VoucherErrorCodes.VouchersForDifferentLocations]:
    'vouchersForDifferentLocations',
};
