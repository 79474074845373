import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Calendars, Offers, OffersState } from '../../state';
import { Select, Store } from '@ngxs/store';
import { combineLatest, map, Observable, tap } from 'rxjs';
import {
  CustomerOfferDetailsVariantModel,
  CustomerOfferDetailsVariantProductModelExtension,
  VariantProductModel,
} from '../../domain';
import {
  IsItemSelectedDirective,
  LocationControlModel,
  Nullable,
  OfferProductFormEnum,
  XTooltipDirective,
} from '@xspot-app/common';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { LocationDto } from '@xspot-app/shared/locations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VolumePricesComponent } from './volume-prices/volume-prices.component';
import { OfferPromotionComponent } from '../offer-promotion/offer-promotion.component';

@UntilDestroy()
@Component({
  selector: 'xspot-app-offer-configuration',
  standalone: true,
  imports: [
    CommonModule,
    ProductSelectorComponent,
    TranslocoDirective,
    IsItemSelectedDirective,
    XTooltipDirective,
    DropdownModule,
    FormsModule,
    VolumePricesComponent,
    ReactiveFormsModule,
    OfferPromotionComponent,
  ],
  templateUrl: './offer-configuration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferConfigurationComponent {
  protected OfferProductFormEnum = OfferProductFormEnum;
  protected selectedLanguage$ = this.translocoService.langChanges$;
  protected selectedLocation: FormControl<Nullable<LocationControlModel>> =
    new FormControl<Nullable<LocationControlModel>>(null);

  protected locations$ = combineLatest([
    this.store.select(OffersState.offer),
    this.store.select(OffersState.activeLocationId),
  ]).pipe(
    map(([offer, activeLocationId]) => {
      const availableLocations = offer!.locations.map(
        (location: LocationDto) => {
          return {
            id: location.id,
            name: location.name,
          };
        }
      );

      return { availableLocations, activeLocationId };
    }),
    tap(({ availableLocations, activeLocationId }) => {
      const selectedLocation = availableLocations.find(
        x => x.id === activeLocationId
      );
      if (selectedLocation) {
        this.selectedLocation.setValue(selectedLocation);
      }
    }),
    map(({ availableLocations }) => availableLocations)
  );

  protected offerLocationNames$ = combineLatest([
    this.selectedLanguage$,
    this.locations$,
  ]).pipe(
    map(([lang, locations]) =>
      locations.map(location => location.name[lang]).join(', ')
    )
  );

  public variants$: Observable<Nullable<CustomerOfferDetailsVariantModel[]>> =
    this.store.select(OffersState.offer).pipe(
      map(offer => {
        return offer?.variants ?? null;
      })
    );

  public selectedVariant$: Observable<
    Nullable<CustomerOfferDetailsVariantModel>
  > = combineLatest([
    this.store.select(OffersState.offer),
    this.store.select(OffersState.selectedVariantId),
  ]).pipe(
    map(([offer, variantId]) => {
      return offer?.variants?.find(variant => variant.id === variantId) || null;
    })
  );

  public selectedOfferVariantProducts$: Observable<
    CustomerOfferDetailsVariantProductModelExtension[]
  > = this.store
    .select(OffersState.selectedOfferVariantProducts)
    .pipe(map(products => products?.filter(x => x.quantity > 0) || []));

  @Select(OffersState.isCreditOffer)
  public isCreditOffer$!: Observable<boolean>;

  constructor(
    private store: Store,
    private translocoService: TranslocoService
  ) {}

  protected changeVariant(id: string): void {
    const offer = this.store.selectSnapshot(OffersState.offer);
    if (!offer) {
      return;
    }
    this.store.dispatch(new Offers.UpdateSelectedVariantId(id));
    const selectedVariantProducts: VariantProductModel[] = offer.variants
      .find(x => x.id === id)!
      .products.map(product => {
        return {
          id: product.id,
          quantity: product.amount ?? product.startAmount!,
          productKind: product.productKind,
          isReservationPro: product.isMinimalAmount,
        } as VariantProductModel;
      });
    this.store.dispatch(
      new Offers.UpdateSelectedVariantProducts(selectedVariantProducts)
    );
    this.store.dispatch(new Calendars.ResetCalendarState());
    this.store.dispatch(new Offers.ResetSelectedUpsells());
  }

  protected onChangeLocation(event: DropdownChangeEvent): void {
    this.store.dispatch(new Calendars.ResetCalendarState());
    this.store.dispatch(new Offers.ResetSelectedUpsells());
    this.store.dispatch(new Offers.UpdateActiveLocationId(event.value.id));
  }
}
