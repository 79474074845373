import { Nullable } from '@xspot-app/common';
import { CalendarTypeEnum } from '../../enums';
import {
  CalendarDaySlotModel,
  CalendarDaysModel,
  SelectedSlotModel,
} from '../../domain';
import { CalendarStepEnum } from '../../enums/calendar-step.enum';
import { DateTime } from 'luxon';

export interface CalendarsStateModel {
  currentStep: CalendarStepEnum;
  type: Nullable<CalendarTypeEnum>;
  days: Nullable<CalendarDaysModel>;
  daySlots: Nullable<CalendarDaySlotModel[]>;
  selectedDate: Nullable<DateTime>;
  currentYearMonth: DateTime;
  selectedSlots: SelectedSlotModel[];
  minSlotMinutes: number;
}
