import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { OffersService } from '../../services';
import { OffersStateModel } from './offers.state-model';
import {
  CustomerOfferDto,
  GetSelectedProductsMinimalPriceDto,
  UpsellDto,
} from '../../dtos';
import { Offers } from './offers.actions';
import { map, Observable, tap } from 'rxjs';
import {
  Nullable,
  OfferCategoryEnum,
  OfferProductPriceKindEnum,
  ProductKindEnum,
  StringUtil,
} from '@xspot-app/common';
import {
  CustomerOfferModel,
  CurrentPriceModel,
  CustomerUpsellModel,
  SelectedUpsellModel,
  CalendarDaySlotModel,
  OfferPromotionModel,
} from '../../domain';
import {
  CustomerOfferDetailsModel,
  CustomerOfferDetailsVariantModel,
  CustomerOfferDetailsVariantProductModelExtension,
} from '../../domain/customer-offer-details.model';
import { CustomerOfferModelMapper } from '../../mappers/customer-offer-model.mapper';
import { ShoppingPathStepEnum } from '../../enums';
import { VariantProductModel } from '../../domain/variant-product.model';
import { LocationDto } from '@xspot-app/shared/locations';
import { Calendars, CalendarsState } from '../calendars';
import { DateTime } from 'luxon';

export const OFFERS_STATE_TOKEN = new StateToken<OffersStateModel>('offers');

const defaultStateValue: OffersStateModel = {
  currentStep: ShoppingPathStepEnum.Card,
  offers: [],
  activeLocationId: StringUtil.empty,
  activeCategory: OfferCategoryEnum.Beginner,
  offer: null,
  selectedVariantId: StringUtil.empty,
  selectedVariantProducts: [],
  currentPrice: {
    original: 0,
    actual: 0,
    isPerMinute: false,
    isPriceFrom: false,
  },
  basketItemId: null,
  upsells: [],
  promotions: [],
  selectedUpsells: [],
};

@State<OffersStateModel>({
  name: OFFERS_STATE_TOKEN,
  defaults: defaultStateValue,
})
@Injectable()
export class OffersState {
  constructor(
    private offersService: OffersService,
    private store: Store
  ) {}

  @Selector()
  public static currentStep(state: OffersStateModel): ShoppingPathStepEnum {
    return state.currentStep;
  }

  @Selector()
  public static offers(state: OffersStateModel): CustomerOfferModel[] {
    return state.offers;
  }

  @Selector()
  public static activeLocationId(state: OffersStateModel): string {
    return state.activeLocationId;
  }

  @Selector()
  public static activeLocation(state: OffersStateModel): Nullable<LocationDto> {
    const activeLocation = this.activeLocationId(state);
    if (!activeLocation) {
      return null;
    }
    return state.offer?.locations.find(x => x.id === activeLocation) ?? null;
  }

  @Selector()
  public static selectedVariantId(state: OffersStateModel): string {
    return state.selectedVariantId;
  }

  @Selector()
  public static promotions(state: OffersStateModel): OfferPromotionModel[] {
    return state.promotions;
  }

  @Selector()
  public static selectedVariant(
    state: OffersStateModel
  ): Nullable<CustomerOfferDetailsVariantModel> {
    const selectedVariantId = state.selectedVariantId;
    const offer = state.offer;
    if (!selectedVariantId || !offer) {
      return null;
    }
    return offer.variants.find(x => x.id === selectedVariantId) ?? null;
  }

  @Selector()
  public static selectedVariantProducts(
    state: OffersStateModel
  ): VariantProductModel[] {
    return state.selectedVariantProducts;
  }

  @Selector()
  public static currentPrice(state: OffersStateModel): CurrentPriceModel {
    return state.currentPrice;
  }

  @Selector()
  public static selectedOfferVariantProducts(
    state: OffersStateModel
  ): Nullable<CustomerOfferDetailsVariantProductModelExtension[]> {
    const selectedVariant = this.selectedVariant(state);
    if (!selectedVariant) {
      return null;
    }
    const selectedProductIdsWithAmount = state.selectedVariantProducts.map(
      x => ({
        id: x.id,
        quantity: x.quantity,
      })
    );
    const selectedProducts = selectedVariant.products
      .filter(product =>
        selectedProductIdsWithAmount.some(x => x.id === product.id)
      )
      .map(product => {
        const quantity =
          selectedProductIdsWithAmount.find(x => x.id === product.id)
            ?.quantity ?? 0;
        return {
          ...product,
          quantity,
        } as CustomerOfferDetailsVariantProductModelExtension;
      });

    return selectedProducts;
  }

  @Selector()
  public static offer(
    state: OffersStateModel
  ): Nullable<CustomerOfferDetailsModel> {
    return state.offer;
  }

  @Selector()
  public static basketItemId(state: OffersStateModel): Nullable<string> {
    return state.basketItemId;
  }

  @Selector()
  public static activeCategory(state: OffersStateModel): OfferCategoryEnum {
    return state.activeCategory;
  }

  @Selector()
  public static upsells(state: OffersStateModel): CustomerUpsellModel[] {
    return state.upsells;
  }

  @Selector()
  public static selectedUpsells(
    state: OffersStateModel
  ): SelectedUpsellModel[] {
    return state.selectedUpsells;
  }

  @Selector()
  public static isCreditOffer(state: OffersStateModel): boolean {
    return state.selectedVariantProducts.some(
      product => product.productKind === ProductKindEnum.Credit
    );
  }

  @Action(Offers.UpdateCurrentStep, {
    cancelUncompleted: true,
  })
  public updateCurrentStep(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateCurrentStep
  ): void {
    ctx.patchState({ currentStep: action.payload });
  }

  @Action(Offers.UpdateActiveLocationId, {
    cancelUncompleted: true,
  })
  public updateActiveLocationId(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateActiveLocationId
  ): void {
    ctx.patchState({ activeLocationId: action.payload });
  }

  @Action(Offers.GetActiveOffers)
  public getActiveOffers(
    ctx: StateContext<OffersStateModel>
  ): Observable<CustomerOfferDto[]> {
    return this.offersService.getActiveOffers().pipe(
      tap(data => {
        ctx.patchState({
          offers: data,
        });
      })
    );
  }

  @Action(Offers.UpdateActiveCategory, {
    cancelUncompleted: true,
  })
  public updateActiveCategory(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateActiveCategory
  ): void {
    ctx.patchState({ activeCategory: action.payload });
  }

  @Action(Offers.GetPromotions, {
    cancelUncompleted: true,
  })
  public getPromotion(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetPromotions
  ): Observable<OfferPromotionModel[]> {
    return this.offersService.getOfferPromotion(action.offerId).pipe(
      tap(model => {
        ctx.patchState({
          promotions: model,
        });
      })
    );
  }

  @Action(Offers.GetOffer)
  public getOffer(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOffer
  ): Observable<CustomerOfferDetailsModel> {
    return this.offersService.getOffer(action.id).pipe(
      map(dto => {
        return CustomerOfferModelMapper.map(dto);
      }),
      tap(model => {
        if (model.id !== ctx.getState().offer?.id) {
          ctx.dispatch([
            new Offers.ResetOfferConfiguration(),
            new Calendars.ResetCalendarState(),
          ]);
        }

        ctx.patchState({
          offer: model,
        });
      })
    );
  }

  @Action(Offers.UpdateSelectedVariantProductAmount)
  public updateSelectedVariantProductAmount(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateSelectedVariantProductAmount
  ): void {
    const state = ctx.getState();

    const updatedProducts = state.selectedVariantProducts.map(product => {
      if (product.id === action.productId) {
        return {
          ...product,
          quantity: action.value,
        };
      }
      return product;
    });

    ctx.patchState({
      selectedVariantProducts: updatedProducts,
    });
  }

  @Action(Offers.UpdateSelectedVariantId, {
    cancelUncompleted: true,
  })
  public updateSelectedVariant(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateSelectedVariantId
  ): void {
    ctx.patchState({ selectedVariantId: action.payload });
  }

  @Action(Offers.UpdateSelectedVariantProducts, {
    cancelUncompleted: true,
  })
  public updateSelectedVariantProducts(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateSelectedVariantProducts
  ): void {
    ctx.patchState({ selectedVariantProducts: action.payload });
  }

  @Action(Offers.UpdateBasketItemId, {
    cancelUncompleted: true,
  })
  public updateBasketItemId(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateBasketItemId
  ): void {
    ctx.patchState({ basketItemId: action.payload });
  }

  @Action(Offers.UpdateCurrentPrice)
  public updateCurrentPrice(ctx: StateContext<OffersStateModel>): void {
    const state = ctx.getState();
    const selectedProducts = state.selectedVariantProducts.filter(
      x => x.quantity > 0
    );
    if (!selectedProducts || selectedProducts.length === 0) {
      ctx.patchState({
        currentPrice: {
          original: 0,
          actual: 0,
          isPerMinute: false,
          isPriceFrom: false,
        },
      });
      return;
    }
    const dto: GetSelectedProductsMinimalPriceDto = {
      offerVariantId: state.selectedVariantId,
      selectedProducts: selectedProducts.map(x => ({
        offerVariantProductId: x.id,
        quantity: x.quantity,
      })),
    };
    const selectedVariantProducts = OffersState.selectedOfferVariantProducts(
      state
    )!.filter(x => x.quantity > 0);
    const upsellsPrice = state.selectedUpsells.reduce((total, upsell) => {
      return total + upsell.pricePerUpsell * upsell.quantity;
    }, 0);
    const selectedSlots: Nullable<CalendarDaySlotModel[]> =
      this.store.selectSnapshot(CalendarsState).selectedSlots;
    const hasSelectedSlots = selectedSlots && selectedSlots?.length > 0;
    const originalPriceFromSelectedSlots = hasSelectedSlots
      ? selectedSlots.reduce((sum, slot) => sum + (slot.originalPrice ?? 0), 0)
      : null;
    const actualPriceFromSelectedSlots = hasSelectedSlots
      ? selectedSlots.reduce((sum, slot) => sum + (slot.actualPrice ?? 0), 0)
      : null;
    const isPricePerMinute =
      (selectedVariantProducts.some(x => x.isMinimalAmount) &&
        !hasSelectedSlots) ??
      false;
    const isPriceFrom =
      selectedVariantProducts.some(
        x => x.priceKind === OfferProductPriceKindEnum.Segments
      ) && !hasSelectedSlots;

    this.offersService
      .getMinimalPrice(state.offer!.id, dto)
      .pipe(
        tap(data => {
          ctx.patchState({
            currentPrice: {
              original:
                (originalPriceFromSelectedSlots ?? data.original) +
                upsellsPrice,
              actual:
                (actualPriceFromSelectedSlots ?? data.actual) + upsellsPrice,
              isPerMinute: isPricePerMinute,
              isPriceFrom: isPriceFrom,
            },
          });
        })
      )
      .subscribe();
  }

  @Action(Offers.GetOfferUpsells)
  public getOfferUpsells(
    ctx: StateContext<OffersStateModel>,
    action: Offers.GetOfferUpsells
  ): Observable<UpsellDto[]> {
    return this.offersService.getOfferUpsells(action.offerId).pipe(
      tap(data => {
        ctx.patchState({
          upsells: data,
        });
      })
    );
  }

  @Action(Offers.UpdateSelectedUpsells, {
    cancelUncompleted: true,
  })
  public updateSelectedUpsells(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateSelectedUpsells
  ): void {
    ctx.patchState({ selectedUpsells: action.payload });
  }

  @Action(Offers.ResetSelectedUpsells)
  public resetSelectedUpsells(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({ selectedUpsells: [] });
  }

  @Action(Offers.ResetOfferConfiguration)
  public resetOfferConfiguration(ctx: StateContext<OffersStateModel>): void {
    ctx.patchState({
      currentStep: ShoppingPathStepEnum.Card,
      selectedVariantId: StringUtil.empty,
      selectedVariantProducts: [],
      basketItemId: null,
    });
  }

  @Action(Offers.UpdateFromShareLinkModel, {
    cancelUncompleted: true,
  })
  public updateFromShareLinkModel(
    ctx: StateContext<OffersStateModel>,
    action: Offers.UpdateFromShareLinkModel
  ): void {
    ctx
      .dispatch([
        new Offers.ResetOfferConfiguration(),
        new Offers.ResetSelectedUpsells(),
        new Calendars.ResetCalendarState(),
      ])
      .subscribe(() => {
        ctx.patchState({ ...action.payload });

        const selectedDate = action.payload.selectedDate
          ? DateTime.fromISO(action.payload.selectedDate)
          : null;

        ctx.dispatch(new Calendars.UpdateSelectedDate(selectedDate));
      });
  }
}
