import { Language } from '../../enums';

export interface CreateUserDto {
  email: string;
  password: string;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  termsOfServiceAgreed: boolean;
  marketingAgreed: boolean;
  language: Language;
}
