import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { map, switchMap, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookAuthService } from '@xspot-app/shared/auth';
import {
  PlatformDetectorService,
  SessionIdService,
  UiContext,
  UiContextService,
} from '@xspot-app/common';
import { TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Meta, Title } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, ToastModule],
  selector: 'xspot-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private uiContextService: UiContextService,
    private facebookAuthService: FacebookAuthService,
    private platformDetector: PlatformDetectorService,
    private primeNgConfig: PrimeNGConfig,
    private translocoService: TranslocoService,
    private sessionIdService: SessionIdService,
    private titleService: Title,
    private metaService: Meta
  ) {}

  public ngOnInit(): void {
    this.facebookAuthService.initializeFacebookSDK();

    if (this.platformDetector.isIos()) {
      document.body.classList.add('is-iOS');
    }

    this.translocoService.langChanges$
      .pipe(
        untilDestroyed(this),
        tap(lang => {
          document.documentElement.lang = lang;
        }),
        switchMap(() => this.uiContextService.currentContext$),
        map(context => context.toLowerCase()),
        switchMap(context =>
          this.translocoService.selectTranslateObject('head').pipe(
            tap(head => {
              this.titleService.setTitle(head.title[context]);
              this.metaService.updateTag({
                name: 'description',
                content: head.metaDescription[context],
              });
            })
          )
        ),
        switchMap(() => this.translocoService.selectTranslateObject('primeng'))
      )
      .subscribe(translation => this.primeNgConfig.setTranslation(translation));

    this.sessionIdService.initializeSessionId();
  }

  public changeTheme(): void {
    this.uiContextService.currentContext$
      .pipe(take(1), untilDestroyed(this))
      .subscribe(context => {
        switch (context) {
          case UiContext.Deepspot:
            this.uiContextService.switchContext(UiContext.Flyspot);
            break;
          case UiContext.Flyspot:
            this.uiContextService.switchContext(UiContext.Deepspot);
            break;
          default:
            throw new Error(`Unknown context ${context}`);
        }
      });
  }
}
