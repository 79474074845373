import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { StringUtil } from '@xspot-app/common';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-weekday-abbreviations',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './weekday-abbreviations.component.html',
  styleUrl: './weekday-abbreviations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekdayAbbreviationsComponent {
  @Input() public className: string = StringUtil.empty;
}
