<ng-container *transloco="let t">
  <ng-container *ngIf="calendarType$ | async as calendarType">
    <ng-container *ngLet="isLoading$ | async as isLoading">
      @if (slotsData.length > 0) {
        <div class="time-slots">
          <span class="text-sm py-1 block lg:hidden text-700 font-semibold">{{
            t('calendar.chooseHour')
          }}</span>
          @for (slot of slotsData; track slot) {
            <ng-container
              *ngLet="getSelectedSlotModel(slot) as selectedSlotModel">
              <div
                class="time-slot relative"
                (click)="selectSlot(slot, $event)"
                [isItemSelected]="selectedSlotModel !== null"
                [ngClass]="{
                  'time-slot--unavailable':
                    slot.availableMinutes === 0 || !slot.isAvailable,
                  'time-slot--loading': isLoading,
                  'bg-primary-50': selectedSlotModel !== null,
                }">
                <div
                  class="flex justify-content-between w-full text-700 font-semibold lg:w-auto lg:flex-column">
                  <span class="text-oswald flex gap-2"
                    >{{ slot.from | timeFormat }}-{{ slot.to | timeFormat }}
                    <div class="icons lg:absolute">
                      <i
                        *ngIf="
                          slot.isNightHours &&
                          calendarType === CalendarTypeEnum.FlyspotPro
                        "
                        class="pi pi-moon"
                        [xTooltip]="t('calendar.nightFly')"></i>
                      <i
                        *ngIf="slot.productPromotions.length"
                        class="pi pi-gift text-primary"></i>
                    </div>
                  </span>
                  <span
                    *ngIf="slot.originalPrice && slot.originalPrice > 0"
                    class="text-center text-sm text-600 font-semibold lg:mt-1">
                    <xspot-app-slot-price
                      [originalPrice]="
                        selectedSlotModel?.originalPrice ?? slot.originalPrice
                      "
                      [actualPrice]="
                        selectedSlotModel?.actualPrice ?? slot.actualPrice
                      "
                      [isSlotSelected]="selectedSlotModel !== null" />
                  </span>
                </div>

                <div
                  *ngIf="calendarType === CalendarTypeEnum.FlyspotPro"
                  class="flex flex-row lg:flex-column align-items-center gap-2 mt-2 lg:mt-0 lg:gap-0">
                  <ng-container
                    [ngTemplateOutlet]="slotCapacity"
                    [ngTemplateOutletContext]="{
                      slot,
                      selectedMinutes: selectedSlotModel?.minutes,
                    }"></ng-container>
                </div>
              </div>
            </ng-container>

            <ng-container [ngTemplateOutlet]="slotOverlay"></ng-container>
          }
          <xspot-app-offer-promotion
            styleClass="lg:hidden offer-promotion-button"
            [filterDate]="filterDate" />
        </div>
      } @else {
        <div class="empty-slots mt-5">
          <h2>{{ t('calendar.noAvailableSlots') }}</h2>
          <span>{{ t('calendar.chooseOtherDay') }}</span>
        </div>
      }
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #slotOverlay>
  <ng-container *transloco="let t">
    <p-overlayPanel
      #calendarFlyspotProOverlay
      styleClass="border-round-2xl offer-item-mobile"
      class="hidden">
      <ng-container *ngIf="calendarFlyspotProOverlaySelectedSlot !== null">
        <ng-container *ngIf="minSlotMinutes$ | async as minSlotMinutes">
          <div
            class="flex lg:hidden justify-content-center align-items-center flex-column w-full text-oswald text-700 text-xl font-semibold relative">
            <p-button
              [rounded]="true"
              [text]="true"
              class="close-overlay-button"
              styleClass="text-2xl"
              severity="secondary"
              icon="pi pi-times"
              (click)="calendarFlyspotProOverlay.toggle($event)">
            </p-button>
            <div class="mb-2 flex align-items-center">
              {{
                calendarFlyspotProOverlaySelectedSlot.date.toFormat(
                  'dd.MM.yyyy'
                )
              }},
              {{ calendarFlyspotProOverlaySelectedSlot.from | timeFormat }}-{{
                calendarFlyspotProOverlaySelectedSlot.to | timeFormat
              }}
              <ng-container
                *ngIf="calendarFlyspotProOverlaySelectedSlot.isNightHours">
                <i
                  class="lg:absolute lg:left-100 ml-1 pi pi-moon"
                  [xTooltip]="t('calendar.nightFly')"></i>
              </ng-container>
            </div>
            <div class="flex align-items-center gap-2 mb-4">
              <div class="text-sm font-normal">
                {{ calendarFlyspotProOverlaySelectedSlot.availableMinutes }}
                {{ t('calendar.ofMinutes', { minutes: maxQuantity }) }}
              </div>
              <div class="w-5rem relative" [style]="">
                <p-progressBar
                  [showValue]="false"
                  color="#76DB9B"
                  class="offer-time-progress-bar"
                  styleClass="border-round-md "
                  [value]="
                    calendarFlyspotProOverlaySelectedSlot.availableMinutes
                      ? (calendarFlyspotProOverlaySelectedSlot.availableMinutes /
                          maxQuantity) *
                        100
                      : 0
                  "
                  [style]="{ height: '4px' }"></p-progressBar>
              </div>
            </div>
          </div>
          <div class="flex flex-column align-items-center">
            <div class="flex gap-1">
              <button
                pButton
                label="10"
                class="border-round-3xl px-3 py-2 text-center text-sm border-2"
                [outlined]="true"
                severity="secondary"
                [disabled]="
                  calendarFlyspotProOverlaySelectedSlot.availableMinutes! <
                    10 || minSlotMinutes > 10
                "
                (click)="calendarFlyspotProOverlayInputValue = 10"></button>
              <button
                pButton
                label="15"
                [outlined]="true"
                severity="secondary"
                class="border-round-3xl px-3 py-2 text-center text-sm border-2"
                [disabled]="
                  calendarFlyspotProOverlaySelectedSlot.availableMinutes! <
                    15 || minSlotMinutes > 15
                "
                (click)="calendarFlyspotProOverlayInputValue = 15"></button>
              <button
                pButton
                label="Max"
                class="border-round-3xl px-3 py-2 text-center text-sm border-2"
                [outlined]="true"
                severity="secondary"
                [disabled]="
                  calendarFlyspotProOverlaySelectedSlot.availableMinutes! <
                    30 || minSlotMinutes > 30
                "
                (click)="calendarFlyspotProOverlayInputValue = 30"></button>
            </div>
            <p-divider class="px-4 lg:px-0 w-full"></p-divider>
            <div class="flex gap-2 align-items-center">
              <i
                class="minute-control-button pi pi-minus-circle"
                (click)="
                  calendarFlyspotProOverlayInputValue =
                    calendarFlyspotProOverlayInputValue - 1
                "
                [class.disabled]="
                  minSlotMinutes > calendarFlyspotProOverlayInputValue - 1
                "></i>
              <p-inputNumber
                [(ngModel)]="calendarFlyspotProOverlayInputValue"
                [suffix]="' ' + t('calendar.min')"
                [min]="minSlotMinutes"
                [max]="
                  calendarFlyspotProOverlaySelectedSlot.availableMinutes
                "></p-inputNumber>
              <i
                class="minute-control-button pi pi-plus-circle"
                (click)="
                  calendarFlyspotProOverlayInputValue =
                    calendarFlyspotProOverlayInputValue + 1
                "
                [class.disabled]="
                  calendarFlyspotProOverlaySelectedSlot.availableMinutes! <
                  calendarFlyspotProOverlayInputValue + 1
                "></i>
            </div>
            <button
              pButton
              class="mt-1 p-2 m-auto choose-button hidden lg:block underline"
              label="{{ t('calendar.choose') }}"
              (click)="
                addToSelectedSlots(
                  calendarFlyspotProOverlaySelectedSlot,
                  calendarFlyspotProOverlayInputValue
                );
                calendarFlyspotProOverlay.toggle($event)
              "
              [text]="true"></button>
            <p-button
              type="button"
              styleClass="w-full"
              class="lg:hidden w-full mt-4"
              label="{{ t('calendar.choose') }}"
              (click)="
                addToSelectedSlots(
                  calendarFlyspotProOverlaySelectedSlot,
                  calendarFlyspotProOverlayInputValue
                );
                calendarFlyspotProOverlay.toggle($event)
              "></p-button>
          </div>
        </ng-container>
      </ng-container>
    </p-overlayPanel>
  </ng-container>
</ng-template>

<ng-template
  #slotCapacity
  let-slot="slot"
  let-selectedMinutes="selectedMinutes">
  <ng-container *ngIf="asCalendarDaySlotModel(slot) as slot">
    <ng-container *transloco="let t">
      <div class="block text-center text-600 text-xs lg:mt-1">
        @if (selectedMinutes) {
          {{ selectedMinutes + ' ' + t('calendar.min') }}
        } @else {
          {{
            slot.availableMinutes +
              ' ' +
              t('calendar.ofMinutes', { minutes: maxQuantity })
          }}
        }
      </div>

      <div
        class="w-5rem relative lg:mt-1"
        [style]="{
          '--selected-minutes':
            ((selectedMinutes ?? 0) / maxQuantity) * 100 + '%',
        }">
        <p-progressBar
          [showValue]="false"
          color="#76DB9B"
          class="offer-time-progress-bar"
          styleClass="border-round-md "
          [value]="
            slot.availableMinutes
              ? (slot.availableMinutes / maxQuantity) * 100
              : 0
          "
          [style]="{ height: '4px' }"></p-progressBar>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
