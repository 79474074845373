<div *transloco="let t" class="w-full h-full flex flex-column gap-3">
  <p-table
    [value]="(files$ | async)!"
    [reorderableColumns]="true"
    (onRowReorder)="onRowReorderHandler()">
    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th class="w-2">{{ t('ui.fileUpload.id') }}</th>
        <th class="w-full">{{ t('ui.fileUpload.name') }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-element let-index="rowIndex">
      <tr [pReorderableRow]="index">
        <td>
          <i *ngIf="!disabled" class="pi pi-bars" pReorderableRowHandle></i>
        </td>
        <td class="font-semibold">{{ element.ordinalNumber + 1 }}.</td>
        <td>{{ element.originalFileName }}</td>
        <td class="flex">
          <p-button
            severity="secondary"
            [rounded]="true"
            [text]="true"
            icon="pi pi-download text-900"
            (onClick)="downloadFile(element)"></p-button>
          <p-button
            *ngIf="!disabled"
            severity="secondary"
            [rounded]="true"
            [text]="true"
            icon="pi pi-trash text-900"
            (onClick)="removeLegalFile(element)"></p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr *ngIf="disabled">
        <td colspan="4" class="text-center p-4">
          {{ t('ui.fileUpload.noFiles') }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <input
    #inputLegalFile
    type="file"
    (change)="uploadLegalFiles($event)"
    class="hidden"
    accept=".pdf" />
  <p-button
    *ngIf="!disabled"
    class="align-self-end"
    type="button"
    icon="pi pi-plus"
    (click)="inputLegalFile.click()"
    [label]="t('ui.fileUpload.addFile')"></p-button>
  <small class="text-error" *ngIf="errorMessage$ | async as errorMessage">
    {{ t('ui.' + errorMessage.summary, { MaxFileSize: MaxFileSize_MB }) }}
  </small>
</div>
