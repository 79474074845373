<ng-container *transloco="let t">
  <a
    href="{{ EXTERNAL_URL.flyspotShoper }}"
    target="_blank"
    [class.voucher-sticky]="isSticky">
    <div class="voucher">
      <div class="line-height-3 px-3">
        <i class="voucher-outline-icon vertical-align-middle"></i>
      </div>
      <div class="line-height-3 vertical-align-middle white-space-nowrap">
        {{ t('common.voucher.buy') }}
      </div>
      <div class="line-height-3 vertical-align-bottom px-3">
        <i class="pi pi-arrow-right vertical-align-middle"></i>
      </div>
    </div>
  </a>
</ng-container>
