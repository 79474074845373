export interface GetCalendarDaySlotsDto {
  offerId: string;
  offerVariantId: string;
  locationId: string;
  date: string;
  offerVariantProducts: GetCalendarDaySlotsOfferVariantProductDto[];
}

export interface GetCalendarDaySlotsOfferVariantProductDto {
  id: string;
  quantity: number;
}
