<ng-container *transloco="let t">
  <div class="google-login-button">
    <p-button
      styleClass="gap-1"
      (onClick)="logInWithGoogle()"
      [loading]="loading"
      [label]="t('loginForm.google')"
      icon="pi pi-google"
      [outlined]="true"
      severity="secondary"></p-button>
  </div>
</ng-container>
