import { Nullable, OfferCategoryEnum } from '@xspot-app/common';
import { ShoppingPathStepEnum } from '../../enums';
import { VariantProductModel } from '../../domain/variant-product.model';
import { SelectedUpsellModel, ShareLinkModel } from '../../domain';

export namespace Offers {
  export class GetActiveOffers {
    public static readonly type = '[Offers] Get Active Offers';
  }

  export class GetOffer {
    public static readonly type = '[Offers] Get Offer';
    constructor(public id: string) {}
  }

  export class UpdateCurrentStep {
    public static readonly type = '[Offers] Update Current Step';
    constructor(public payload: ShoppingPathStepEnum) {}
  }

  export class GetPromotions {
    public static readonly type = '[Offers] Get Promotion';
    constructor(public offerId: string) {}
  }

  export class UpdateActiveLocationId {
    public static readonly type = '[Offers] Update Active Location Id';
    constructor(public payload: string) {}
  }

  export class UpdateActiveCategory {
    public static readonly type = '[Offers] Update Active Category';
    constructor(public payload: OfferCategoryEnum) {}
  }

  export class UpdateSelectedVariantId {
    public static readonly type = '[Offers] Update Selected Variant Id';
    constructor(public payload: string) {}
  }

  export class UpdateSelectedVariantProducts {
    public static readonly type = '[Offers] Update Selected Variant Products';
    constructor(public payload: VariantProductModel[]) {}
  }

  export class UpdateSelectedVariantProductAmount {
    public static readonly type =
      '[Offers] Update Selected Variant Product Amount';
    constructor(
      public productId: string,
      public value: number
    ) {}
  }

  export class UpdateBasketItemId {
    public static readonly type = '[Offers] Update Basket Item Id';
    constructor(public payload: Nullable<string>) {}
  }

  export class UpdateCurrentPrice {
    public static readonly type = '[Offers] Update Current Price';
  }

  export class GetOfferUpsells {
    public static readonly type = '[Offers] Get Offer Upsells';
    constructor(public offerId: string) {}
  }

  export class UpdateSelectedUpsells {
    public static readonly type = '[Offers] Update Selected Upsells';
    constructor(public payload: SelectedUpsellModel[]) {}
  }

  export class ResetSelectedUpsells {
    public static readonly type = '[Offers] Reset Selected Upsells';
  }

  export class ResetOfferConfiguration {
    public static readonly type = '[Offers] Reset Offer Configuration';
  }

  export class UpdateFromShareLinkModel {
    public static readonly type = '[Offers] Update From Share Link Model';
    constructor(public payload: ShareLinkModel) {}
  }
}
