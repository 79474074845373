import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnChanges,
  forwardRef,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControlErrorsComponent, SearchBarComponent } from './../';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import {
  SelectOption,
  SortOrder,
  StringUtil,
  dataTableDefaults,
} from '@xspot-app/common';
import { Store } from '@ngxs/store';
import { Nullable } from 'primeng/ts-helpers';
import { OverlayListenerOptions, OverlayOptions } from 'primeng/api';
// TODO: fix it
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  GetOfferListDto,
  Offers,
  OfferDto,
  OfferSortColumn,
} from '@xspot-app/back-office/offers';

@UntilDestroy()
@Component({
  selector: 'xspot-app-offer-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    SearchBarComponent,
    FormControlErrorsComponent,
    TranslocoDirective,
  ],
  templateUrl: './offer-dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OfferDropdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OfferDropdownComponent),
      multi: true,
    },
  ],
})
export class OfferDropdownComponent
  implements ControlValueAccessor, Validator, OnChanges
{
  @ViewChild('dropdown') private dropdown!: Dropdown;
  @Input() public formControl!: FormControl<Nullable<string>>;
  @Input() public initialOption!: SelectOption;

  public onChange: (value: Nullable<string>) => void = () => {};
  public onTouched: (value: Nullable<string>) => void = () => {};

  public options: SelectOption[] = [];
  public filter: GetOfferListDto = {
    pageSize: dataTableDefaults.pageSize,
    pageNumber: dataTableDefaults.pageNumber,
    sortOrder: SortOrder.Descending,
    orderBy: OfferSortColumn.Name,
    filter: { searchQuery: StringUtil.empty },
  };

  constructor(private store: Store) {
    this.fetchData();
  }

  public loadLazy($event: { last: number; first: number }): void {
    if ($event.last >= this.filter.pageSize * this.filter.pageNumber) {
      this.filter.pageNumber++;
      this.fetchData();
    }
  }

  public fetchData(options?: SelectOption[]): void {
    const offers = options ? options : [...this.options];
    this.store
      .dispatch(new Offers.GetAll(this.filter))
      .pipe(untilDestroyed(this))
      .subscribe(data => {
        const loadedOffers: SelectOption[] =
          data.offers?.all?.items.map((offer: OfferDto) => {
            return {
              label: offer.name,
              value: offer.id,
            };
          }) || [];
        const filteredOffers = loadedOffers.filter(
          x => !offers.some(u => u.value === x.value)
        );

        this.options = [...offers, ...filteredOffers];
      });
  }

  public search(searchQuery: string): void {
    this.dropdown.scroller?.scrollToIndex(0);
    this.filter.filter.searchQuery = searchQuery;
    this.filter.pageNumber = 1;
    const options = this.options.filter(
      x => x.value === this.formControl.value
    );

    this.fetchData(options);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialOption'] && this.initialOption) {
      this.fetchData([this.initialOption]);
    }
  }

  //Prevents dropdown from closing while scrolling the page
  public getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type === 'scroll') {
          return false;
        }
        return options?.valid;
      },
    };
  }

  public validate(): ValidationErrors | null {
    return this.formControl.valid
      ? null
      : {
          invalidForm: {
            valid: false,
            message: 'Invalid offer dropdown',
          },
        };
  }

  public registerOnChange(fn: (value: Nullable<string>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (value: Nullable<string>) => void): void {
    this.onTouched = fn;
  }

  public writeValue(data: Nullable<string>): void {
    if (this.formControl && this.formControl.value != data) {
      this.formControl.setValue(data, { emitEvent: false });
    }
  }
}
