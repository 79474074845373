<ng-container *transloco="let t">
  <ng-container>
    <div class="bg-white">
      <div class="week-navigation pb-4">
        <div
          class="text-base xl:text-2xl text-oswald font-semibold flex justify-content-center align-items-center gap-2">
          <ng-container *ngIf="currentYearMonth$ | async as currentYearMonth">
            <span>
              {{
                t(
                  'calendar.months.' + Months[currentYearMonth.month - 1]
                    | lowercase
                ) | uppercase
              }}
              {{ currentYearMonth.year }}
            </span>
          </ng-container>

          <p-button
            icon="pi pi-chevron-down"
            [rounded]="true"
            [text]="true"
            severity="secondary"
            (click)="op.toggle($event)"></p-button>

          <p-overlayPanel #op styleClass="border-round-2xl">
            <p-calendar
              appendTo="body"
              [inline]="true"
              [defaultDate]="calendarMinAndDefaultDate"
              [minDate]="calendarMinAndDefaultDate"
              (onSelect)="onSelectDate($event); op.toggle($event)"></p-calendar>
          </p-overlayPanel>
        </div>
      </div>

      <xspot-app-weekday-abbreviations
        [className]="'px-3'"></xspot-app-weekday-abbreviations>

      <div class="week-days w-full mb-3">
        <div class="h-full flex align-items-center">
          <button
            class="flex align-items-center px-0 bg-white text-color-secondary text-left text-sm border-none cursor-pointer"
            (click)="changeWeek(-1)">
            <i class="pi pi-arrow-left"></i>
          </button>
        </div>

        <ng-container *ngIf="calendarDays$ | async as calendarDays">
          <ng-container *ngIf="calendarType$ | async as calendarType">
            <xspot-app-calendar-day
              *ngFor="let day of calendarDays.days.slice(0, 7)"
              [calendarDay]="day"
              (calendarDayClick)="onSelectDay(day)"></xspot-app-calendar-day>
          </ng-container>
        </ng-container>

        <div class="h-full flex align-items-center">
          <button
            class="flex align-items-center px-0 bg-white text-color-secondary text-left text-sm border-none cursor-pointer"
            (click)="changeWeek(1)">
            <i class="pi pi-arrow-right"></i>
          </button>
        </div>
      </div>

      <div *ngIf="calendarDaySlots$ | async as daySlots; else spinner">
        <div *ngIf="daySlots !== null; else spinner">
          <xspot-app-time-slots
            [filterDate]="filterDate"
            [slotsData]="daySlots"></xspot-app-time-slots>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #spinner>
  <div class="justify-content-center flex h-19rem align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
