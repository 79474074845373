import { produce } from 'immer';
import { LanguageControlGroup } from '@xspot-app/common';
import { CustomerLocationsListDto } from '@xspot-app/customer/locations-api';
import { InvalidBasketItemDto } from '../dtos';
import { InvalidBasketItemModel } from '../domain';

export function mapLocations<
  T extends {
    locations: {
      id: string;
      name: LanguageControlGroup;
    }[];
  },
>(products: T[], locations: CustomerLocationsListDto[]): T[] {
  return produce(products, draft => {
    draft.forEach(product => {
      product.locations = product.locations.map(location => {
        const loc = locations.find(l => l.id === location.id);

        return {
          id: location.id,
          name: loc ? loc.name : ({} as LanguageControlGroup),
        };
      });
    });
  });
}

export function mapLocationsInvalid(
  basketItems: InvalidBasketItemDto[],
  locations: CustomerLocationsListDto[]
): InvalidBasketItemModel[] {
  return basketItems.map(item => {
    const mappedLocations = item.locationIds.map(locationId => {
      const loc = locations.find(l => l.id === locationId);

      return {
        id: locationId,
        name: loc ? loc.name : ({} as LanguageControlGroup),
      };
    });

    return { ...item, locations: mappedLocations };
  });
}
