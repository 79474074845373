import { LanguageControlGroup, OfferCategoryEnum } from '@xspot-app/common';
import { PriceDto } from './price.dto';
import { OfferProductKindEnum } from '../enums';

// FIXME: Remove change interface when FLYS-1801 is complete
// Task link: https://herodot.atlassian.net/browse/FLYS-1801
export interface CustomerOfferDto {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  ordinal: number;
  originalPrice: PriceDto;
  categories: OfferCategoryEnum[];
  locationIds: string[];
  actualPrice?: number;
  isPromotion?: boolean;
  offerProductKind: OfferProductKindEnum;
}
