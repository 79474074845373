import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { AuthStateModel } from './auth.state-model';
import { catchError, EMPTY, Observable, tap, throwError } from 'rxjs';
import { AuthorizationService } from '../services';
import { Auth } from './auth.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ExternalProviderModel } from '../domain';
import { StateReset } from 'ngxs-reset-plugin';
import { AuthService } from '@xspot-app/common';
import { AuthenticationDto } from '@xspot-app/shared/auth';
import { UserState } from '@xspot-app/customer/users-api';

const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');
@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: {
    accessToken: null,
    tokenType: null,
    expiresIn: null,
    refreshToken: null,
    externalProvider: null,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authorizationService: AuthorizationService,
    private authService: AuthService,
    private router: Router,
    private zone: NgZone,
    private store: Store
  ) {}

  @Selector()
  public static socialData(
    state: AuthStateModel
  ): ExternalProviderModel | null {
    return state.externalProvider;
  }
  @Selector()
  public static accessToken({
    externalProvider,
  }: AuthStateModel): string | null {
    return externalProvider ? externalProvider.accessToken : null;
  }
  @Action(Auth.LogIn)
  public login(
    ctx: StateContext<AuthStateModel>,
    action: Auth.LogIn
  ): Observable<AuthenticationDto> {
    return this.authorizationService.login(action.payload).pipe(
      tap(response => {
        ctx.patchState({ ...response });
        this.authService.setToken(response.accessToken, response.refreshToken);
      })
    );
  }

  @Action(Auth.ExternalLogIn)
  public externalLogIn(
    { patchState }: StateContext<AuthStateModel>,
    { payload, redirectTo }: Auth.ExternalLogIn
  ): Observable<AuthenticationDto> {
    patchState({
      externalProvider: {
        provider: payload.provider,
        birthday: payload.data.birthday ?? null,
        ...payload.data,
      },
    });
    return this.authorizationService
      .externalLogin({
        provider: payload.provider,
        externalToken: payload.data.accessToken,
      })
      .pipe(
        tap(response => {
          patchState({
            ...response,
          });
          this.authService.setToken(
            response.accessToken,
            response.refreshToken
          );
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            this.zone.run(() => {
              this.router.navigate(['/auth/register/social-login'], {
                queryParamsHandling: 'merge',
                queryParams: { redirectTo },
              });
            });
          }
          return throwError(() => error);
        })
      );
  }
  @Action(Auth.Logout)
  public logout(): Observable<void> {
    this.store.dispatch([new StateReset(AuthState), new StateReset(UserState)]);
    this.authService.clearTokens();
    return EMPTY;
  }

  @Action(Auth.ConfirmEmail)
  public confirmEmail(
    ctx: StateContext<AuthStateModel>,
    action: Auth.ConfirmEmail
  ): Observable<void> {
    return this.authorizationService.confirmEmail(action.payload);
  }

  @Action(Auth.ResendEmailConfirmation)
  public resendEmailConfirmation(
    ctx: StateContext<AuthStateModel>,
    action: Auth.ResendEmailConfirmation
  ): Observable<void> {
    return this.authorizationService.resendEmailConfirmation(action.payload);
  }

  @Action(Auth.ResetPasswordRequest)
  public resetPasswordRequest(
    ctx: StateContext<AuthStateModel>,
    action: Auth.ResetPasswordRequest
  ): Observable<void> {
    return this.authorizationService.resetPassword(action.payload);
  }

  @Action(Auth.ResetPasswordConfirm)
  public resetPasswordConfirm(
    ctx: StateContext<AuthStateModel>,
    action: Auth.ResetPasswordConfirm
  ): Observable<void> {
    return this.authorizationService.resetPasswordConfirm(action.payload);
  }
}
