import { Component, HostListener, Input, OnInit } from '@angular/core';
import { RippleModule } from 'primeng/ripple';
import { StyleClassModule } from 'primeng/styleclass';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { MenuService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BREAKPOINT_LG,
  UiContext,
  UiContextService,
  scopeLoaderFactory,
} from '@xspot-app/common';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { MenuItem } from '../../models';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DividerModule } from 'primeng/divider';
import { combineLatest, filter, map, Observable, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'xspot-app-left-menu',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    OverlayPanelModule,
    SidebarModule,
    RouterModule,
    RippleModule,
    StyleClassModule,
    TranslocoDirective,
  ],
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
  providers: [
    provideTranslocoScope({
      scope: 'menu',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class LeftMenuComponent implements OnInit {
  @Input({ required: true }) public menuItems$!: Observable<MenuItem[]>;
  @Input({ required: true }) public logoUrl!: string;
  @Input() public allowSwitchContext = true;

  public isOpen!: boolean;

  public UiContext = UiContext;

  public activeParentName$!: Observable<string>;

  constructor(
    public menuService: MenuService,
    private uiContextService: UiContextService,
    private router: Router
  ) {}

  @HostListener('window:resize')
  public onResize(): void {
    if (window.innerWidth < BREAKPOINT_LG) {
      this.hideMenu();
    }
  }

  public ngOnInit(): void {
    this.menuService.currentMenuState
      .pipe(untilDestroyed(this))
      .subscribe(isOpen => (this.isOpen = isOpen));

    this.activeParentName$ = combineLatest([
      this.router.events.pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd),
        map(event => (event as NavigationEnd).url),
        startWith(this.router.url)
      ),
      this.menuItems$,
    ]).pipe(
      untilDestroyed(this),
      map(([url, menuItems]) => {
        const activeParent = menuItems.find(item =>
          item.items
            ? item.items.find(subItem =>
                subItem.url ? url.substring(1).startsWith(subItem.url) : false
              )
            : false
        );

        return activeParent?.label || '';
      })
    );
  }

  public hideMenu(): void {
    this.menuService.hideMenu();
  }

  public runItemAction(item: MenuItem): void {
    this.hideMenu();

    if (item.action) {
      item.action();
    }
  }

  public changeContext(context: UiContext): void {
    this.uiContextService.switchContext(context, true);
  }
}
