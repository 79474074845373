import { CustomerOfferDetailsVariantModel } from './customer-offer-details.model';
import { LocationControlModel, Nullable } from '@xspot-app/common';

export interface OfferModel {
  id: string;
  activeIndex: number;
  location?: Nullable<LocationControlModel>;
  variant?: Nullable<CustomerOfferDetailsVariantModel>;
  variantProducts?: Record<string, number>;
  upsells?: Record<string, number>;
}
