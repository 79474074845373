import { ExternalProviders, Language } from '../../enums';

export interface CreateExternalUserDto {
  email: string;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  termsOfServiceAgreed: boolean;
  marketingAgreed: boolean;
  language: Language;
  provider: ExternalProviders;
  externalToken: string;
}
