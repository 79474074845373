import { DateTime, Duration } from 'luxon';
import {
  CalendarDaySlotModel,
  CalendarDaySlotProductPromotionModel,
} from '../../domain';
import { CalendarDaySlotDto } from '../../dtos';

export class CalendarDaySlotsModelMapper {
  public static map(
    calendarDaySlotsDto: CalendarDaySlotDto[]
  ): CalendarDaySlotModel[] {
    return calendarDaySlotsDto.map(slot => {
      const date = DateTime.fromFormat(slot.date, 'yyyy-MM-dd');
      const from = Duration.fromISOTime(slot.from);
      const to = Duration.fromISOTime(slot.to);
      const dateTime: DateTime = date.set({
        hour: from.hours,
        minute: from.minutes,
      });
      const hasPromotion: boolean = Math.random() < 0.2;
      const productPromotions: CalendarDaySlotProductPromotionModel[] =
        hasPromotion
          ? [
              {
                quantity: 2,
                name: {
                  cs: 'Mock promotion',
                  de: 'Mock promotion',
                  es: 'Mock promotion',
                  fr: 'Mock promotion',
                  uk: 'Mock promotion',
                  pl: 'Mock promotion',
                  en: 'Mock promotion',
                },
              },
            ]
          : [];

      return {
        ...slot,
        date,
        dateTime,
        from,
        to,
        productPromotions,
      };
    });
  }
}
