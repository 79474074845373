export interface NbpCurrencyDto {
  code: string;
  currency: string;
  rates: {
    effectiveDate: string;
    mid: number;
    np: string;
  }[];
  table: 'A' | 'B' | 'C';
}
