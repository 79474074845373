import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import {
  CustomerOfferDetailsDto,
  CustomerOfferDto,
  GetSelectedProductsMinimalPriceDto,
  SelectedProductsMinimalPriceDto,
  UpsellDto,
} from '../dtos';
import { environment, OfferCategoryEnum } from '@xspot-app/common';
import { ActivatedRoute } from '@angular/router';
import { OfferProductKindEnum } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  // FIXME: Remove mock data when FLYS-1801 is complete
  // Task link: https://herodot.atlassian.net/browse/FLYS-1801
  public getActiveOffers(): Observable<CustomerOfferDto[]> {
    return this.http
      .get<CustomerOfferDto[]>(`${environment.apiUrl}/api/offers`)
      .pipe(
        map(data => {
          const offers = [...data];

          const mockOffers: CustomerOfferDto[] = [
            {
              id: 'faked1',
              coverMobileUri:
                'https://stxspotdevdata.blob.core.windows.net/images/Offers/7921c8f9-7efd-45bb-88c5-1002836b9c52.jpeg',
              coverDesktopUri:
                'https://stxspotdevdata.blob.core.windows.net/images/Offers/7921c8f9-7efd-45bb-88c5-1002836b9c52.jpeg',
              name: { pl: 'MOCK: Promocja produktowa', en: 'Extra Offer 1' },
              teaser: {
                pl: '<p>Warto spróbować</p>',
                en: '<p>Worth a try</p>',
              },
              ordinal: 1,
              originalPrice: {
                isMinimal: true,
                value: 123,
                isPricePerMinute: false,
              },
              actualPrice: 100,
              isPromotion: true,
              categories: [OfferCategoryEnum.Beginner],
              offerProductKind: OfferProductKindEnum.Reservation,
              locationIds: [
                '9936fa34-7b7d-4452-99e4-3c675e9fa804',
                'ce614821-c6f2-4c19-a67b-4b4fd23eee3d',
              ],
            },
            {
              id: 'faked2',
              coverMobileUri:
                'https://stxspotdevdata.blob.core.windows.net/images/Offers/7921c8f9-7efd-45bb-88c5-1002836b9c52.jpeg',
              coverDesktopUri:
                'https://stxspotdevdata.blob.core.windows.net/images/Offers/7921c8f9-7efd-45bb-88c5-1002836b9c52.jpeg',
              name: { pl: 'MOCK: Promocja kwotowa', en: 'Extra Offer 2' },
              teaser: { pl: '<p>Spróbuj teraz!</p>', en: '<p>Try it now!</p>' },
              ordinal: 2,
              originalPrice: {
                isMinimal: true,
                value: 123,
                isPricePerMinute: false,
              },
              actualPrice: 123,
              isPromotion: true,
              offerProductKind: OfferProductKindEnum.Reservation,
              categories: [OfferCategoryEnum.Beginner],
              locationIds: [
                'f4961379-5e60-4ce0-89de-62e595e542d7',
                'a9f79c8e-29ee-429d-92fb-76ac86e31208',
              ],
            },
          ];

          // Combine original and mock data
          return [...offers, ...mockOffers];
        })
      );
  }

  public getOffer(id: string): Observable<CustomerOfferDetailsDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.get<CustomerOfferDetailsDto>(
      `${environment.apiUrl}/api/offers/${id}`,
      { headers }
    );
  }

  public getMinimalPrice(
    offerId: string,
    payload: GetSelectedProductsMinimalPriceDto
  ): Observable<SelectedProductsMinimalPriceDto> {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return this.http.post<SelectedProductsMinimalPriceDto>(
      `${environment.apiUrl}/api/offers/${offerId}/MinimalPrice`,
      payload,
      { headers }
    );
  }

  public getOfferUpsells(offerId: string): Observable<UpsellDto[]> {
    return this.http.get<UpsellDto[]>(
      `${environment.apiUrl}/api/offers/${offerId}/upsells`
    );
  }
}
