import { LanguageControlGroup, Nullable } from '@xspot-app/common';
import { DateTime, Duration } from 'luxon';

export interface CalendarDaySlotModel {
  isAvailable: boolean;
  date: DateTime;
  dateTime: DateTime;
  from: Duration;
  to: Duration;
  originalPrice: Nullable<number>;
  actualPrice: Nullable<number>;
  availableMinutes: Nullable<number>;
  isNightHours: boolean;
  productPromotions: CalendarDaySlotProductPromotionModel[];
}

export interface CalendarDaySlotProductPromotionModel {
  quantity: number;
  name: LanguageControlGroup;
}
