import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CalendarDaysDto,
  CalendarDaySlotDto,
  GetCalendarDaysDto,
  GetCalendarDaySlotsDto,
  GetVoucherCalendarDaysDto,
  GetVoucherCalendarDaySlotsDto,
} from '../dtos';
import { environment } from '@xspot-app/common';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CalendarsService {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) {}

  public getCalendarDays(
    payload: GetCalendarDaysDto
  ): Observable<CalendarDaysDto> {
    const headers = this.prepareHeader();
    return this.http.post<CalendarDaysDto>(
      `${environment.apiUrl}/api/calendars/days`,
      payload,
      { headers }
    );
  }

  public getVoucherCalendarDays(
    payload: GetVoucherCalendarDaysDto
  ): Observable<CalendarDaysDto> {
    return this.http.post<CalendarDaysDto>(
      `${environment.apiUrl}/api/calendars/vouchers/days`,
      payload
    );
  }

  public getCalendarDaySlots(
    payload: GetCalendarDaySlotsDto
  ): Observable<CalendarDaySlotDto[]> {
    const headers = this.prepareHeader();
    return this.http.post<CalendarDaySlotDto[]>(
      `${environment.apiUrl}/api/calendars/days/slots`,
      payload,
      { headers }
    );
  }

  public getVoucherCalendarDaySlots(
    payload: GetVoucherCalendarDaySlotsDto
  ): Observable<CalendarDaySlotDto[]> {
    return this.http.post<CalendarDaySlotDto[]>(
      `${environment.apiUrl}/api/calendars/vouchers/days/slots`,
      payload
    );
  }

  private prepareHeader(): HttpHeaders {
    let headers = new HttpHeaders();
    const { backOfficeToken } = this.route.snapshot.queryParams;

    if (backOfficeToken) {
      headers = headers.set('X-BackOfficeOfferToken', backOfficeToken);
    }

    return headers;
  }
}
