import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, DOCUMENT, isPlatformServer } from '@angular/common';
import { RouterModule } from '@angular/router';
import { map, switchMap, take, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookAuthService } from '@xspot-app/shared/auth';
import {
  DeepspotPreset,
  environment,
  FlyspotPreset,
  PlatformDetectorService,
  SessionIdService,
  UiContext,
  UiContextService,
} from '@xspot-app/common';
import { TranslocoService } from '@jsverse/transloco';
import { ToastModule } from 'primeng/toast';
import { Meta, Title } from '@angular/platform-browser';
import { PrimeNG } from 'primeng/config';
import { usePreset } from '@primeng/themes';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, ToastModule],
  selector: 'xspot-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private uiContextService: UiContextService,
    private facebookAuthService: FacebookAuthService,
    private platformDetector: PlatformDetectorService,
    @Inject(PLATFORM_ID) private platformId: object,
    private primeNgConfig: PrimeNG,
    private translocoService: TranslocoService,
    private sessionIdService: SessionIdService,
    private titleService: Title,
    private metaService: Meta
  ) {}

  public ngOnInit(): void {
    this.initializeGTM();
    this.facebookAuthService.initializeFacebookSDK();

    if (this.platformDetector.isIos()) {
      this.document.body.classList.add('is-iOS');
    }

    this.translocoService.langChanges$
      .pipe(
        untilDestroyed(this),
        tap(lang => {
          this.document.documentElement.lang = lang;
        }),
        switchMap(() => this.uiContextService.currentContext$),
        map(context => context.toLowerCase()),
        switchMap(context =>
          this.translocoService.selectTranslateObject('head').pipe(
            tap(head => {
              this.titleService.setTitle(head.title[context]);
              this.metaService.updateTag({
                name: 'description',
                content: head.metaDescription[context],
              });
            })
          )
        ),
        switchMap(() => this.translocoService.selectTranslateObject('primeng'))
      )
      .subscribe(translation => this.primeNgConfig.setTranslation(translation));

    this.sessionIdService.initializeSessionId();
    this.initTheme();
  }

  public initTheme(): void {
    this.uiContextService.currentContext$.pipe(take(1)).subscribe(context => {
      switch (context) {
        case UiContext.Deepspot:
          usePreset(DeepspotPreset);
          break;
        case UiContext.Flyspot:
          usePreset(FlyspotPreset);
          break;
        default:
          throw new Error(`Unknown context ${context}`);
      }
    });
  }

  private initializeGTM(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    (function (w, d, s, l, i) {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      /* eslint-enable @typescript-eslint/ban-ts-comment */
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s) as HTMLScriptElement,
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode?.insertBefore(j, f);
    })(window, this.document, 'script', 'dataLayer', environment.googleTagId);
  }
}
