import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { skippableInterceptorUrls, UiContextService } from '@xspot-app/common';
import { Observable } from 'rxjs';

export function contextInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const uiContextService = inject(UiContextService);

  if (skippableInterceptorUrls.some(url => request.url.includes(url))) {
    return next(request);
  }

  const modifiedRequest = request.clone({
    setHeaders: {
      'X-Format': uiContextService.currentContext$.getValue(),
    },
  });

  return next(modifiedRequest);
}
