import {
  addMinutesToTime,
  LanguageControlGroup,
  ProductKindEnum,
} from '@xspot-app/common';
import {
  BasketOfferSlotModel,
  BasketOfferReservationProductModel,
  BasketModel,
  BasketOfferModel,
  BasketOfferProductModel,
  BasketOfferUpsellModel,
} from '../domain';
import { BasketOfferProductDto, BasketDto } from '../dtos';

export class BasketModelMapper {
  public static map(basketDto: BasketDto): BasketModel {
    return {
      totalPrice: basketDto.actualPrice,
      originalPrice: basketDto.originalPrice,
      offers: basketDto.items.map(offerDto => {
        const isPro = this.isProReservation(offerDto.products);

        const nonReservationProducts = offerDto.products
          .filter(p => !p.date)
          .map(productDto => {
            return {
              offerVariantProductId: productDto.offerVariantProductId,
              totalPrice: productDto.actualPrice * productDto.quantity,
              originalPrice: productDto.originalPrice * productDto.quantity,
              actualPrice: productDto.actualPrice,
              name: productDto.productDisplayName,
              quantity: productDto.quantity,
            } as BasketOfferProductModel;
          });

        const allProducts = offerDto.products
          .sort(a =>
            a.productKind === ProductKindEnum.Reservation ||
            a.productKind === ProductKindEnum.DeepspotReservation
              ? -1
              : 1
          )
          .map(productDto => {
            return {
              offerVariantProductId: productDto.offerVariantProductId,
              totalPrice: productDto.actualPrice * productDto.quantity,
              originalPrice: productDto.originalPrice * productDto.quantity,
              actualPrice: productDto.actualPrice,
              name: productDto.productDisplayName,
              quantity: productDto.quantity,
            } as BasketOfferProductModel;
          });

        const upsells = offerDto.upsells.map(upsell => {
          return {
            upsellId: upsell.upsellId,
            totalPrice: upsell.actualPrice * upsell.quantity,
            name: upsell.upsellName,
            quantity: upsell.quantity,
          } as BasketOfferUpsellModel;
        });

        const reservationProducts = this.groupProductsByDate(offerDto.products);
        const reservationProductsMapped = this.mapReservationProducts(
          reservationProducts,
          isPro
        );

        const isCreditOffer = offerDto.products.some(
          product => product.productKind === ProductKindEnum.Credit
        );

        const locations = offerDto.locationIds.map(locationId => ({
          id: locationId,
          name: {} as LanguageControlGroup,
        }));

        return {
          id: offerDto.id,
          offerId: offerDto.offerId,
          offerVariantId: offerDto.offerVariantId,
          originalPrice: offerDto.originalPrice,
          totalPrice: offerDto.actualPrice,
          name: offerDto.offerName,
          variantName: offerDto.offerVariantName,
          locations: locations,
          nonReservationProducts: nonReservationProducts,
          allProducts: allProducts,
          reservationProducts: reservationProductsMapped,
          upsells: upsells,
          detailsVisibility: false,
          coverDesktopUri: offerDto.coverDesktopUri,
          coverMobileUri: offerDto.coverMobileUri,
          kind: offerDto.kind,
          voucherCode: offerDto.voucherCode,
          voucherId: offerDto.voucherId,
          isCreditOffer,
        } as BasketOfferModel;
      }),
    };
  }

  public static groupProductsByDate(
    products: BasketOfferProductDto[]
  ): Record<string, Array<{ time: string; product: BasketOfferProductDto }>> {
    return products
      .filter(p => p.date)
      .reduce(
        (acc, product) => {
          const { date, from } = product;
          if (!acc[date!]) {
            acc[date!] = [];
          }
          acc[date!].push({ product, time: from! });
          return acc;
        },
        {} as Record<
          string,
          Array<{ time: string; product: BasketOfferProductDto }>
        >
      );
  }

  private static mapSlots(
    products: Array<{ time: string; product: BasketOfferProductDto }>
  ): BasketOfferSlotModel[] {
    return products.reduce((acc, { time, product }) => {
      const existingSlot = acc.find(slot => slot.timeFrom === time);

      if (existingSlot) {
        existingSlot.products.push({
          offerVariantProductId: product.offerVariantProductId,
          totalPrice: product.actualPrice * product.quantity,
          originalPrice: product.originalPrice * product.quantity,
          actualPrice: product.actualPrice,
          name: product.productDisplayName,
          quantity: product.quantity,
        });
      } else {
        acc.push({
          timeFrom: time.substring(0, 5),
          timeTo: addMinutesToTime(time, 30),
          products: [
            {
              offerVariantProductId: product.offerVariantProductId,
              totalPrice: product.actualPrice * product.quantity,
              originalPrice: product.originalPrice * product.quantity,
              actualPrice: product.actualPrice,
              name: product.productDisplayName,
              quantity: product.quantity,
            },
          ],
          minutes: product.numberOfMinutes,
        });
      }

      return acc;
    }, [] as BasketOfferSlotModel[]);
  }

  public static mapReservationProducts(
    reservationProducts: Record<
      string,
      Array<{ time: string; product: BasketOfferProductDto }>
    >,
    isPro: boolean
  ): BasketOfferReservationProductModel[] {
    return Object.entries(reservationProducts).map(([date, products]) => ({
      date,
      slots: this.mapSlots(products),
      isReservationPro: isPro,
    }));
  }

  public static isProReservation(products: BasketOfferProductDto[]): boolean {
    return products.filter(p => p.date).every(p => p.isMinimalAmount);
  }
}
