import { Injectable } from '@angular/core';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import {
  BookingMembersStateModel,
  BookingModel,
} from './booking-members.state-model';
import { BookingMembers } from './booking-members.actions';
import { BookingMembersService } from '../../services';
import { Nullable } from '@xspot-app/common';
import { BookingDto } from '../../dtos';

const BOOKING_MEMBERS_STATE_TOKEN = new StateToken<BookingMembersStateModel>(
  'bookingMembers'
);

@State<BookingMembersStateModel>({
  name: BOOKING_MEMBERS_STATE_TOKEN,
  defaults: {
    bookingMembers: null,
    isMinimalAmountBookingGlobal: false,
  },
})
@Injectable()
export class BookingMembersState {
  constructor(private bookingMembersService: BookingMembersService) {}

  @Selector()
  public static getIsMinimalAmountBookingGlobal(
    state: BookingMembersStateModel
  ): boolean {
    return state.isMinimalAmountBookingGlobal;
  }

  @Selector()
  public static bookingMembers(
    state: BookingMembersStateModel
  ): Nullable<BookingModel[]> {
    return state.bookingMembers;
  }

  @Action(BookingMembers.UpdateBookingMembers)
  public updateBookingMembers(
    ctx: StateContext<BookingMembersStateModel>,
    action: BookingMembers.UpdateBookingMembers
  ): Observable<void> {
    return this.bookingMembersService.updateBookingMembers(
      action.orderItemId,
      action.payload
    );
  }

  @Action(BookingMembers.GetBookingMembers)
  public getBookingMembers(
    ctx: StateContext<BookingMembersStateModel>,
    action: BookingMembers.GetBookingMembers
  ): Observable<BookingDto[]> {
    return this.bookingMembersService
      .getBookingMembers(action.orderItemId, action.params)
      .pipe(
        tap(data => {
          ctx.patchState({
            bookingMembers: data.map(booking => ({
              ...booking,
            })) as BookingModel[],

            isMinimalAmountBookingGlobal:
              data.length > 0 ? !!data[0].isMinimalAmountBooking : false,
          });
        })
      );
  }

  @Action(BookingMembers.ClearState)
  public clearState(ctx: StateContext<BookingMembersStateModel>): void {
    ctx.patchState({
      bookingMembers: null,
      isMinimalAmountBookingGlobal: false,
    });
  }
}
