import { LanguageControlGroup, PromotionKindEnum } from '@xspot-app/common';

export interface OfferPromotionDto {
  id: string;
  validFromDate: string;
  validToDate: string;
  locationIds: string[];
  offerVariantProducts: OfferPromotionVariantProductDto[];
  daysOfWeek: string[];
  documents: OfferPromotionDocumentDto[];
  description: LanguageControlGroup;
  displayName: LanguageControlGroup;
  kind: PromotionKindEnum;
  value?: number;
  products: OfferPromotionProductDto[];
  isInSpecificHours: boolean;
}

export interface OfferPromotionVariantProductDto {
  id: string;
  displayName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
}

export interface OfferPromotionDocumentDto {
  id: string;
  fileName: string;
  uri: string;
  ordinal: number;
}

export interface OfferPromotionProductDto {
  id: string;
  displayName: LanguageControlGroup;
}
