import { GetBookingMembersDto, UpdateBookingMembersDto } from '../../dtos';

export namespace BookingMembers {
  export class UpdateBookingMembers {
    public static readonly type = '[BookingMembers] Get Items';
    constructor(
      public orderItemId: string,
      public payload: UpdateBookingMembersDto
    ) {}
  }

  export class GetBookingMembers {
    public static readonly type = '[BookingMembers] Update Item';
    constructor(
      public orderItemId: string,
      public params: GetBookingMembersDto
    ) {}
  }

  export class ClearState {
    public static readonly type = '[BookingMembers] Clear State';
  }
}
