import { Router, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService, NotificationService } from '@xspot-app/common';
import { Store } from '@ngxs/store';
import { User, UserState } from '../state';

export function userProfileGuardFn():
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree> {
  const store = inject(Store);
  const router = inject(Router);
  const notificationService = inject(NotificationService);

  return inject(AuthService)
    .isAuthenticated()
    .pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated !== true) {
          return of(isAuthenticated);
        }

        return store.selectOnce(UserState.profile).pipe(
          switchMap(profile => {
            if (!profile) {
              return store
                .dispatch(new User.FetchProfile())
                .pipe(switchMap(() => store.selectOnce(UserState.profile)));
            }

            return of(profile);
          }),
          map(profile => {
            if (!profile?.emailConfirmed) {
              notificationService.showWarningNotification(
                'common.emailHasToBeConfirmed'
              );
              return router.createUrlTree(['/auth/register/activate-account'], {
                queryParams: { fromProfile: true },
              });
            }

            return true;
          })
        );
      })
    );
}
