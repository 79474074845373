import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'joinPipe',
  standalone: true,
})
export class JoinPipe implements PipeTransform {
  private translocoService = inject(TranslocoService);

  /**
   * @param value - An array of objects containing language keys or a string array (depending on your needs).
   * @param lang - The language code, e.g. 'pl'
   * @param separator - The separator between elements.
   * @param translationPrefix - An optional translation key prefix, e.g. 'common.weekDays.'
   */
  public transform(
    value: unknown,
    lang: string,
    separator: string = ', ',
    translationPrefix?: string
  ): string {
    if (!Array.isArray(value)) {
      return '';
    }

    return value
      .map(item => {
        let text: string | null = null;

        if (typeof item === 'object' && item !== null && lang in item) {
          text = (item as Record<string, string>)[lang];
        } else if (typeof item === 'string') {
          text = item;
        }

        if (text && translationPrefix) {
          const lowerCaseText = text.toLowerCase();
          return this.translocoService.translate(
            translationPrefix + lowerCaseText,
            {},
            lang
          );
        }

        return text;
      })
      .filter(
        (item): item is string => typeof item === 'string' && item != null
      )
      .join(separator);
  }
}
