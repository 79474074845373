<ng-container *transloco="let t">
  @if (product.productForm === OfferProductFormEnum.Range) {
    <ng-container
      *ngIf="selectedVariantProduct$ | async as selectedVariantProduct">
      <div
        *ngIf="selectedLanguage$ | async as lang"
        class="p-3 flex justify-content-between align-items-center font-bold border-2 border-round-lg surface-border mt-2">
        <div>{{ product.displayName[lang] }}</div>
        <div
          class="w-6rem flex align-items-center justify-content-between text-xl font-normal">
          <button
            class="selector bg-none"
            [disabled]="
              selectedVariantProduct.quantity - 1 < product!.minAmount!
            "
            (click)="changeProductAmount(selectedVariantProduct.quantity - 1)">
            <i class="pi pi-minus-circle"></i>
          </button>
          <div>{{ selectedVariantProduct.quantity }}</div>
          <button
            class="selector bg-none"
            [disabled]="
              selectedVariantProduct.quantity + 1 > product!.maxAmount!
            "
            (click)="changeProductAmount(selectedVariantProduct.quantity + 1)">
            <i class="pi pi-plus-circle"></i>
          </button>
        </div></div
    ></ng-container>
  }
</ng-container>
