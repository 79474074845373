import { Buffer } from 'buffer';
import { DateTime, Duration } from 'luxon';

const dateTimeSerializationPrefix = '{DateTime}+';
const durationSerializationPrefix = '{Duration}+';

DateTime.prototype.toJSON = function () {
  return dateTimeSerializationPrefix + this.toISO();
};
Duration.prototype.toJSON = function () {
  return durationSerializationPrefix + this.toISO();
};

/* eslint-disable */
export class Base64Util {
  public static encode(value: object): string {
    return Buffer.from(JSON.stringify(value)).toString('base64');
  }

  public static decode<T>(value: string): T {
    const reviver = (key: string, val: any): any => {
      if (
        typeof val === 'string' &&
        val.startsWith(dateTimeSerializationPrefix)
      ) {
        const isoString = val.replace(dateTimeSerializationPrefix, '');
        return DateTime.fromISO(isoString);
      }
      if (
        typeof val === 'string' &&
        val.startsWith(durationSerializationPrefix)
      ) {
        const isoString = val.replace(durationSerializationPrefix, '');
        return Duration.fromISO(isoString);
      }
      return val;
    };
    return JSON.parse(
      Buffer.from(value, 'base64').toString('utf-8'),
      reviver
    ) as T;
  }
}
