import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import {
  AgeGroupKindEnum,
  EmailValidator,
  highlightFormErrors,
  NoWhitespaceValidator,
  Nullable,
} from '@xspot-app/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective } from '@ngneat/transloco';
import { DividerModule } from 'primeng/divider';
import { FormControlErrorsComponent } from '@xspot-app/shared/ui';
import { InputTextModule } from 'primeng/inputtext';
import { BookingMembers, BookingMembersState } from '../../state';
import { Store } from '@ngxs/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BookingFormModel,
  BookingFormValue,
  BookingMemberModel,
  BookingMembersForm,
  BookingMembersFormValue,
} from '../../domain';
import {
  bookingMemberLimit,
  firstNameMaxLength,
  firstNameMinLength,
  lastNameMaxLength,
  lastNameMinLength,
} from '../../const';
import {
  BookingDto,
  UpdateBookingMembersBookingDto,
  UpdateBookingMembersDto,
  UpdateBookingMembersMemberDto,
} from '../../dtos';

@UntilDestroy()
@Component({
  selector: 'xspot-app-booking-members-modal',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DividerModule,
    ReactiveFormsModule,
    FormControlErrorsComponent,
    InputTextModule,
    DividerModule,
    TranslocoDirective,
  ],
  templateUrl: './booking-members-modal.component.html',
  styleUrl: './booking-members-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingMembersModalComponent implements OnInit {
  public form!: FormGroup<BookingMembersForm>;

  public usedUpsellMinutes: number = 0;
  public maxUpsellMinutes: number = 0;

  public isMinimalAmountBookingGlobal: boolean = this.store.selectSnapshot(
    BookingMembersState.getIsMinimalAmountBookingGlobal
  );

  public from!: string;
  public to!: string;
  public minutes!: string;

  constructor(
    private fb: NonNullableFormBuilder,
    protected dialogConfig: DynamicDialogConfig<{
      bookingMembers: BookingDto[];
      orderItemId: string;
      date: string;
      from: string;
      to: string;
      minutes: string;
    }>,
    private dialogRef: DynamicDialogRef,
    private store: Store
  ) {
    this.from = this.dialogConfig.data?.from || '';
    this.to = this.dialogConfig.data?.to || '';
    this.minutes = this.dialogConfig.data?.minutes || '';
  }

  public ngOnInit(): void {
    let bookingMembersData = this.dialogConfig.data?.bookingMembers ?? [];

    if (
      bookingMembersData.length === 1 &&
      bookingMembersData[0].isMinimalAmountBooking === true &&
      bookingMembersData[0].members &&
      bookingMembersData[0].members.length > 1
    ) {
      const original = bookingMembersData[0];
      bookingMembersData = original.members.map((member, index) => ({
        id: index === 0 ? original.id : null,
        isMinimalAmountBooking: original.isMinimalAmountBooking,
        ageGroupKind: original.ageGroupKind,
        numberOfMinutes: original.numberOfMinutes,
        numberOfPromotionMinutes: original.numberOfPromotionMinutes,
        numberOfUpsellMinutes: original.numberOfUpsellMinutes,
        totalNumberOfMinutes: original.totalNumberOfMinutes,
        members: [member],
      }));
    }

    this.initializeForm(bookingMembersData);

    this.calculateGlobalUpsellMinutes();
    this.maxUpsellMinutes = this.usedUpsellMinutes;

    this.bookingMembersFormArray.controls.forEach(
      (bookingGroup: FormGroup<BookingFormModel>) => {
        bookingGroup.controls.numberOfUpsellMinutes.valueChanges
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.calculateGlobalUpsellMinutes();
          });
      }
    );
  }

  private initializeForm(bookingMembers: BookingDto[]): void {
    this.form = this.fb.group({
      bookingMembers: this.fb.array(
        bookingMembers.map((item, index) =>
          this.createBookingMemberFormGroup(item, index)
        )
      ),
    });
  }

  protected getMembersArray(
    bookingControl: FormGroup<BookingFormModel>
  ): FormArray<FormGroup<BookingMemberModel>> {
    return bookingControl.controls.members;
  }

  private calculateGlobalUpsellMinutes(): void {
    let sum = 0;
    this.bookingMembersFormArray.controls.forEach(
      (booking: FormGroup<BookingFormModel>) => {
        const upsell = booking.controls.numberOfUpsellMinutes.value;
        sum += upsell;
      }
    );
    this.usedUpsellMinutes = sum;
  }

  protected addBookingMember(): void {
    const defaultAgeGroup =
      this.bookingMembersFormArray.at(0).controls.ageGroupKind.value;

    if (this.bookingMembersFormArray.length < bookingMemberLimit) {
      const dummyBooking: BookingDto = {
        id: null,
        isMinimalAmountBooking: true,
        numberOfMinutes: 0,
        numberOfPromotionMinutes: 0,
        numberOfUpsellMinutes: 0,
        totalNumberOfMinutes: 0,
        ageGroupKind: defaultAgeGroup as AgeGroupKindEnum,
        members: [],
      };
      const newBookingGroup = this.createBookingMemberFormGroup(
        dummyBooking,
        0
      );
      this.bookingMembersFormArray.push(newBookingGroup);
    }
  }

  protected removeBookingMember(index: number): void {
    this.bookingMembersFormArray.removeAt(index);
  }

  private createBookingMemberFormGroup(
    item: BookingDto,
    bookingIndex: number
  ): FormGroup<BookingFormModel> {
    const isAlwaysRequired =
      this.isMinimalAmountBookingGlobal || bookingIndex === 0;

    const membersData =
      item.members && item.members.length > 0
        ? item.members
        : [{ id: '', name: '', surname: '', email: '' }];

    const memberGroups = membersData.map(member => {
      const baseNameValidators = [
        Validators.minLength(firstNameMinLength),
        Validators.maxLength(firstNameMaxLength),
        NoWhitespaceValidator,
      ];
      const baseSurnameValidators = [
        Validators.minLength(lastNameMinLength),
        Validators.maxLength(lastNameMaxLength),
        NoWhitespaceValidator,
      ];
      const baseEmailValidators = [EmailValidator];

      const nameValidators = isAlwaysRequired
        ? [Validators.required, ...baseNameValidators]
        : baseNameValidators;
      const surnameValidators = isAlwaysRequired
        ? [Validators.required, ...baseSurnameValidators]
        : baseSurnameValidators;
      const emailValidators = isAlwaysRequired
        ? [Validators.required, ...baseEmailValidators]
        : baseEmailValidators;

      const memberGroup = this.fb.group<BookingMemberModel>({
        id: this.fb.control<Nullable<string>>(member.id ?? null),
        name: this.fb.control<string>(member.name, {
          validators: nameValidators,
        }),
        surname: this.fb.control<string>(member.surname, {
          validators: surnameValidators,
        }),
        email: this.fb.control<string>(member.email, {
          validators: emailValidators,
        }),
      });

      if (!isAlwaysRequired) {
        memberGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
          const nameVal = memberGroup.controls.name.value?.trim();
          const surnameVal = memberGroup.controls.surname.value?.trim();
          const emailVal = memberGroup.controls.email.value?.trim();
          const anyFilled = !!(nameVal || surnameVal || emailVal);

          const newNameValidators = anyFilled
            ? [Validators.required, ...baseNameValidators]
            : baseNameValidators;
          const newSurnameValidators = anyFilled
            ? [Validators.required, ...baseSurnameValidators]
            : baseSurnameValidators;
          const newEmailValidators = anyFilled
            ? [Validators.required, ...baseEmailValidators]
            : baseEmailValidators;

          memberGroup.controls.name.setValidators(newNameValidators);
          memberGroup.controls.surname.setValidators(newSurnameValidators);
          memberGroup.controls.email.setValidators(newEmailValidators);

          memberGroup.controls.name.updateValueAndValidity({
            emitEvent: false,
          });
          memberGroup.controls.surname.updateValueAndValidity({
            emitEvent: false,
          });
          memberGroup.controls.email.updateValueAndValidity({
            emitEvent: false,
          });
        });
      }

      return memberGroup;
    });

    return this.fb.group<BookingFormModel>({
      id: this.fb.control<Nullable<string>>(item.id ?? null),
      numberOfPromotionMinutes: this.fb.control<number>(
        item.numberOfPromotionMinutes ?? 0
      ),
      numberOfUpsellMinutes: this.fb.control<number>(
        item.numberOfUpsellMinutes ?? 0
      ),
      totalNumberOfMinutes: this.fb.control<number>(
        item.totalNumberOfMinutes ?? 0
      ),
      ageGroupKind: this.fb.control<AgeGroupKindEnum>(
        item.ageGroupKind ?? (item.ageGroupKind as AgeGroupKindEnum)
      ),
      members: this.fb.array(memberGroups),
    });
  }

  public get bookingMembersFormArray(): FormArray<FormGroup<BookingFormModel>> {
    return this.form.controls.bookingMembers;
  }

  protected decrementUpsell(booking: FormGroup<BookingFormModel>): void {
    const currentControl = booking.controls.numberOfUpsellMinutes;
    const current = currentControl.value;
    if (current > 0) {
      currentControl.setValue(current - 1);
    }
  }

  protected incrementUpsell(booking: FormGroup<BookingFormModel>): void {
    const currentControl = booking.controls.numberOfUpsellMinutes;
    const current = currentControl.value;
    if (this.usedUpsellMinutes < this.maxUpsellMinutes) {
      currentControl.setValue(current + 1);
    }
  }

  protected close(): void {
    this.dialogRef.close();
  }

  private mapMembers(
    booking: BookingFormValue
  ): UpdateBookingMembersMemberDto[] {
    if (!this.isMinimalAmountBookingGlobal) {
      if (!booking.members || booking.members.length === 0) {
        return [];
      }
      const first = booking.members[0];
      if (
        !(first.name?.trim() || first.surname?.trim() || first.email?.trim())
      ) {
        return [];
      }
    }
    return booking.members.map(member => ({
      id: member.id || undefined,
      name: member.name,
      surname: member.surname,
      email: member.email,
    }));
  }

  private mapBooking(
    booking: BookingFormValue
  ): UpdateBookingMembersBookingDto {
    return {
      bookingId: booking.id || undefined,
      isMinimalAmountBooking: this.isMinimalAmountBookingGlobal,
      numberOfUpsellMinutes: booking.numberOfUpsellMinutes,
      members: this.mapMembers(booking),
    };
  }

  protected save(): void {
    if (this.form.invalid) {
      highlightFormErrors(this.form);
      return;
    }

    const { date, from, to, orderItemId } = this.dialogConfig.data!;
    const formValue = this.form.getRawValue() as BookingMembersFormValue;
    let payload: UpdateBookingMembersDto;

    if (this.isMinimalAmountBookingGlobal) {
      const bookingArray: UpdateBookingMembersBookingDto[] =
        formValue.bookingMembers.map(booking => this.mapBooking(booking));

      const combinedMembers: UpdateBookingMembersMemberDto[] =
        bookingArray.reduce(
          (acc, curr) => acc.concat(curr.members),
          [] as UpdateBookingMembersMemberDto[]
        );

      const mergedBooking: UpdateBookingMembersBookingDto = {
        bookingId: bookingArray[0].bookingId,
        isMinimalAmountBooking: this.isMinimalAmountBookingGlobal,
        numberOfUpsellMinutes: bookingArray[0].numberOfUpsellMinutes,
        members: combinedMembers,
      };

      payload = {
        date,
        from,
        to,
        bookings: [mergedBooking],
      };
    } else {
      payload = {
        date,
        from,
        to,
        bookings: formValue.bookingMembers.map(booking =>
          this.mapBooking(booking)
        ),
      };
    }

    this.store
      .dispatch(new BookingMembers.UpdateBookingMembers(orderItemId, payload))
      .subscribe(() => {
        this.close();
      });
  }
}
