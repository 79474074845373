import { HttpErrorResponse } from '@angular/common/http';
import { HttpErrorResponseCodes } from '../errors';

export class ErrorUtil {
  public static isUnauthorizedOrInvalidRefreshTokenError(
    error?: Error
  ): boolean {
    if (!ErrorUtil.isHttpErrorResponse(error)) {
      return false;
    }
    const {
      status,
      error: { errorCode },
    } = error;

    return (
      status === 401 ||
      (status === 400 &&
        (errorCode === HttpErrorResponseCodes.ExpiredRefreshToken ||
          errorCode === HttpErrorResponseCodes.InvalidRefreshToken))
    );
  }

  public static isUnauthorizedError(error?: Error): boolean {
    if (!ErrorUtil.isHttpErrorResponse(error)) {
      return false;
    }

    return error.status === 401;
  }

  public static isNotFoundError(error?: Error): boolean {
    if (!ErrorUtil.isHttpErrorResponse(error)) {
      return false;
    }

    return error.status === 404;
  }

  public static isHttpErrorCode(errorCode: string, error?: Error): boolean {
    if (!ErrorUtil.isHttpErrorResponse(error)) {
      return false;
    }

    return error?.error?.errorCode === errorCode;
  }

  public static isHttpErrorCodeInArray(
    errorCodes: string[],
    error?: Error
  ): boolean {
    for (const errorCode of errorCodes) {
      if (ErrorUtil.isHttpErrorCode(errorCode, error)) {
        return true;
      }
    }

    return false;
  }

  private static isHttpErrorResponse(
    error?: Error
  ): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
  }
}
