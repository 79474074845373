<ng-container *transloco="let t">
  <div class="flex flex-column md:flex-row flex-nowrap mt-6">
    <div class="md:w-6 overflow-visible relative banner">
      <div
        class="overflow-hidden md:border-round-left-lg md:border-noround-top border-round-top-lg shadow-2 banner">
        <img
          class="banner-img"
          src="assets/flyspot/banner-background.png"
          alt="banner" />
      </div>
      <div class="overlay">
        <img
          class="w-full h-full"
          src="assets/flyspot/firsttimer.png"
          alt="logo" />
      </div>
    </div>
    <div
      class="py-3 md:py-3 flex flex-column flex-nowrap justify-content-around align-items-center md:w-6 border-1 surface-border border-solid md:border-round-right-lg md:border-noround-bottom border-round-bottom-lg border-top-none md:border-top-1 md:border-left-none shadow-2">
      <div class="text-3xl font-semibold uppercase mb-5 md:mb-2 text-oswald">
        {{ t('common.voucher.voucher') }}
      </div>
      <div
        class="flex voucher-button__wrapper flex-nowrap justify-content-center gap-2 align-items-center">
        <p-button
          class="flex voucher-button flex-grow-1 align-self-stretch"
          type="button"
          severity="secondary"
          [outlined]="true"
          (click)="toRedeemVoucher()"
          [label]="t('common.voucher.redeem') | uppercase">
        </p-button>
        <p-button
          class="flex voucher-button flex-grow-1 align-self-stretch"
          (click)="toShopper()"
          [label]="t('common.voucher.buy') | uppercase">
        </p-button>
      </div>
    </div>
  </div>
</ng-container>
