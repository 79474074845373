import { Nullable } from '@xspot-app/common';

export interface UpsertBasketItemDto {
  basketId: Nullable<string>;
  offerId: string;
  offerVariantId: string;
  locationIds: string[];
  totalPrice: number;
  products: UpsertBasketItemProductDto[];
  upsells: UpsertBasketItemUpsellDto[];
}

export interface UpsertBasketItemProductDto {
  offerVariantProductId: string;
  quantity: number;
  slot?: UpsertBasketItemProductSlotDto;
}

export interface UpsertBasketItemProductSlotDto {
  date: string;
  from: string;
}

export interface UpsertBasketItemUpsellDto {
  upsellId: string;
  quantity: number;
}
