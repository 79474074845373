import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  public getDaysInMonth(year: number, month: number): DateTime[] {
    const startOfMonth: DateTime = DateTime.fromObject({ year, month }).startOf(
      'month'
    );
    const endOfMonth: DateTime = DateTime.fromObject({ year, month }).endOf(
      'month'
    );
    const startOfWeek: DateTime = startOfMonth.startOf('week');
    const endOfWeek: DateTime = endOfMonth.endOf('week');

    return this.generateDays(startOfWeek, endOfWeek);
  }

  public getDaysInWeek(date: DateTime): DateTime[] {
    const startOfWeek: DateTime = date.startOf('week');
    const endOfWeek: DateTime = date.endOf('week');

    return this.generateDays(startOfWeek, endOfWeek);
  }

  private generateDays(from: DateTime, to: DateTime): DateTime[] {
    const days: DateTime[] = [];
    let day: DateTime = from;
    while (day <= to) {
      days.push(day);
      day = day.plus({ days: 1 });
    }

    return days;
  }
}
