import { Nullable, PaymentMethodEnum } from '@xspot-app/common';

export interface CheckoutState {
  step: CheckoutStepEnum;
  paymentMethod: PaymentMethodEnum;
  agreements: AgreementsState;
  invoice: Nullable<InvoiceState>;
}

export enum CheckoutStepEnum {
  basket = 0,
  payment = 1,
}

export interface AgreementsState {
  termsOfService: boolean;
  marketing: Nullable<boolean>;
  statement: boolean;
}

export interface InvoiceState {
  taxNumber: string;
  name: string;
  city: string;
  postalCode: string;
  street: string;
  number: string;
}
