import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarService, isPreviousDate, Months } from '@xspot-app/common';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { Select, Store } from '@ngxs/store';
import { CalendarModule, CalendarMonthChangeEvent } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Calendars, CalendarsState } from '../../../state';
import { CalendarDayModel, CalendarDaysModel } from '../../../domain';
import { CalendarTypeEnum, CalendarDayStatusEnum } from '../../../enums';
import { hasActionsExecuting } from '@ngxs-labs/actions-executing';
import { WeekdayAbbreviationsComponent } from '../shared/weekday-abbreviations/weekday-abbreviations.component';
import { CalendarDayComponent } from '../shared/calendar-day/calendar-day.component';

@UntilDestroy()
@Component({
  selector: 'xspot-app-calendar-days',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    CommonModule,
    OverlayPanelModule,
    TranslocoDirective,
    ProgressSpinnerModule,
    CalendarDayComponent,
    WeekdayAbbreviationsComponent,
  ],
  templateUrl: './calendar-days.component.html',
  styleUrl: './calendar-days.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDaysComponent implements OnInit {
  @Output() public loadCalendarDaySlots = new EventEmitter<{
    date: DateTime;
  }>();
  @Output() public loadCalendarDays = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() public selectedDate = new EventEmitter<{
    date: DateTime;
  }>();
  @Output() public calendarYearMonth = new EventEmitter<DateTime>();

  @Select(hasActionsExecuting([Calendars.LoadDays, Calendars.LoadVoucherDays]))
  protected isLoading$!: Observable<boolean>;
  @Select(CalendarsState.type)
  public calendarType$!: Observable<CalendarTypeEnum>;
  @Select(CalendarsState.days)
  public calendarDays$!: Observable<CalendarDaysModel>;
  @Select(CalendarsState.currentYearMonth)
  public currentYearMonth$!: Observable<DateTime>;

  protected Months = Months;
  protected CalendarDayStatus = CalendarDayStatusEnum;
  protected CalendarTypeEnum = CalendarTypeEnum;

  protected calendarMinAndDefaultDate: Date = new Date();

  constructor(
    private calendarService: CalendarService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.loadCalendarData();

    this.store
      .select(CalendarsState.selectedSlots)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  protected changeMonth(offset: number): void {
    const calendarYearMonth = this.store.selectSnapshot(
      CalendarsState.currentYearMonth
    );
    const newCalendarMinYearMonth = calendarYearMonth.plus({
      months: offset,
    });
    this.updateCalendarMonth(newCalendarMinYearMonth);
  }

  protected handleMonthChange(event: CalendarMonthChangeEvent): void {
    if (!event.year || !event.month) {
      return;
    }
    const newCalendarMinYearMonth = DateTime.fromObject({
      year: event.year,
      month: event.month,
    });
    this.updateCalendarMonth(newCalendarMinYearMonth);
  }

  private updateCalendarMonth(newDate: DateTime): void {
    if (
      isPreviousDate(
        newDate,
        DateTime.fromJSDate(this.calendarMinAndDefaultDate).startOf('month')
      )
    ) {
      return;
    }
    this.store.dispatch(new Calendars.UpdateCurrentYearMonth(newDate));
    this.loadCalendarData();
  }

  private loadCalendarData(): void {
    const calendarYearMonth = this.store.selectSnapshot(
      CalendarsState.currentYearMonth
    );
    const days = this.calendarService.getDaysInMonth(
      calendarYearMonth.year,
      calendarYearMonth.month
    );

    this.loadCalendarDays.emit({
      startDate: days[0],
      endDate: days[days.length - 1],
    });

    this.calendarYearMonth.emit(calendarYearMonth);
  }

  protected onSelectDay(day: CalendarDayModel): void {
    if (day.status !== CalendarDayStatusEnum.Available) {
      return;
    }
    this.store.dispatch(new Calendars.ResetDaySlots());
    this.selectedDate.emit({ date: day.date });
  }

  protected onSelectDate(date: Date): void {
    this.store.dispatch(new Calendars.ResetDaySlots());
    this.selectedDate.emit({ date: DateTime.fromJSDate(date) });
  }
}
