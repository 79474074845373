export class HttpErrorResponseCodes {
  public static EmailTaken = 'EmailTaken';
  public static InvalidPromotionOfferVariantProducts =
    'InvalidPromotionOfferVariantProducts';
  public static InvalidVolumePriceOfferVariantProducts =
    'InvalidVolumePriceOfferVariantProducts';
  public static ExpiredRefreshToken = 'ExpiredRefreshToken';
  public static InvalidRefreshToken = 'InvalidRefreshToken';
  public static CompanyNotFoundInGusRegistry = 'CompanyNotFoundInGusRegistry';
  public static InvalidSlots = 'InvalidSlots';
  public static InvalidSlotValue = 'InvalidSlotValue';
  public static InvalidOfferVariant = 'InvalidOfferVariant';
  public static InvalidOfferVariantProduct = 'InvalidOfferVariantProduct';
  public static InvalidOfferLocation = 'InvalidOfferLocation';
}
