<ng-container *ngIf="control?.touched">
  <small class="text-error" *ngFor="let error of errors | keyvalue">
    <ng-container *ngIf="prefix; else noPrefix">
      <span
        *ngIf="
          hasHtmlTags('errors.' + prefix + '.' + error.key | transloco);
          else plainText
        "
        [innerHTML]="
          'errors.' + prefix + '.' + error.key | transloco | safeHtml
        "></span>
      <ng-template #plainText>
        {{ 'errors.' + prefix + '.' + error.key | transloco }}
      </ng-template>
    </ng-container>
    <ng-template #noPrefix>
      <span
        *ngIf="hasHtmlTags('errors.' + error.key | transloco); else noHtml"
        [innerHTML]="'errors.' + error.key | transloco | safeHtml"></span>
      <ng-template #noHtml>
        {{ 'errors.' + error.key | transloco }}
      </ng-template>
    </ng-template>
  </small>
</ng-container>
