import { Nullable, PaymentMethodEnum } from '@xspot-app/common';

export interface CreateBasketPaymentDto {
  price: number;
  paymentMethod: PaymentMethodEnum;
  invoiceData: Nullable<CreateBasketPaymentInvoiceDto>;
}

export interface CreateBasketPaymentInvoiceDto {
  taxNumber: string;
  name: string;
  city: string;
  streetWithNumber: string;
  flatNumber: Nullable<string>;
  postalCode: string;
}
