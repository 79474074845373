<ng-container *transloco="let t">
  <ng-container *ngIf="volumePrices$ | async as volumePrices">
    <ng-container *ngIf="volumePrices.length > 0">
      <div
        #volumePricePanel
        *ngIf="selectedLanguage$ | async as lang"
        class="volume-prices mb-3 p-3 border-round-lg flex flex-column relative">
        @for (
          volumePrice of volumePrices;
          track volumePrice.isMaximumThresholdReached
        ) {
          <div class="volume-prices-item flex gap-2 align-items-center">
            <div>
              <i class="cart-plus-icon block"></i>
            </div>

            @if (!volumePrice.isMaximumThresholdReached) {
              <p
                class="p-0 m-0 text-primary text-sm block"
                [innerHTML]="
                  t('offers.details.selectOfferDetailsPanel.volumePrice.info', [
                    volumePrice.threshold,
                    volumePrice.productNames[lang],
                    volumePrice.discount | async,
                  ])
                "></p>
            } @else {
              <p
                class="p-0 m-0 text-primary text-sm block"
                [innerHTML]="
                  t(
                    'offers.details.selectOfferDetailsPanel.volumePrice.maximumThresholdReached',
                    [
                      volumePrice.discount | async,
                      volumePrice.productNames[lang],
                    ]
                  )
                "></p>
            }
          </div>
        }
      </div>
    </ng-container>
  </ng-container>
</ng-container>
