import { Language } from '@xspot-app/common';

export const AvailableLanguages = {
  [Language.pl]: 'pl',
  [Language.en]: 'en',
};

export const AvailableCustomerLanguages = {
  [Language.pl]: 'pl',
  [Language.en]: 'en',
  [Language.de]: 'de',
  [Language.cs]: 'cs',
  [Language.es]: 'es',
  [Language.fr]: 'fr',
  [Language.uk]: 'uk',
};

export const defaultLanguage = AvailableLanguages[Language.pl];
