<ng-container *transloco="let t">
  <ng-container *ngIf="calendarType$ | async as calendarType">
    @if (actualPrice !== null && actualPrice !== originalPrice) {
      <div class="text-center discount-container">
        <span
          class="lg:block text-lg text-600 font-semibold lg:text-sm text-primary">
          <ng-container *ngIf="isMinimalPrice">
            {{ t('offers.from') }}
          </ng-container>
          {{
            (actualPrice | currencyFormatPipe | async) +
              (calendarType === CalendarTypeEnum.FlyspotPro && !isSlotSelected
                ? t('offers.currencyPerMinute')
                : '')
          }}
        </span>
        <span class="lg:block text-sm lg:text-xs line-through">
          <ng-container *ngIf="isMinimalPrice">
            {{ t('offers.from') }}
          </ng-container>
          {{
            (originalPrice | currencyFormatPipe | async) +
              (calendarType === CalendarTypeEnum.FlyspotPro && !isSlotSelected
                ? t('offers.currencyPerMinute')
                : '')
          }}
        </span>
      </div>
    } @else {
      <span>
        <ng-container *ngIf="isMinimalPrice">
          {{ t('offers.from') }}
        </ng-container>
        {{
          (originalPrice | currencyFormatPipe | async) +
            (calendarType === CalendarTypeEnum.FlyspotPro && !isSlotSelected
              ? t('offers.currencyPerMinute')
              : '')
        }}
      </span>
    }
  </ng-container>
</ng-container>
