<ng-container *transloco="let t">
  <div class="flex flex-column w-full gap-3">
    <div class="flex flex-wrap col-12 p-0">
      <div
        dragAndDrop
        class="surface-ground bg-center bg-no-repeat bg-primary overflow-hidden col-12 lg:col-8 relative"
        (fileDropped)="uploadFiles($event)"
        [ngStyle]="{
          'background-image': 'url(./assets/flyspot/image-placeholder.svg)',
          height: '40rem',
        }">
        <img
          *ngIf="
            selectedElement &&
            selectedElement.controls.kind.value === MediaFileKindEnum.Picture &&
            selectedElement.controls.uri
          "
          [src]="selectedElement.controls.uri.value"
          [alt]="selectedElement.controls.name.value"
          [ngStyle]="{ 'object-fit': 'cover' }"
          class="w-full h-full" />

        <iframe
          *ngIf="
            selectedElement &&
            selectedElement.controls.kind.value === MediaFileKindEnum.Movie &&
            selectedElement.controls.safeUri.value
          "
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          [src]="selectedElement.controls.safeUri.value"
          frameborder="0"></iframe>
      </div>
      <div class="flex flex-column border-1 surface-border col-12 lg:col-4">
        <div class="h-auto flex-grow-1">
          <p-table
            [value]="formArray.controls"
            [(selection)]="selectedElement"
            [reorderableColumns]="true"
            [selectionMode]="'single'"
            (onRowReorder)="onRowReorderHandler()">
            <ng-template pTemplate="header">
              <tr>
                <th></th>
                <th class="w-2">{{ t('ui.fileUpload.id') }}</th>
                <th class="w-full">{{ t('ui.fileUpload.name') }}</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-element let-index="rowIndex">
              <tr [pSelectableRow]="element" [pReorderableRow]="index">
                <td>
                  <i class="pi pi-bars" pReorderableRowHandle></i>
                </td>
                <td class="font-semibold">
                  {{ element.controls.ordinal.value + 1 }}.
                </td>
                <td>
                  @switch (element.controls.kind.value) {
                    @case (MediaFileKindEnum.Picture) {
                      {{ element.controls.name.value }}
                    }
                    @case (MediaFileKindEnum.Movie) {
                      {{ element.controls.uri.value }}
                    }
                    @default {
                      -
                    }
                  }
                </td>
                <td>
                  <p-button
                    severity="secondary"
                    icon="pi pi-trash text-900"
                    [rounded]="true"
                    [text]="true"
                    (onClick)="removeMedia(index)"></p-button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div
          class="flex justify-content-end border-top-1 surface-border p-2 w-full h-auto">
          <input
            #inputImageFile
            type="file"
            class="hidden"
            accept=".jpg,.jpeg,.png"
            (change)="uploadFile($event)" />
          <p-button
            [loading]="isLoading$ | async"
            (onClick)="inputImageFile.click()"
            class="mr-0 flex"
            type="button"
            icon="pi pi-plus"
            [label]="t('ui.fileUpload.addFile') | uppercase"></p-button>
        </div>
      </div>
    </div>
    <div class="w-full flex flex-column align-items-end text-xs text-gray-600">
      {{ t('ui.maxFileSize', { MaxFileSize: MaxFileSize_MB }) }}
    </div>
    <div class="flex flex-column col-12 lg:col-4 ml-auto px-0">
      <div class="flex">
        <div class="form-control w-full">
          <span class="p-float-label w-full">
            <input class="w-full" pInputText [formControl]="movieForm" />
            <label>{{ t('ui.fileUpload.url') | uppercase }}</label>
          </span>
        </div>
        <p-button
          class="w-min white-space-nowrap"
          type="button"
          [loading]="isLoading$ | async"
          [label]="t('ui.fileUpload.addUrl') | uppercase"
          [disabled]="!movieForm.valid"
          (onClick)="submitMovieForm()"></p-button>
      </div>
      <xspot-app-form-control-errors
        [control]="movieForm"
        [prefix]="'form'"></xspot-app-form-control-errors>
      <small
        class="text-error mt-2"
        *ngIf="errorMessage$ | async as errorMessage">
        {{ t('ui.' + errorMessage.summary, { MaxFileSize: MaxFileSize_MB }) }}
      </small>
    </div>
  </div>
</ng-container>
