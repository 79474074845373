import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionIdService } from '../services';
import { skippableInterceptorUrls } from '../consts';

export function sessionInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> {
  const sessionIdSevice = inject(SessionIdService);
  const sessionId = sessionIdSevice.sessionId$.getValue();

  if (
    !sessionId ||
    skippableInterceptorUrls.some(url => request.url.includes(url))
  ) {
    return next(request);
  }

  const modifiedRequest = request.clone({
    setHeaders: {
      sessionId: sessionId,
    },
  });

  return next(modifiedRequest);
}
