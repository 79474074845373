@if (enhancedPromotions$ | async; as promotions) {
  @if (promotions.length > 0) {
    <ng-container *transloco="let t">
      <ng-container *ngIf="lang$ | async as lang">
        <div class="{{ styleClass }}">
          <button
            class="border-2 border-primary font-bold lg:font-normal border-round-lg lg:text-base px-4 py-3 lg:p-0 lg:border-none flex align-items-center bg-none text-left text-sm cursor-pointer"
            tabindex="0"
            [xTooltip]="tooltipContent"
            tooltipPosition="top"
            (click)="toggleModal()">
            <i class="tag-price-icon mr-2"></i>
            <span
              class="text-primary offer-promotion-button lg:text-color-secondary">
              {{ t('offers.viewAllPromotions') }}
            </span>
            <ng-template #tooltipContent>
              <div class="text-sm font-semibold">
                {{ t('offers.promotion') }}
              </div>
              <div class="text-sm">{{ t('offers.clickToLearnMore') }}</div>
            </ng-template>
          </button>
          <p-dialog
            [modal]="true"
            class="offer-promotion-dialog"
            [(visible)]="isVisible"
            [closeOnEscape]="true"
            [closable]="true"
            [blockScroll]="true"
            [draggable]="false"
            [resizable]="false"
            [style]="{ width: '780px' }"
            [breakpoints]="{ '1200px': '85vm', '992px': '100vw' }">
            <ng-template pTemplate="header">
              <div class="flex flex-column w-full">
                @for (promotion of promotions; track promotion.id) {
                  <div
                    class="flex flex-column gap-3 w-full border-bottom-1 pb-3 mb-3 w-full bg-white surface-border">
                    <div class="flex w-full align-items-center flex-row gap-3">
                      <i class="tag-price-icon"></i>
                      <h3
                        class="text-oswald font-normal text-700 text-2xl uppercase">
                        {{ promotion.displayName[lang] }}
                      </h3>
                    </div>

                    <div>
                      <div class="font-semibold text-base line-height-2">
                        @switch (promotion.kind) {
                          @case (promotionKindEnum.Product) {
                            {{
                              t('offers.discountOn', {
                                name:
                                  getProductDisplayNames(promotion)
                                  | joinPipe: lang,
                              })
                            }}
                          }
                          @case (promotionKindEnum.Percent) {
                            {{
                              t('offers.discountOn', {
                                name:
                                  calculatePercentage(promotion?.value) + '%',
                              })
                            }}
                          }
                          @case (promotionKindEnum.Price) {
                            {{
                              t('offers.discountOn', {
                                name:
                                  promotion.value | currencyFormatPipe | async,
                              })
                            }}
                          }
                        }
                      </div>
                      <ul class="m-0 pl-5">
                        @for (
                          productVariant of promotion.offerVariantProducts;
                          track productVariant.id
                        ) {
                          <li class="font-semibold text-base line-height-2">
                            {{ productVariant.displayName[lang] }},
                            {{ productVariant.offerVariantName[lang] }}
                          </li>
                        }
                      </ul>
                    </div>

                    <div class="line-height-2">
                      <div>
                        <span>{{
                          t('offers.validFromTo', {
                            start:
                              (promotion.validFromDate | date: 'dd.MM.yyyy'),
                            end: (promotion.validToDate | date: 'dd.MM.yyyy'),
                          })
                        }}</span>
                        @if (promotion.isInSpecificHours) {
                          <span>, {{ t('offers.withinSelectedHours') }}</span>
                        }
                      </div>
                      @if (
                        promotion.daysOfWeek && promotion.daysOfWeek.length > 0
                      ) {
                        <div>
                          {{
                            t('offers.daysOfTheWeak', {
                              days:
                                (promotion.daysOfWeek
                                | joinPipe: lang : ', ' : 'common.weekDays.'
                                | lowercase),
                            })
                          }}
                        </div>
                      }
                      <div>
                        {{ t('offers.forCity') }}
                        {{ promotion?.locationNames | joinPipe: lang }}
                      </div>
                      <div *ngIf="promotion.description[lang]" class="mt-3">
                        {{ promotion.description[lang] }}
                      </div>
                    </div>
                    @if (
                      promotion?.documents && promotion.documents.length > 0
                    ) {
                      <a
                        [href]="promotion.documents[0].uri"
                        class="text-700 underline"
                        target="_blank"
                        rel="noopener noreferrer">
                        {{ t('offers.termsAndConditions') }}
                      </a>
                    }
                  </div>
                }
              </div>
            </ng-template>
          </p-dialog>
        </div>
      </ng-container>
    </ng-container>
  }
}
