import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PL, GB, DE, CZ, ES, UA, FR } from 'country-flag-icons/string/3x2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  provideTranslocoScope,
  TranslocoDirective,
  TranslocoService,
} from '@ngneat/transloco';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { map } from 'rxjs';
import {
  AuthService,
  Language,
  scopeLoaderFactory,
  UserService,
} from '@xspot-app/common';
import { CurrencyEnum } from '../../enums';
import { CurrencyModel } from '../../models/currency.model';
import { Currency } from '../../state/currency/currency.actions';
import { Store } from '@ngxs/store';
import { CurrencyState } from '../../state/currency';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-language-currency-select',
  standalone: true,
  imports: [ButtonModule, CommonModule, OverlayPanelModule, TranslocoDirective],
  templateUrl: './language-currency-select.component.html',
  styleUrls: ['./language-currency-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideTranslocoScope({
      scope: 'languageSelect',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class LanguageCurrencySelectComponent implements OnInit {
  protected CURRENCIES: CurrencyModel[] = [
    {
      name: 'languageSelect.polishZloty',
      code: CurrencyEnum.PLN,
      flag: 'zł',
    },
    {
      name: 'languageSelect.euro',
      code: CurrencyEnum.EUR,
      flag: '€',
    },
  ];

  public LANGUAGES: {
    name: string;
    code: keyof typeof Language;
    flag: SafeHtml;
  }[] = [
    {
      name: 'Polski',
      code: 'pl',
      flag: this.sanitizer.bypassSecurityTrustHtml(PL),
    },
    {
      name: 'Čeština',
      code: 'cs',
      flag: this.sanitizer.bypassSecurityTrustHtml(CZ),
    },
    {
      name: 'Deutsch',
      code: 'de',
      flag: this.sanitizer.bypassSecurityTrustHtml(DE),
    },
    {
      name: 'English',
      code: 'en',
      flag: this.sanitizer.bypassSecurityTrustHtml(GB),
    },
    {
      name: 'Español',
      code: 'es',
      flag: this.sanitizer.bypassSecurityTrustHtml(ES),
    },
    {
      name: 'Français',
      code: 'fr',
      flag: this.sanitizer.bypassSecurityTrustHtml(FR),
    },
    {
      name: 'Українська',
      code: 'uk',
      flag: this.sanitizer.bypassSecurityTrustHtml(UA),
    },
  ];

  protected selectedCurrency$ = this.store.select(
    CurrencyState.getSelectedCurrency
  );
  protected selectedCurrencyObject$ = this.selectedCurrency$.pipe(
    untilDestroyed(this),
    map(currencyCode => {
      return (
        this.CURRENCIES.find(c => c.code === currencyCode) || this.CURRENCIES[0]
      );
    })
  );

  protected selectedLang$ = this.translocoService.langChanges$;

  protected selectedLangFlag$ = this.selectedLang$.pipe(
    map(lang => {
      const selectedLang = this.LANGUAGES.find(l => l.code === lang);
      return selectedLang?.flag;
    })
  );

  public ngOnInit(): void {
    this.store.dispatch(new Currency.LoadCurrencyFromStorage());
  }

  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private store: Store,
    private translocoService: TranslocoService,
    private userService: UserService
  ) {}

  protected changeLanguage(lang: keyof typeof Language): void {
    localStorage.setItem('selectedLanguage', lang);
    this.translocoService.setActiveLang(lang);

    if (this.authService.isLoggedIn()) {
      this.userService.updateLanguage(Language[lang]).subscribe();
    }
  }

  protected changeCurrency(currency: CurrencyModel): void {
    this.store.dispatch(new Currency.SetCurrency(currency.code));
  }
}
