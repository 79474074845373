import {
  LanguageControlGroup,
  LocationControlModel,
  Nullable,
} from '@xspot-app/common';
import {
  InvalidBasketItemProductDto,
  InvalidBasketItemUpsellDto,
} from '../dtos';
import { BasketItemSourceEnum } from '../enums';

export interface InvalidBasketItemModel {
  basketItemId: string;
  offerId: string;
  offerName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
  coverMobileUri: string;
  coverDesktopUri: string;
  locations: LocationControlModel[];
  originalPrice: number;
  actualPrice: number;
  products: InvalidBasketItemProductDto[];
  upsells: InvalidBasketItemUpsellDto[];
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
}
