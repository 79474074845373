import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  LanguageControlGroup,
  VolumePriceDiscountKindEnum,
  ZeroOrTwoDecimalDigitsPipe,
} from '@xspot-app/common';
import {
  Observable,
  map,
  combineLatest,
  of,
  pairwise,
  tap,
  switchMap,
  startWith,
} from 'rxjs';
import {
  CustomerOfferDetailsVariantProductVolumePriceItemModel,
  CustomerOfferDetailsVariantVolumePriceModel,
  VolumePriceModel,
} from '../../../domain';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { CurrencyFormatPipe } from '@xspot-app/shared/ui';
import { Store } from '@ngxs/store';
import { OffersState } from '../../../state';

@UntilDestroy()
@Component({
  selector: 'xspot-app-volume-prices',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './volume-prices.component.html',
  styleUrl: './volume-prices.component.scss',
  providers: [ZeroOrTwoDecimalDigitsPipe, CurrencyFormatPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumePricesComponent {
  @ViewChild('volumePricePanel', { read: ElementRef })
  private volumePricePanel?: ElementRef<HTMLButtonElement>;

  protected selectedLanguage$ = this.translocoService.langChanges$;
  public volumePrices$: Observable<VolumePriceModel[]> = combineLatest([
    this.store.select(OffersState.selectedVariant),
    this.store.select(OffersState.selectedOfferVariantProducts),
  ]).pipe(
    untilDestroyed(this),
    map(([selectedVariant, selectedVariantProducts]) => {
      const volumePrices: VolumePriceModel[] = [];
      if (!selectedVariant || !selectedVariantProducts) {
        return volumePrices;
      }

      selectedVariant.volumePrices.forEach(volumePrice => {
        const currentQuantity = selectedVariantProducts
          .filter(x => volumePrice.products.some(p => p.id === x.id))
          .map(x => x.quantity)
          .reduce((sum, quantity) => sum + quantity, 0);
        const volumePriceItem = this.getCurrentVolumePriceItem(
          volumePrice,
          currentQuantity
        );
        return volumePrices.push({
          productNames: this.getProductNames(
            volumePrice.products.map(x => x.displayName)
          ),
          discount: this.getVolumePriceDiscount(
            volumePriceItem.discountValue,
            volumePrice.discountKind
          ),
          isMaximumThresholdReached: !volumePrice.items.some(
            x => x.from > currentQuantity
          ),
          threshold: volumePriceItem.from,
        });
      });
      return volumePrices;
    }),
    startWith([]),
    pairwise(),
    tap(([prev, curr]) => {
      if (prev.length > 0 && curr.length > 0) {
        const hasChanges = curr.some((currVolumePrice, index) => {
          const prevVolumePrice = prev[index];
          return (
            currVolumePrice.threshold !== prevVolumePrice.threshold ||
            currVolumePrice.isMaximumThresholdReached !==
              prevVolumePrice.isMaximumThresholdReached
          );
        });

        if (hasChanges) {
          this.emitVolumePriceBlinkEffect();
        }
      }
    }),
    switchMap(([, curr]) => of(curr))
  );

  constructor(
    private store: Store,
    private translocoService: TranslocoService,
    private zeroOrTwoDecimalDigitsPipe: ZeroOrTwoDecimalDigitsPipe,
    private currencyFormatPipe: CurrencyFormatPipe
  ) {}

  private getProductNames(
    productNames: LanguageControlGroup[]
  ): LanguageControlGroup {
    const result: LanguageControlGroup = {};
    productNames.forEach(product => {
      Object.keys(product).forEach(lang => {
        if (!result[lang]) {
          result[lang] = '';
        }
        result[lang] += product[lang] + ', ';
      });
    });

    Object.keys(result).forEach(lang => {
      result[lang] = result[lang].slice(0, -2);
    });

    return result;
  }

  private getCurrentVolumePriceItem(
    volumePrice: CustomerOfferDetailsVariantVolumePriceModel,
    currentQuantity: number
  ): CustomerOfferDetailsVariantProductVolumePriceItemModel {
    return (
      volumePrice.items.find(x => currentQuantity < x.from) ||
      volumePrice.items.reduce((max, item) =>
        item.from > max.from ? item : max
      )
    );
  }

  protected getVolumePriceDiscount(
    value: number,
    kind: VolumePriceDiscountKindEnum
  ): Observable<string> {
    if (kind === VolumePriceDiscountKindEnum.Percent) {
      return of(this.zeroOrTwoDecimalDigitsPipe.transform(value) + '%');
    }

    if (kind === VolumePriceDiscountKindEnum.Price) {
      return this.currencyFormatPipe.transform(value).pipe(
        untilDestroyed(this),
        map(formattedPrice => formattedPrice ?? '')
      );
    }

    return of('');
  }

  private emitVolumePriceBlinkEffect(): void {
    this.volumePricePanel?.nativeElement.classList.remove('highlight-once');
    setTimeout(
      () =>
        this.volumePricePanel?.nativeElement.classList.add('highlight-once'),
      10
    );
  }
}
