import { Injectable } from '@angular/core';
import { CheckoutState } from '../domain';
import { Nullable } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class ShoppingPathStateService {
  private readonly CHECKOUT_KEY = 'checkoutData';

  public setCheckoutState(state: CheckoutState): void {
    sessionStorage.setItem(this.CHECKOUT_KEY, JSON.stringify(state));
  }

  public resetCheckoutState(): void {
    sessionStorage.removeItem(this.CHECKOUT_KEY);
  }

  public getCheckoutState(): Nullable<CheckoutState> {
    const paymentStateJson = sessionStorage.getItem(this.CHECKOUT_KEY);

    return paymentStateJson
      ? (JSON.parse(paymentStateJson) as CheckoutState)
      : null;
  }
}
