import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarDayModel, SelectedSlotModel } from '../../../../domain';
import { CalendarDayStatusEnum, CalendarStepEnum } from '../../../../enums';
import { TranslocoDirective } from '@ngneat/transloco';
import { SlotPriceComponent } from '../slot-price/slot-price.component';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { Calendars, CalendarsState } from '../../../../state';
import { NgLetModule } from 'ng-let';
import { IsItemSelectedDirective, isSameDay } from '@xspot-app/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { hasActionsExecuting } from '@ngxs-labs/actions-executing';

@UntilDestroy()
@Component({
  selector: 'xspot-app-calendar-day',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    SlotPriceComponent,
    NgLetModule,
    IsItemSelectedDirective,
  ],
  templateUrl: './calendar-day.component.html',
  styleUrl: './calendar-day.component.scss',
})
export class CalendarDayComponent {
  @Input({ required: true }) public calendarDay!: CalendarDayModel;
  protected CalendarDayStatus = CalendarDayStatusEnum;
  @Output() public calendarDayClick = new EventEmitter<void>();

  @Select(CalendarsState.currentStep)
  public currentStep$!: Observable<CalendarStepEnum>;
  @Select(CalendarsState.selectedDate)
  public selectedDate$!: Observable<DateTime>;
  @Select(CalendarsState.selectedSlots)
  public selectedSlots$!: Observable<SelectedSlotModel[]>;

  @Select(hasActionsExecuting([Calendars.LoadDays, Calendars.LoadVoucherDays]))
  protected isLoading$!: Observable<boolean>;

  protected CalendarStepEnum = CalendarStepEnum;

  constructor(private store: Store) {}

  public isDaySelected(): boolean {
    const selectedSlots = this.store.selectSnapshot(
      CalendarsState.selectedSlots
    );
    return (
      selectedSlots?.some(x => isSameDay(x.dateTime, this.calendarDay.date)) ??
      false
    );
  }
}
