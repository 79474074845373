import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { UiContext } from '../enums';
import { inject } from '@angular/core';
import { UiContextService } from '../services';

export function contextGuardFn(
  route: ActivatedRouteSnapshot
):
  | boolean
  | UrlTree
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree> {
  if (
    Object.values(UiContext).find(
      context => context.toLowerCase() === route.url[0]?.path
    )
  ) {
    return true;
  }

  const router = inject(Router);
  const uiContextService = inject(UiContextService);

  return uiContextService.currentContext$.pipe(
    map(context =>
      router.createUrlTree(
        ['/', context.toLowerCase(), ...route.url.map(url => url.path)],
        {
          queryParams: route.queryParams,
          queryParamsHandling: 'merge',
        }
      )
    )
  );
}
