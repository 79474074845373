import {
  isSameMonthAndYear,
  LanguageControlGroup,
  Nullable,
} from '@xspot-app/common';
import { CalendarDaysModel } from '../../domain';
import { CalendarDaysDto } from '../../dtos';
import { DateTime } from 'luxon';

export class CalendarDaysModelMapper {
  public static map(
    calendarDaysDto: CalendarDaysDto,
    calendarYearMonth: DateTime
  ): CalendarDaysModel {
    return {
      ...calendarDaysDto,
      days: calendarDaysDto.days.map(day => {
        const hasPromotion: boolean = Math.random() < 0.2;
        const productPromotionName: Nullable<LanguageControlGroup> =
          hasPromotion
            ? {
                cs: 'Mock promotion',
                de: 'Mock promotion',
                es: 'Mock promotion',
                fr: 'Mock promotion',
                uk: 'Mock promotion',
                pl: 'Mock promotion',
                en: 'Mock promotion',
              }
            : null;
        const hasAnyPromotion =
          !!productPromotionName ||
          (day.actualPrice != null && day.originalPrice != null
            ? day.actualPrice < day.originalPrice
            : false);
        const date: DateTime = DateTime.fromFormat(day.date, 'yyyy-MM-dd');
        return {
          ...day,
          date,
          isCurrentMonth: isSameMonthAndYear(date, calendarYearMonth),
          productPromotionName,
          hasAnyPromotion,
        };
      }),
    };
  }
}
