import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@xspot-app/common';
import { OrderPaymentUrlDto } from '../dtos/order-payment-url.dto';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) {}

  public getPaymentUrl(orderNumber: string): Observable<OrderPaymentUrlDto> {
    return this.http.get<OrderPaymentUrlDto>(
      `${environment.apiUrl}/api/orders/${orderNumber}/paymentUrl`
    );
  }
}
