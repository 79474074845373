import { Nullable } from '../../helpers';

export interface GusCompanyDataDto {
  taxNumber: string;
  name: string;
  state: Nullable<string>;
  city: Nullable<string>;
  postalCode: Nullable<string>;
  street: Nullable<string>;
  streetNumber: Nullable<string>;
  flatNumber: Nullable<string>;
}
