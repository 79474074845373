<ng-container *transloco="let t">
  <form class="flex flex-column gap-2" [formGroup]="phoneNumberForm">
    <div class="flex">
      <span class="p-float-label">
        <p-dropdown
          [options]="prefixesData"
          formControlName="prefix"
          optionLabel="name"
          optionValue="prefix"
          [filter]="true"
          filterBy="prefix"
          styleClass="h-full"
          [lazy]="true"
          inputId="prefix">
          <ng-template pTemplate="selectedItem">
            <ng-container *ngIf="prefixControl.value">{{
              prefixControl.value
            }}</ng-container>
          </ng-template>
          <ng-template let-code pTemplate="item">
            <div class="flex align-items-center gap-3 md:w-20rem xl:w-30rem">
              <span>{{ code.flag }}</span>
              <span class="white-space-normal">{{ code.name }}</span>
              <span class="ml-auto">{{ code.prefix }}</span>
            </div>
          </ng-template>
        </p-dropdown>
        <label for="prefix">Prefix</label>
      </span>

      <span class="p-float-label flex-grow-1 -ml-1">
        <input
          class="w-full h-full"
          (blur)="onTouched()"
          pInputText
          id="phoneNumber"
          formControlName="number"
          type="tel"
          (input)="formatPhoneNumber($event)" />
        <label htmlFor="number">{{ t(phoneNumberTranslationKey) }}</label>
      </span>
    </div>

    <ng-container
      *ngIf="
        phoneNumberControl.invalid &&
        phoneNumberControl.touched &&
        phoneNumberControl.errors as errors
      ">
    </ng-container>
  </form>
</ng-container>
