import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { FacebookAuthService } from '../../services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FacebookLogInDto } from '../../dtos';
import { scopeLoaderFactory } from '@xspot-app/common';
import { BehaviorSubject, catchError, EMPTY, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'xspot-app-fb-login-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslocoDirective],
  templateUrl: './fb-login-button.component.html',
  styleUrls: ['./fb-login-button.component.scss'],
  providers: [
    FacebookAuthService,
    provideTranslocoScope({
      scope: 'loginForm',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class FbLoginButtonComponent {
  @Input({ required: true }) public isLoading$ = new BehaviorSubject(false);
  @Output() public logIn = new EventEmitter<FacebookLogInDto>();
  constructor(private fbAuthService: FacebookAuthService) {}

  public logInWithFb(): void {
    this.fbAuthService
      .loginWithFacebook()
      .pipe(
        untilDestroyed(this),
        tap((result: FacebookLogInDto) => {
          this.logIn.emit(result);
        }),
        catchError(() => {
          this.isLoading$.next(false);
          return EMPTY;
        })
      )
      .subscribe();
  }
}
