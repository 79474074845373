import {
  LanguageControlGroup,
  LocationControlModel,
  Nullable,
} from '@xspot-app/common';
import { BasketItemSourceEnum } from '../enums';

export interface BasketModel {
  totalPrice: number;
  originalPrice: number;
  offers: BasketOfferModel[];
}

export interface BasketOfferModel {
  id: string;
  offerId: string;
  offerVariantId: string;
  totalPrice: number;
  originalPrice: number;
  name: LanguageControlGroup;
  variantName: LanguageControlGroup;
  locations: LocationControlModel[];
  nonReservationProducts: BasketOfferProductModel[];
  reservationProducts: BasketOfferReservationProductModel[];
  allProducts: BasketOfferProductModel[];
  upsells: BasketOfferUpsellModel[];
  detailsVisibility: boolean;
  coverDesktopUri: string;
  coverMobileUri: string;
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
  isCreditOffer: boolean;
}

export interface BasketOfferProductModel {
  offerVariantProductId: string;
  totalPrice: number;
  actualPrice: number;
  originalPrice: number;
  name: LanguageControlGroup;
  quantity: number;
}

export interface BasketOfferUpsellModel {
  upsellId: string;
  totalPrice: number;
  name: LanguageControlGroup;
  quantity: number;
}

export interface BasketOfferReservationProductModel {
  date: string;
  slots: BasketOfferSlotModel[];
  isReservationPro: boolean;
}

export interface BasketOfferSlotModel {
  timeFrom: string;
  timeTo: string;
  products: BasketOfferProductModel[];
  minutes: Nullable<number>;
}
