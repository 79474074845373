<p-menu
  #overlayMenu
  [model]="items"
  [popup]="true"
  [style]="{ width: '208px' }"
  styleClass="py-0 border-1 border-400 drop-menu"
  *transloco="let t">
  <ng-template pTemplate="item" let-item>
    <div
      class="drop-menu__item cursor-pointer"
      (click)="item.type === menuType.Link ? item.action() : null">
      <ng-template #companyMenu>
        <div class="flex align-items-center gap-2">
          <ng-container *ngIf="item.icon; else labelAvatar">
            <p-avatar
              [image]="item.icon"
              [style]="{ 'max-width': '24px', 'max-height': '24px' }"
              styleClass="drop-menu__avatar surface-500 line-height-3"
              shape="circle"></p-avatar>
          </ng-container>
          <span class="text-700">{{ item.label }}</span>
        </div>
      </ng-template>

      <ng-template #labelAvatar>
        <p-avatar
          *ngIf="item.label"
          [label]="item.label[0]"
          [style]="{ 'max-width': '24px', 'max-height': '24px' }"
          styleClass="drop-menu__avatar surface-500 text-200 text-xs line-height-3"
          shape="circle"></p-avatar>
      </ng-template>

      <ng-container *ngIf="item.type === menuType.Link; else companyMenu">
        <p class="text-700">{{ t(item.label) }}</p>
      </ng-container>
    </div>
  </ng-template>
</p-menu>
