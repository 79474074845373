import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import {
  ErrorMessage,
  getErrorMessage,
  MaxFileSize_MB,
  Nullable,
  scopeLoaderFactory,
  StringUtil,
} from '@xspot-app/common';
import { DocumentModel } from '../../models';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FileUploadErrorCodes, FileUploadErrorMessages } from '../../consts';
import { LibraryFilesDto } from '../../dtos';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { FilesLibraryUploadService } from '../../services';

@UntilDestroy()
@Component({
  selector: 'xspot-app-files-upload',
  standalone: true,
  imports: [ButtonModule, CommonModule, TableModule, TranslocoDirective],
  templateUrl: './files-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class FilesUploadComponent {
  @Input() public maxFilesOnList: number = 10;
  @Input() public disabled: boolean = false;

  public files$ = new BehaviorSubject<DocumentModel[]>([]);

  protected errorMessage$ = new BehaviorSubject<Nullable<ErrorMessage>>(null);

  protected isLoading$ = new BehaviorSubject<boolean>(false);

  public MaxFileSize_MB = MaxFileSize_MB;

  constructor(private filesLibraryUploadService: FilesLibraryUploadService) {}

  protected onRowReorderHandler(): void {
    const files = this.files$.getValue();

    this.files$.next(
      files.map((item, index) => {
        return {
          uri: item.uri,
          originalFileName: item.originalFileName,
          id: item.id,
          ordinalNumber: index,
        } as DocumentModel;
      })
    );
  }

  protected removeLegalFile(legalFile: DocumentModel): void {
    if (legalFile) {
      const files = this.files$.getValue();

      const indexToRemove: number = files.indexOf(legalFile);
      files.splice(indexToRemove, 1);
      this.files$.next(
        files.map((item, index) => {
          return {
            uri: item.uri,
            originalFileName: item.originalFileName,
            id: item.id,
            ordinalNumber: index,
          } as DocumentModel;
        })
      );
      this.errorMessage$.next(null);
    }
  }

  public uploadLegalFiles(event: Event): void {
    this.errorMessage$.next(null);
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;

    const files = this.files$.getValue();

    if (files.length >= this.maxFilesOnList) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.TooManyFiles,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      fileInput.value = StringUtil.empty;
      return;
    }

    if (!fileInput.files || !fileInput.files[0]) return;
    const file: File = fileInput.files[0];
    this.sendLegalFile(file);
    fileInput.value = StringUtil.empty;
  }

  private sendLegalFile(file: File): void {
    this.isLoading$.next(true);
    this.errorMessage$.next(null);

    this.filesLibraryUploadService
      .upload([file])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: LibraryFilesDto): void => {
          this.isLoading$.next(false);

          const files = this.files$.getValue();
          files.push({
            ...result[0],
            isNew: true,
            ordinalNumber: files.length,
          });

          this.files$.next(files);
        },
        error: ({ error }): void => {
          this.isLoading$.next(false);
          this.errorMessage$.next(
            getErrorMessage(
              error.errorCode,
              FileUploadErrorMessages,
              () => 'error'
            )
          );
        },
      });
  }

  protected downloadFile(file: DocumentModel): void {
    if (file.uri) {
      window.open(file.uri, '_blank');
    }
  }
}
