<ng-container *ngLet="currentStep$ | async as currentStep">
  <xspot-app-calendar-days
    *ngIf="currentStep === CalendarStepEnum.Days"
    (loadCalendarDays)="
      this.loadCalendarDays.emit({
        startDate: $event.startDate,
        endDate: $event.endDate,
      })
    "
    (loadCalendarDaySlots)="
      this.loadCalendarDaySlots.emit({ date: $event.date })
    "
    (selectedDate)="onSelectedDate($event.date)"></xspot-app-calendar-days>

  <xspot-app-calendar-day-slots
    *ngIf="currentStep === CalendarStepEnum.DaySlots"
    (loadCalendarDays)="
      this.loadCalendarDays.emit({
        startDate: $event.startDate,
        endDate: $event.endDate,
      })
    "
    (loadCalendarDaySlots)="
      this.loadCalendarDaySlots.emit({ date: $event.date })
    "
    (selectedDate)="onSelectedDate($event.date)">
  </xspot-app-calendar-day-slots
></ng-container>
