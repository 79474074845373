import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Observable } from 'rxjs';
import {
  CalendarService,
  IsItemSelectedDirective,
  isPreviousDate,
  Months,
  Nullable,
} from '@xspot-app/common';
import { Select, Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { TimeSlotsComponent } from './time-slots/time-slots.component';
import { Calendars, CalendarsState } from '../../../state';
import {
  CalendarDaysModel,
  CalendarDaySlotModel,
  CalendarDayModel,
} from '../../../domain';
import { CalendarTypeEnum, CalendarDayStatusEnum } from '../../../enums';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WeekdayAbbreviationsComponent } from '../shared/weekday-abbreviations/weekday-abbreviations.component';
import { CalendarDayComponent } from '../shared/calendar-day/calendar-day.component';

@UntilDestroy()
@Component({
  selector: 'xspot-app-calendar-day-slots',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CalendarModule,
    OverlayPanelModule,
    IsItemSelectedDirective,
    TranslocoDirective,
    ProgressSpinnerModule,
    TimeSlotsComponent,
    CalendarDayComponent,
    WeekdayAbbreviationsComponent,
  ],
  templateUrl: './calendar-day-slots.component.html',
  styleUrls: ['./calendar-day-slots.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDaySlotsComponent {
  @Output() public loadCalendarDaySlots = new EventEmitter<{
    date: DateTime;
  }>();
  @Output() public loadCalendarDays = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();
  @Output() public selectedDate = new EventEmitter<{
    date: DateTime;
  }>();

  protected filterDate: DateTime = DateTime.now();
  @Select(CalendarsState.type)
  public calendarType$!: Observable<CalendarTypeEnum>;
  @Select(CalendarsState.days)
  public calendarDays$!: Observable<CalendarDaysModel>;
  @Select(CalendarsState.daySlots)
  public calendarDaySlots$!: Observable<Nullable<CalendarDaySlotModel[]>>;
  @Select(CalendarsState.selectedDate)
  public selectedDate$!: Observable<DateTime>;
  @Select(CalendarsState.currentYearMonth)
  public currentYearMonth$!: Observable<DateTime>;

  protected Months = Months;
  protected calendarMinAndDefaultDate: Date = new Date();

  constructor(
    private store: Store,
    private calendarService: CalendarService
  ) {}

  public changeWeek(offset: number): void {
    const newDayOfWeek = this.store
      .selectSnapshot(CalendarsState.days)
      ?.days?.[0].date.plus({ week: offset })
      .endOf('week');
    if (
      !newDayOfWeek ||
      isPreviousDate(
        newDayOfWeek,
        DateTime.fromJSDate(this.calendarMinAndDefaultDate)
      )
    ) {
      return;
    }

    const days = this.calendarService.getDaysInWeek(newDayOfWeek);
    this.loadCalendarDays.emit({
      startDate: days[0],
      endDate: days[days.length - 1],
    });
  }

  protected onSelectDay(day: CalendarDayModel): void {
    if (day.status !== CalendarDayStatusEnum.Available) {
      return;
    }
    this.checkToResetDaySlots();
    this.store.dispatch(new Calendars.UpdateCurrentYearMonth(day.date));
    this.selectedDate.emit({ date: day.date });
    this.filterDate = day.date;
  }

  protected onSelectDate(jsDate: Date): void {
    this.checkToResetDaySlots();
    const date: DateTime = DateTime.fromJSDate(jsDate);
    this.store.dispatch(new Calendars.UpdateCurrentYearMonth(date));
    this.selectedDate.emit({ date });
    this.filterDate = date;
  }

  private checkToResetDaySlots(): void {
    const daySlots = this.store.selectSnapshot(CalendarsState.daySlots);
    if (daySlots?.length === 0) {
      this.store.dispatch(new Calendars.ResetDaySlots());
    }
  }
}
