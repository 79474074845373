import {
  LanguageControlGroup,
  Nullable,
  ProductKindEnum,
} from '@xspot-app/common';
import { BasketItemSourceEnum } from '../enums';

export interface BasketDto {
  actualPrice: number;
  originalPrice: number;
  items: BasketOfferDto[];
  creditPaymentInfo?: CreditPaymentInfoDto;
}

export interface CreditPaymentInfoDto {
  canBePaidByCredit: boolean;
  isEnoughCreditAmount?: boolean;
  amountToPay?: number;
  creditAmountToPay?: number;
}

export interface BasketOfferDto {
  id: string;
  offerId: string;
  offerVariantId: string;
  actualPrice: number;
  originalPrice: number;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  locationIds: string[];
  products: BasketOfferProductDto[];
  upsells: BasketOfferUpsellDto[];
  coverDesktopUri: string;
  coverMobileUri: string;
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherCode: string;
}

export interface BasketOfferProductDto {
  id: string;
  offerVariantProductId: string;
  actualPrice: number;
  originalPrice: number;
  productDisplayName: LanguageControlGroup;
  quantity: number;
  date: Nullable<string>;
  from: Nullable<string>;
  isMinimalAmount: boolean;
  numberOfMinutes: Nullable<number>;
  productKind: ProductKindEnum;
}

export interface BasketOfferUpsellDto {
  id: string;
  upsellId: string;
  actualPrice: number;
  originalPrice: number;
  upsellName: LanguageControlGroup;
  quantity: number;
}
