import { LanguageControlGroup } from '@xspot-app/common';

export interface VoucherModel {
  id: string;
  code: string;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  validTo: string;
  isUpsellIncludedInOffer: boolean;
  products: ProductVariantModel[];
  locationIds: string[];
  locations: LanguageControlGroup[];
}

export interface ProductVariantModel {
  productName: LanguageControlGroup;
  quantity: number;
}
