import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import { CustomerOfferDetailsVariantProductModel } from '../../../domain';
import { OfferProductFormEnum } from '@xspot-app/common';
import { Store } from '@ngxs/store';
import { Calendars, Offers, OffersState } from '../../../state';
import { Observable, of, switchMap } from 'rxjs';
import { VariantProductModel } from '../../../domain/variant-product.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-product-selector',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './product-selector.component.html',
  styleUrl: './product-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectorComponent {
  @Input({ required: true })
  public product!: CustomerOfferDetailsVariantProductModel;

  public selectedVariantProduct$: Observable<VariantProductModel> = this.store
    .select(OffersState.selectedVariantProducts)
    .pipe(
      untilDestroyed(this),
      switchMap(variantProducts =>
        of(variantProducts.find(x => x.id === this.product.id)!)
      )
    );

  protected selectedLanguage$ = this.translocoService.langChanges$;
  protected OfferProductFormEnum = OfferProductFormEnum;

  constructor(
    private translocoService: TranslocoService,
    private store: Store
  ) {}

  protected changeProductAmount(amount: number): void {
    if (amount < this.product.minAmount! || amount > this.product.maxAmount!) {
      return;
    }
    this.store.dispatch(
      new Offers.UpdateSelectedVariantProductAmount(this.product.id, amount)
    );
    this.store.dispatch(new Calendars.ResetCalendarState());
    this.store.dispatch(new Offers.ResetSelectedUpsells());
  }
}
