import { Nullable } from '@xspot-app/common';
import { CalendarDayStatusEnum } from '../enums';

export interface CalendarDaysDto {
  minutesAmount: Nullable<number>;
  isMinimalMinutesAmount: Nullable<boolean>;
  days: CalendarDayDto[];
}

export interface CalendarDayDto {
  date: string;
  status: CalendarDayStatusEnum;
  originalPrice: Nullable<number>;
  actualPrice: Nullable<number>;
  isMinimalPrice: Nullable<boolean>;
}
