import { Component, Input } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { RouterLink } from '@angular/router';
import { UiContextService } from '@xspot-app/common';
import { map } from 'rxjs';

@Component({
  selector: 'xspot-app-auth-navigation',
  standalone: true,
  imports: [CommonModule, ButtonModule, RouterLink],
  templateUrl: './auth-navigation.component.html',
  styleUrls: ['./auth-navigation.component.scss'],
})
export class AuthNavigationComponent {
  @Input() public hasBackButton = true;

  public logoURL$ = this.uiContextService.currentContext$.pipe(
    map(context => `assets/${context.toLowerCase()}/logo.svg`)
  );

  constructor(
    private location: Location,
    private uiContextService: UiContextService
  ) {}

  public back(): void {
    this.location.back();
  }
}
