import { LanguageControlGroup, Nullable } from '@xspot-app/common';
import { CalendarDayStatusEnum } from '../enums';
import { DateTime } from 'luxon';

export interface CalendarDaysModel {
  minutesAmount: Nullable<number>;
  isMinimalMinutesAmount: Nullable<boolean>;
  days: CalendarDayModel[];
}

export interface CalendarDayModel {
  date: DateTime;
  status: CalendarDayStatusEnum;
  originalPrice: Nullable<number>;
  actualPrice: Nullable<number>;
  isMinimalPrice: Nullable<boolean>;
  isCurrentMonth: boolean;
  productPromotionName: Nullable<LanguageControlGroup>;
  hasAnyPromotion: boolean;
}
