import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrencyEnum } from '../enums/currency.enum';
import { NbpCurrencyDto } from '../dtos';
import { environment } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  private httpWithoutInterceptors: HttpClient;

  constructor(private httpBackend: HttpBackend) {
    this.httpWithoutInterceptors = new HttpClient(this.httpBackend);
  }

  /**
   * Pobiera kurs waluty z API NBP dla dnia poprzedniego
   * @param code - Kod waluty (ISO_4217)
   */
  public getCurrencyRate(code: CurrencyEnum): Observable<NbpCurrencyDto> {
    return this.httpWithoutInterceptors.get<NbpCurrencyDto>(
      `${environment.nbpUrl}/exchangerates/rates/a/${code}`
    );
  }
}
