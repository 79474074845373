import { LanguageControlGroup, Nullable } from '@xspot-app/common';
import { BasketOfferProductDto } from './basket.dto';
import { BasketItemSourceEnum } from '../enums';

export interface InvalidBasketDto {
  unavailable: InvalidBasketItemDto[];
  priceChanged: InvalidBasketItemDto[];
}

export interface InvalidBasketItemDto {
  basketItemId: string;
  offerId: string;
  offerName: LanguageControlGroup;
  offerVariantId: string;
  offerVariantName: LanguageControlGroup;
  coverMobileUri: string;
  coverDesktopUri: string;
  locationIds: string[];
  originalPrice: number;
  actualPrice: number;
  products: InvalidBasketItemProductDto[];
  upsells: InvalidBasketItemUpsellDto[];
  kind: BasketItemSourceEnum;
  voucherId: Nullable<string>;
  voucherValidTo: Nullable<string>;
  voucherCode: string;
}

export interface InvalidBasketItemUpsellDto {
  basketItemUpsellId: string;
  upsellName: LanguageControlGroup;
  quantity: number;
  originalPrice: number;
  actualPrice: number;
}

export interface InvalidBasketItemProductDto extends BasketOfferProductDto {}
