import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class CalendarFileService {
  constructor(private http: HttpClient) {}

  public getOrderEventsCalendar(
    orderNumber: string
  ): Observable<HttpResponse<Blob>> {
    return this.http.get(
      `${environment.apiUrl}/api/Orders/${orderNumber}/EventsCalendar`,
      { responseType: 'blob', observe: 'response' }
    );
  }
}
