import { Component, Input } from '@angular/core';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { scopeLoaderFactory } from '@xspot-app/common';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { GoogleAuthService } from '../../services';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'xspot-app-google-login-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslocoDirective],
  templateUrl: './google-login-button.component.html',
  styleUrls: ['./google-login-button.component.scss'],
  providers: [
    provideTranslocoScope({
      scope: 'loginForm',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class GoogleLoginButtonComponent {
  @Input() public loading = false;
  constructor(
    private googleAuthService: GoogleAuthService,
    private route: ActivatedRoute
  ) {}

  public logInWithGoogle(): void {
    const redirectTo = this.route.snapshot.queryParams['redirectTo'];
    this.googleAuthService.login(redirectTo);
  }
}
