import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import type { MenuTopBar } from '../../models';
import { DropMenuComponent } from '../drop-menu/drop-menu.component';
import { MenuService } from '../../services';
import { Observable } from 'rxjs';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { Language, scopeLoaderFactory } from '@xspot-app/common';
import {
  provideTranslocoScope,
  TranslocoDirective,
  TranslocoService,
} from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import {
  AvailableLanguages,
  defaultLanguage,
} from '../../consts/available-languages.const';
import { LanguageCurrencySelectComponent } from '../language-currency-select/language-currency-select.component';

@Component({
  selector: 'xspot-app-top-bar',
  standalone: true,
  imports: [
    CommonModule,
    AvatarModule,
    ButtonModule,
    DropMenuComponent,
    DropdownModule,
    TranslocoDirective,
    FormsModule,
    LanguageCurrencySelectComponent,
  ],
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class TopBarComponent implements OnInit {
  @Input() public customerLanguages = false;
  @Input({ required: true }) public menuItems: MenuTopBar[] = [];
  @Output() public isOpenChange = new EventEmitter<boolean>();
  public selectedLanguage = defaultLanguage;

  public isOpen$!: Observable<boolean>;
  public languages = Object.entries(AvailableLanguages).map(([key, value]) => ({
    name: key,
    value,
  }));

  constructor(
    public menuService: MenuService,
    private translocoService: TranslocoService
  ) {}

  public ngOnInit(): void {
    this.isOpen$ = this.menuService.currentMenuState;
  }

  public toggleMenu(): void {
    this.menuService.toggleLeftMenu();
  }

  public changeLanguage($event: DropdownChangeEvent): void {
    const value = $event.value as Language;
    this.translocoService.setActiveLang(value);
  }
}
