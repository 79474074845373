<ng-container *transloco="let t">
  <div class="fb-login-button">
    <p-button
      styleClass="gap-1"
      (onClick)="logInWithFb()"
      [loading]="(isLoading$ | async) ?? false"
      [label]="t('loginForm.fb')"
      icon="pi pi-facebook"
      [outlined]="true"
      severity="secondary"></p-button>
  </div>
</ng-container>
