import {
  LanguageControlGroup,
  Nullable,
  OfferMediaKindEnum,
  OfferProductFormEnum,
  OfferProductPriceKindEnum,
  ProductKindEnum,
  SlotKindEnum,
  VolumePriceDiscountKindEnum,
} from '@xspot-app/common';
import { LocationsDto } from '@xspot-app/shared/locations';

export interface CustomerOfferDetailsDto {
  id: string;
  hasUpsells: boolean;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  description: LanguageControlGroup;
  legalContent: LanguageControlGroup;
  locations: LocationsDto;
  media: CustomerOfferDetailsMediaDto[];
  legalFiles: CustomerOfferDetailsFileDto[];
  variants: CustomerOfferDetailsVariantDto[];
}

export interface CustomerOfferDetailsMediaDto {
  kind: OfferMediaKindEnum;
  uri: string;
  Ordinal: number;
}

export interface CustomerOfferDetailsFileDto {
  uri: string;
  fileName: string;
  ordinal: number;
}

export interface CustomerOfferDetailsVariantDto {
  id: string;
  name: LanguageControlGroup;
  information: LanguageControlGroup;
  ordinal: number;
  entries: CustomerOfferDetailsVariantEntryDto[];
  products: CustomerOfferDetailsVariantProductDto[];
}

export interface CustomerOfferDetailsVariantEntryDto {
  header: LanguageControlGroup;
  description: LanguageControlGroup;
  ordinal: number;
}

export interface CustomerOfferDetailsVariantProductDto {
  id: string;
  productKind: ProductKindEnum;
  displayName: LanguageControlGroup;
  productForm: OfferProductFormEnum;
  minAmount?: Nullable<number>;
  maxAmount?: Nullable<number>;
  startAmount?: Nullable<number>;
  amount?: Nullable<number>;
  ordinal: number;
  isMinimalAmount: Nullable<boolean>;
  priceKind: OfferProductPriceKindEnum;
  singlePrice: Nullable<number>;
  volumePrice: Nullable<CustomerOfferDetailsVariantProductVolumePriceDto>;
  segmentPrices: CustomerOfferDetailsVariantProductSegmentPriceDto[];
}

export interface CustomerOfferDetailsVariantProductVolumePriceDto {
  id: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: CustomerOfferDetailsVariantProductVolumePriceItemDto[];
  documents: CustomerOfferDetailsFileDto[];
}

export interface CustomerOfferDetailsVariantProductVolumePriceItemDto {
  from: number;
  discountValue: number;
}

export interface CustomerOfferDetailsVariantProductSegmentPriceDto {
  price: number;
  slotKind: SlotKindEnum;
}
