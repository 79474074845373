import { LanguageControlGroup } from '@xspot-app/common';

export interface VoucherDto {
  id: string;
  code: string;
  offerName: LanguageControlGroup;
  offerVariantName: LanguageControlGroup;
  validTo: string;
  isUpsellIncludedInOffer: boolean;
  products: ProductVariantDto[];
  locationIds: string[];
}

export interface ProductVariantDto {
  productName: LanguageControlGroup;
  quantity: number;
}
