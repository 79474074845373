import { CanActivateFn, Router } from '@angular/router';
import { UiContext } from '../enums';
import { inject } from '@angular/core';

// this guard allows access to a route only if the current context is listed in allowedContexts
export const contextGuardFn: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const lowerCaseContext = state.url.split('/')?.[1];

  if (!lowerCaseContext) {
    return true;
  }

  const context = (lowerCaseContext[0].toUpperCase() +
    lowerCaseContext.substring(1)) as UiContext;

  const allowedContexts = route.data['allowedContexts'] as UiContext[];
  const hasAccess = allowedContexts.includes(context);

  return hasAccess ? true : router.createUrlTree(['/']);
};
