import { LanguageControlGroup, OfferCategoryEnum } from '@xspot-app/common';
import { PriceDto } from '../dtos';
import { OfferProductKindEnum } from '../enums';

export interface CustomerOfferModel {
  id: string;
  coverMobileUri: string;
  coverDesktopUri: string;
  name: LanguageControlGroup;
  teaser: LanguageControlGroup;
  ordinal: number;
  price: PriceDto;
  isMinimumPrice: boolean;
  isPricePerMinute: boolean;
  categories: OfferCategoryEnum[];
  locationIds: string[];
  hasAnyPromotion?: boolean;
  offerProductKind: OfferProductKindEnum;
}
