import { Language } from '../../enums';
import { UserAgreementDto } from './user-agreement.dto';

export interface UserProfileDto {
  email: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  language: Language;
  emailConfirmed: boolean | null;
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  agreements: UserAgreementDto[];
}
