import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TranslocoDirective, TranslocoService } from '@ngneat/transloco';
import {
  Base64Util,
  NotificationService,
  UiContextService,
} from '@xspot-app/common';
import { ShareLinkModel } from '../../domain/share-link.model';
import { Store } from '@ngxs/store';
import { CalendarsState, OffersState } from '../../state';

@Component({
  selector: 'xspot-app-offer-share-link',
  standalone: true,
  imports: [ButtonModule, CommonModule, TranslocoDirective],
  templateUrl: './offer-share-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferShareLinkComponent {
  constructor(
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private store: Store,
    private uiContextService: UiContextService
  ) {}

  protected shareLink(): void {
    const offerId = this.store.selectSnapshot(OffersState.offer)?.id;
    const context = this.uiContextService.currentContext$.value;

    if (!offerId) {
      throw Error('Cannot generate share link');
    }

    const selectedDate = this.store.selectSnapshot(CalendarsState.selectedDate);

    const selectedValues: ShareLinkModel = {
      activeLocationId: this.store.selectSnapshot(OffersState.activeLocationId),
      selectedVariantId: this.store.selectSnapshot(
        OffersState.selectedVariantId
      ),
      selectedVariantProducts: this.store.selectSnapshot(
        OffersState.selectedVariantProducts
      ),
      selectedDate: selectedDate ? selectedDate.toISODate() : null,
    };

    const baseUrl = window.location.origin;
    const path = `${context.toLowerCase()}/offers/${offerId}/link/`;
    const encodedValues = Base64Util.encode(selectedValues);

    const shareUrl = new URL(`${baseUrl}/${path}${encodedValues}`);

    navigator.clipboard.writeText(shareUrl.toString()).then(() => {
      this.notificationService.showSuccessNotification(
        this.translocoService.translate('offers.copyLinkSuccess')
      );
    });
  }
}
