import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, distinctUntilChanged, filter, map } from 'rxjs';
import { ActivationEnd, Router } from '@angular/router';
import { UiContext } from '../enums';

@Injectable()
export class UiContextService {
  public currentContext$ = new BehaviorSubject<UiContext>(
    (localStorage.getItem('context') as UiContext | null) ?? UiContext.Flyspot
  );

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd &&
            Object.keys(event.snapshot.params).length > 0
        ),
        map(event =>
          event instanceof ActivationEnd ? event.snapshot.params : {}
        ),
        map(params => params['context']),
        filter(Boolean),
        distinctUntilChanged(),
        map(context => context[0].toUpperCase() + context.slice(1))
      )
      .subscribe(context => {
        if (context !== this.currentContext$.getValue()) {
          this.switchContext(context);
        }
      });

    this.switchContext(this.currentContext$.getValue());
  }

  public switchContext(context: UiContext, reload = false): void {
    const themeLink = this.document.getElementById('theme') as HTMLLinkElement;
    const favicon = this.document.querySelector('#appIcon') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${context.toLowerCase()}.css`;
      localStorage.setItem('context', context);

      this.currentContext$.next(context);

      if (favicon) {
        favicon.href = `assets/${context.toLowerCase()}/logo.svg`;
      }

      if (reload) {
        if (this.router.url.startsWith('/location')) {
          this.router.navigate(['/']).then(() => {
            window.location.reload();
          });
        } else {
          window.location.reload();
        }
      }
    }
  }
}
