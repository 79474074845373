import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarDaysComponent } from './calendar-days/calendar-days.component';
import { CalendarDaySlotsComponent } from './calendar-day-slots/calendar-day-slots.component';
import { CalendarStepEnum } from '../../enums';
import { Select, Store } from '@ngxs/store';
import { Calendars, CalendarsState } from '../../state';
import { Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { NgLetModule } from 'ng-let';
import { CalendarService } from '@xspot-app/common';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-calendar',
  standalone: true,
  imports: [
    CommonModule,
    CalendarDaySlotsComponent,
    CalendarDaysComponent,
    NgLetModule,
  ],
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent {
  @Output() public loadCalendarDaySlots = new EventEmitter<{
    date: DateTime;
  }>();
  @Output() public loadCalendarDays = new EventEmitter<{
    startDate: DateTime;
    endDate: DateTime;
  }>();

  protected CalendarStepEnum = CalendarStepEnum;

  @Select(CalendarsState.currentStep)
  public currentStep$!: Observable<CalendarStepEnum>;

  constructor(
    private store: Store,
    private calendarService: CalendarService
  ) {}

  protected onSelectedDate(date: DateTime): void {
    this.store.dispatch(
      new Calendars.UpdateCurrentStep(CalendarStepEnum.DaySlots)
    );
    this.store.dispatch(new Calendars.UpdateSelectedDate(date));
    const days = this.calendarService.getDaysInWeek(date);

    this.loadCalendarDays.emit({
      startDate: days[0],
      endDate: days[days.length - 1],
    });
    this.loadCalendarDaySlots.emit({ date });
  }
}
