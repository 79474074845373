<div *transloco="let t" [formGroup]="form">
  <div class="px-4 pb-8 mb-8 lg:mb-0 lg:pb-4">
    <ng-container formArrayName="bookingMembers">
      <div class="flex flex-column gap-4">
        @if (
          bookingMembersFormArray.length > 1 &&
          !isMinimalAmountBookingGlobal &&
          maxUpsellMinutes > 0
        ) {
          <div
            class="w-full p-2 text-oswald text-2xl font-semibold uppercase booking-description">
            {{ t('userProfile.members.additionalMinutesToAllocate') }}:
            {{ usedUpsellMinutes }}/{{ maxUpsellMinutes }}
          </div>
        }

        @if (isMinimalAmountBookingGlobal) {
          <div
            class="w-full p-2 text-oswald text-2xl font-semibold uppercase booking-description">
            {{
              t('userProfile.members.proInfo', {
                from: from.substring(0, 5),
                to: to.substring(0, 5),
                minutes: minutes,
              })
            }}
          </div>
        }
        <div class="booking-members flex flex-column gap-4 w-full">
          <div
            class="border-round-lg p-3 border-100 border-1 w-full relative"
            *ngFor="
              let booking of bookingMembersFormArray.controls;
              let i = index
            "
            [formGroupName]="i">
            <div
              *ngIf="!booking.get('id')?.value"
              class="absolute right-0 top-0 mt-2 mr-2">
              <button
                class="bg-none outline-none border-none cursor-pointer text-color-secondary py-1 flex align-items-center gap-2"
                (click)="removeBookingMember(i)">
                <i class="trash-icon"></i>
              </button>
            </div>

            <div class="text-oswald text-2xl font-semibold uppercase">
              {{ t('userProfile.members.member') }} #{{ i + 1 }}
            </div>
            <div class="pt-3 text-color-secondary text-sm">
              {{ t('userProfile.members.ageGroup') }}:
              <span class="text-primary font-semibold">{{
                t(
                  'userProfile.members.ageGroupKind.' +
                    booking.get('ageGroupKind')?.value
                )
              }}</span>

              <div formArrayName="members" class="members-wrap">
                <div
                  *ngFor="
                    let memberControl of getMembersArray(booking).controls;
                    let j = index
                  "
                  [formGroupName]="j">
                  <div class="grid px-2 py-3">
                    <div class="col-12 lg:col-4">
                      <div class="form-control">
                        <span class="p-float-label">
                          <input
                            class="w-full"
                            pInputText
                            id="name-{{ i }}-{{ j }}"
                            formControlName="name"
                            type="text" />
                          <label for="name-{{ i }}-{{ j }}">{{
                            t('userProfile.members.name')
                          }}</label>
                        </span>

                        <xspot-app-form-control-errors
                          [control]="memberControl.get('name')"
                          [prefix]="'form'"></xspot-app-form-control-errors>
                      </div>
                    </div>
                    <div class="col-12 lg:col-4">
                      <div class="form-control">
                        <span class="p-float-label">
                          <input
                            class="w-full"
                            pInputText
                            id="surname-{{ i }}-{{ j }}"
                            formControlName="surname"
                            type="text" />
                          <label for="surname-{{ i }}-{{ j }}">{{
                            t('userProfile.members.surname')
                          }}</label>
                        </span>

                        <xspot-app-form-control-errors
                          [control]="memberControl.get('surname')"
                          [prefix]="'form'"></xspot-app-form-control-errors>
                      </div>
                    </div>
                    <div class="col-12 lg:col-4">
                      <div class="form-control">
                        <span class="p-float-label">
                          <input
                            class="w-full"
                            pInputText
                            id="email-{{ i }}-{{ j }}"
                            formControlName="email"
                            type="text" />
                          <label for="email-{{ i }}-{{ j }}">{{
                            t('userProfile.members.email')
                          }}</label>
                        </span>

                        <xspot-app-form-control-errors
                          [control]="memberControl.get('email')"
                          [prefix]="'form'"></xspot-app-form-control-errors>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              @if (!isMinimalAmountBookingGlobal) {
                <p-divider class="mb-3 mt-0"></p-divider>
                <div class="flex flex-column lg:flex-row gap-4 text-sm">
                  <div class="flex flex-row gap-4">
                    <div>
                      {{ t('userProfile.members.minutes') }}:
                      <span class="text-primary font-semibold"
                        >{{ booking.get('totalNumberOfMinutes')?.value }}
                        {{ t('userProfile.members.min') }}</span
                      >
                    </div>
                    <div *ngIf="booking.get('numberOfPromotionMinutes')?.value">
                      {{ t('userProfile.members.promotionalMinutes') }}:
                      <span class="text-primary font-semibold"
                        >{{ booking.get('numberOfPromotionMinutes')?.value }}
                        {{ t('userProfile.members.min') }}</span
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="maxUpsellMinutes > 0"
                    class="flex flex-row align-items-center justify-content-start lg:justify-content-center">
                    <span
                      >{{
                        t('userProfile.members.additionalFlightMinute')
                      }}:&nbsp;</span
                    >
                    @if (bookingMembersFormArray.length > 1) {
                      <div
                        class="flex gap-1 align-items-center justify-content-start lg:justify-content-center">
                        <button
                          class="bg-white border-none"
                          [disabled]="
                            booking.get('numberOfUpsellMinutes')?.value === 0
                          "
                          (click)="decrementUpsell(booking)">
                          <i class="pi pi-minus-circle"></i>
                        </button>
                        <div class="font-semibold">
                          {{ booking.get('numberOfUpsellMinutes')?.value }}
                        </div>
                        <button
                          class="bg-white border-none"
                          [disabled]="usedUpsellMinutes >= maxUpsellMinutes"
                          (click)="incrementUpsell(booking)">
                          <i class="pi pi-plus-circle"></i>
                        </button>
                      </div>
                    } @else {
                      <span class="text-primary font-semibold"
                        >{{ booking.get('numberOfUpsellMinutes')?.value }}
                        {{ t('userProfile.members.min') }}</span
                      >
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="isMinimalAmountBookingGlobal"
        class="mt-4 w-full flex justify-content-end">
        <p-button
          class="w-full lg:w-auto"
          styleClass="px-5 w-full"
          [disabled]="bookingMembersFormArray.length >= 20"
          [label]="t('userProfile.members.addMember')"
          (onClick)="addBookingMember()">
        </p-button>
      </div>
    </ng-container>
  </div>

  <div
    class="fixed bottom-0 p-4 w-full bg-white lg:relative flex justify-content-end gap-4 lg:gap-2 border-top-1 border-100">
    <p-button
      class="w-6 lg:w-auto"
      severity="secondary"
      [outlined]="true"
      styleClass="w-full px-5"
      [label]="t('userProfile.members.cancel')"
      (onClick)="close()">
    </p-button>
    <p-button
      class="w-6 lg:w-auto"
      styleClass="w-full px-5"
      [disabled]="usedUpsellMinutes !== maxUpsellMinutes"
      [label]="t('userProfile.members.save')"
      (onClick)="save()">
    </p-button>
  </div>
</div>
