import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  CreateExternalUserDto,
  CreateUserDto,
  GusCompanyDataDto,
  UpdateUserAgreementsDto,
  UpdateUserProfileDto,
  UserProfileDto,
} from '../dtos';
import { environment } from '../environments/environment';
import { Language } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public create(data: CreateUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users`, {
      ...data,
    });
  }

  public fetchProfile(): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(
      `${environment.apiUrl}/api/users/profile`
    );
  }

  public updateProfile(data: Partial<UpdateUserProfileDto>): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/profile`, {
      ...data,
    });
  }

  public updateLanguage(language: Language): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/language`, {
      language,
    });
  }

  public createExternalUser(data: CreateExternalUserDto): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/users/external`, {
      ...data,
    });
  }

  public updateAgreements(data: UpdateUserAgreementsDto): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/api/users/agreements`, {
      ...data,
    });
  }

  public getGusCompanyData(taxNumber: string): Observable<GusCompanyDataDto> {
    return this.http.get<GusCompanyDataDto>(
      `${environment.apiUrl}/api/users/getGusCompanyData/${taxNumber}`
    );
  }
}
