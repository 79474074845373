import {
  CreateUserDto,
  CreateExternalUserDto,
  UpdateUserProfileDto,
  UpdateUserAgreementsDto,
} from '@xspot-app/common';

export namespace User {
  export class Create {
    public static readonly type = '[Users] Create';
    constructor(public payload: CreateUserDto) {}
  }

  export class CreateExternal {
    public static readonly type = '[Users] Create External';
    constructor(public payload: CreateExternalUserDto) {}
  }

  export class FetchProfile {
    public static readonly type = '[Users] Fetch Profile';
  }

  export class UpdateProfile {
    public static readonly type = '[Users] Update Profile';
    constructor(public payload: Partial<UpdateUserProfileDto>) {}
  }

  export class UpdateAgreements {
    public static readonly type = '[Users] Update Agreements';
    constructor(public payload: UpdateUserAgreementsDto) {}
  }

  export class GetGusCompanyData {
    public static readonly type = '[Users] Get Gus company data';
    constructor(public taxNumber: string) {}
  }
}
