<ng-container *transloco="let t">
  <div class="weekday-abbreviations" [ngClass]="className">
    <div class="weekday">
      {{ t('calendar.days.monday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.tuesday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.wednesday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.thursday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.friday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.saturday') }}
    </div>
    <div class="weekday">
      {{ t('calendar.days.sunday') }}
    </div>
  </div>
</ng-container>
