import { CurrencyEnum } from '../../enums/currency.enum';

export namespace Currency {
  export class SetCurrency {
    public static readonly type = '[Currency] Set Currency';
    constructor(public currency: CurrencyEnum) {}
  }

  export class LoadCurrencyFromStorage {
    public static readonly type = '[Currency] Load Currency From Storage';
  }

  export class GetCurrencyRate {
    public static readonly type = '[Currency] Get Currency Rate';
  }
}
