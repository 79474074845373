import { Language } from '../../enums';

export interface UpdateUserProfileDto {
  phoneNumberPrefix: string;
  phoneNumberWithoutPrefix: string;
  name: string;
  surname: string;
  dateOfBirth: string | null;
  language: Language;
}
