import { HttpParams } from '@angular/common/http';
import { GetListWithOrderDto, FiltersDto, GetListDto } from '../dtos';
import { Nullable } from './types';

export function pagedListWithOrderQueryParams<T extends FiltersDto>(
  dto: GetListWithOrderDto<Nullable<string>, T>
): HttpParams {
  let params = pagedListQueryParams(dto);

  if (dto.orderBy)
    params = params.set('orderBy', dto.orderBy).set('sortOrder', dto.sortOrder);

  return params;
}

export function pagedListQueryParams<T extends FiltersDto>(
  dto: GetListDto<T>
): HttpParams {
  let params: HttpParams = new HttpParams()
    .set('pageSize', dto.pageSize)
    .set('pageNumber', dto.pageNumber);

  if (dto.filter) {
    Object.entries(dto.filter)
      .filter(([, value]) => value)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(element => {
            params = params.append(`filter.${key}`, element);
          });
        } else {
          params = params.set(`filter.${key}`, value as string);
        }
      });
  }

  return params;
}
