import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  Nullable,
  OfferProductPriceKindEnum,
  OfferProductFormEnum,
  ProductKindEnum,
  VolumePriceDiscountKindEnum,
} from '@xspot-app/common';
import { PriceSegment } from './price-segment.model';

export interface VariantProductForm {
  id?: FormControl<Nullable<string>>;
  productId: FormControl<string>;
  name: FormControl<string>;
  kind: FormControl<ProductKindEnum>;
  creditKind: FormControl<Nullable<string>>;
  productAmount: FormControl<Nullable<number>>;
  expireAt: FormControl<Nullable<string>>;
  ageGroupKind: FormControl<Nullable<string>>;
  priceKind: FormControl<OfferProductPriceKindEnum>;
  productForm: FormControl<OfferProductFormEnum>;
  amount: FormControl<Nullable<number>>;
  minAmount: FormControl<Nullable<number>>;
  maxAmount: FormControl<Nullable<number>>;
  startAmount: FormControl<Nullable<number>>;
  singlePrice: FormControl<Nullable<number>>;
  priceSegments: FormArray<FormGroup<PriceSegment>>;
  volumePrice: FormControl<VariantProductVolumePriceForm | null>;
  isMinimalAmount: FormControl<Nullable<boolean>>;
}

export interface VariantProductVolumePriceForm {
  id: string;
  name: string;
  discountKind: VolumePriceDiscountKindEnum;
  items: VariantProductVolumePriceItemForm[];
}

export interface VariantProductVolumePriceItemForm {
  from: number;
  to: number | null;
  discountValue: number;
}
