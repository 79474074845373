import { Nullable, OfferCategoryEnum } from '@xspot-app/common';
import { CustomerOfferDetailsModel } from '../../domain/customer-offer-details.model';
import { CustomerOfferModel } from '../../domain/customer-offer.model';
import { ShoppingPathStepEnum } from '../../enums';
import { VariantProductModel } from '../../domain/variant-product.model';
import {
  CurrentPriceModel,
  CustomerUpsellModel,
  OfferPromotionModel,
  SelectedUpsellModel,
} from '../../domain';

export interface OffersStateModel {
  currentStep: ShoppingPathStepEnum;
  offers: CustomerOfferModel[];
  activeLocationId: string;
  activeCategory: OfferCategoryEnum;
  offer: Nullable<CustomerOfferDetailsModel>;
  selectedVariantId: string;
  selectedVariantProducts: VariantProductModel[];
  currentPrice: CurrentPriceModel;
  basketItemId: Nullable<string>;
  upsells: CustomerUpsellModel[];
  selectedUpsells: SelectedUpsellModel[];
  promotions: OfferPromotionModel[];
}
