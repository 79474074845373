import {
  ChangeDetectorRef,
  Directive,
  HostListener,
  Input,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Button } from 'primeng/button';
import { NotificationService, TranslationsService } from '../services';
import { GetTranslationDto } from '../dtos/get-translation.dto';
import { Language } from '../enums';
import { finalize, forkJoin, map, Observable, of, tap, throwError } from 'rxjs';

@Directive({
  selector: '[translateLanguageFormGroups]',
  standalone: true,
})
export class TranslateLanguageFormGroupsDirective {
  @Input() public translateLanguageFormGroups!: FormGroup<{
    [key: string]: FormControl<string>;
  }>[];

  constructor(
    private button: Button,
    private translationsService: TranslationsService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('click', ['$event']) public async onClick(): Promise<void> {
    if (this.button.loading) {
      return;
    }
    this.button.loading = true;

    forkJoin(
      this.translateLanguageFormGroups.map(formGroup =>
        this.translate(formGroup)
      )
    )
      .pipe(
        finalize(() => {
          this.button.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  private translate(
    translateLanguageFormGroup: FormGroup<{
      [key: string]: FormControl<string>;
    }>
  ): Observable<null> {
    const plFormControl = translateLanguageFormGroup.controls['pl'];
    if (!plFormControl) {
      return throwError(
        () =>
          new Error(
            "The 'pl' form control is missing in the translateLanguageFormGroup"
          )
      );
    }
    const plText = plFormControl.value;
    if (!plText) {
      this.notificationService.showWarningNotification(
        'notification.warning.fillPolishTranslationFirst'
      );
      return of(null);
    }
    const dto: GetTranslationDto = {
      text: plText,
      language: Language.pl,
    };
    return this.translationsService.translate(dto).pipe(
      tap(response => {
        Object.keys(response).forEach(languageKey => {
          const control = translateLanguageFormGroup.controls[languageKey];
          if (control) {
            control.setValue(response[languageKey]);
          }
        });
      }),
      map(() => null)
    );
  }
}
