import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformDetectorService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  public isIos(): boolean {
    if (isPlatformServer(this.platformId)) {
      return false;
    }

    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
