import { Nullable } from '@xspot-app/common';
import {
  GetCalendarDaysDto,
  GetCalendarDaySlotsDto,
  GetVoucherCalendarDaysDto,
  GetVoucherCalendarDaySlotsDto,
} from '../../dtos';
import { CalendarTypeEnum } from '../../enums';
import { CalendarStepEnum } from '../../enums/calendar-step.enum';
import { DateTime } from 'luxon';
import { SelectedSlotModel } from '../../domain';

export namespace Calendars {
  export class UpdateCurrentStep {
    public static readonly type = '[Calendars] Update Current Step';
    constructor(public payload: CalendarStepEnum) {}
  }

  export class UpdateType {
    public static readonly type = '[Calendars] Update type';
    constructor(public payload: Nullable<CalendarTypeEnum>) {}
  }

  export class UpdateSelectedDate {
    public static readonly type = '[Calendars] Update Selected Date';
    constructor(public payload: DateTime) {}
  }

  export class UpdateCurrentYearMonth {
    public static readonly type = '[Calendars] Update Current Year-Month';
    constructor(public payload: DateTime) {}
  }

  export class UpdateSelectedSlots {
    public static readonly type = '[Calendars] Update Selected Slots';
    constructor(public payload: SelectedSlotModel[]) {}
  }

  export class LoadDays {
    public static readonly type = '[Calendars] Load Days';
    constructor(public dto: GetCalendarDaysDto) {}
  }

  export class LoadVoucherDays {
    public static readonly type = '[Calendars] Load Voucher Days';
    constructor(public dto: GetVoucherCalendarDaysDto) {}
  }

  export class LoadDaySlots {
    public static readonly type = '[Calendars] Load Day Slots';
    constructor(public dto: GetCalendarDaySlotsDto) {}
  }

  export class LoadVoucherDaySlots {
    public static readonly type = '[Calendars] Load Voucher Day Slots';
    constructor(public dto: GetVoucherCalendarDaySlotsDto) {}
  }

  export class ResetDaySlots {
    public static readonly type = '[Calendars] Reset Day Slots';
  }

  export class ResetCalendarState {
    public static readonly type = '[Calendars] Reset Calendar State';
  }
}
