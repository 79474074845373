import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EXTERNAL_URL } from '@xspot-app/common';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'xspot-app-voucher-button',
  standalone: true,
  imports: [CommonModule, TranslocoDirective],
  templateUrl: './voucher-button.component.html',
  styleUrl: './voucher-button.component.scss',
})
export class VoucherButtonComponent {
  @Input() public isSticky: boolean = false;
  protected readonly EXTERNAL_URL = EXTERNAL_URL;
}
