import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CurrencyStateModel } from './currency.state-model';
import { Currency } from './currency.actions';
import { CurrencyService } from '../../services/currency.service';
import { CurrencyEnum } from '../../enums/currency.enum';
import { tap } from 'rxjs/operators';
import { produce } from 'immer';
import { NbpCurrencyDto } from '../../dtos';
import { EMPTY, Observable } from 'rxjs';

const CURRENCY_STATE_TOKEN = new StateToken<CurrencyStateModel>('currency');

@State<CurrencyStateModel>({
  name: CURRENCY_STATE_TOKEN,
  defaults: {
    selectedCurrency: CurrencyEnum.PLN,
    currencyRateCache: 0,
  },
})
@Injectable()
export class CurrencyState {
  constructor(private currencyService: CurrencyService) {}

  @Selector()
  public static getSelectedCurrency(state: CurrencyStateModel): CurrencyEnum {
    return state.selectedCurrency;
  }

  @Selector()
  public static getCurrencyRate(state: CurrencyStateModel): number {
    return state.currencyRateCache;
  }

  @Action(Currency.SetCurrency)
  public setCurrency(
    ctx: StateContext<CurrencyStateModel>,
    action: Currency.SetCurrency
  ): void {
    ctx.patchState({ selectedCurrency: action.currency });
    localStorage.setItem('selectedCurrency', action.currency);

    if (action.currency !== CurrencyEnum.PLN) {
      ctx.dispatch(new Currency.GetCurrencyRate());
    }
  }

  @Action(Currency.LoadCurrencyFromStorage)
  public loadCurrencyFromStorage(ctx: StateContext<CurrencyStateModel>): void {
    const storedCurrency = localStorage.getItem(
      'selectedCurrency'
    ) as CurrencyEnum;
    if (storedCurrency) {
      ctx.patchState({ selectedCurrency: storedCurrency });

      if (storedCurrency !== CurrencyEnum.PLN) {
        ctx.dispatch(new Currency.GetCurrencyRate());
      }
    }
  }

  @Action(Currency.GetCurrencyRate, { cancelUncompleted: true })
  public getCurrencyRate(
    ctx: StateContext<CurrencyStateModel>
  ): Observable<NbpCurrencyDto> {
    const state = ctx.getState();

    if (state.currencyRateCache !== 0) {
      return EMPTY;
    }

    return this.currencyService.getCurrencyRate(state.selectedCurrency).pipe(
      tap((data: NbpCurrencyDto) => {
        const rate = data.rates[0].mid;
        const newState = produce(state, draft => {
          draft.currencyRateCache = rate;
        });
        ctx.setState(newState);
      })
    );
  }
}
