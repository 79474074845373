import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CurrencyFormatPipe } from '@xspot-app/shared/ui';
import { CalendarTypeEnum } from '../../../../enums';
import { Nullable } from '@xspot-app/common';
import { CalendarsState } from '../../../../state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'xspot-app-slot-price',
  standalone: true,
  imports: [CommonModule, CurrencyFormatPipe, TranslocoDirective],
  templateUrl: './slot-price.component.html',
  styleUrl: './slot-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlotPriceComponent {
  @Input({ required: true }) public originalPrice!: number;
  @Input() public actualPrice?: Nullable<number>;
  @Input() public isMinimalPrice?: Nullable<boolean>;
  @Input() public isSlotSelected: boolean = false;

  @Select(CalendarsState.type)
  public calendarType$!: Observable<CalendarTypeEnum>;

  public CalendarTypeEnum = CalendarTypeEnum;
}
