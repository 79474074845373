export enum ProductKindEnum {
  Reservation = 'Reservation',
  DeepspotReservation = 'DeepspotReservation',
  Credit = 'Credit',
  Supplement = 'Supplement',
  Instructor = 'Instructor',
  Insurance = 'Insurance',
  SsiCertificate = 'SsiCertificate',
  EntryPack = 'EntryPack',
}
