import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import {
  highlightFormErrors,
  PhoneNumber,
  PrefixModel,
  scopeLoaderFactory,
  StringUtil,
} from '@xspot-app/common';
import { DropdownModule } from 'primeng/dropdown';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import CountryCodes from '../../data/country-calling-codes.json';

@UntilDestroy()
@Component({
  selector: 'xspot-app-phone-number',
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    InputTextModule,
    ReactiveFormsModule,
    DropdownModule,
    TranslocoDirective,
  ],
  templateUrl: './phone-number.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberComponent),
      multi: true,
    },
    provideTranslocoScope({
      scope: 'phoneNumber',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
})
export class PhoneNumberComponent implements ControlValueAccessor, OnInit {
  @Input() public phoneNumberTranslationKey = 'phoneNumber.phoneNumber';

  public phoneNumberForm: FormGroup<{
    prefix: FormControl<string>;
    number: FormControl<string>;
  }>;

  public prefixesData: PrefixModel[] = [];

  public get phoneNumberControl(): AbstractControl {
    return this.phoneNumberForm.controls.number;
  }

  public get prefixControl(): AbstractControl {
    return this.phoneNumberForm.controls.prefix;
  }
  public onTouched: () => void = () => {
    highlightFormErrors(this.phoneNumberForm);
  };
  constructor(private fb: NonNullableFormBuilder) {
    this.phoneNumberForm = this.fb.group({
      prefix: [StringUtil.empty],
      number: [StringUtil.empty],
    });
  }

  public ngOnInit(): void {
    this.prefixesData = CountryCodes;
  }

  public registerOnChange(fn: (data: PhoneNumber) => void): void {
    this.phoneNumberForm.valueChanges
      .pipe(
        untilDestroyed(this),
        map(() => this.getFormData())
      )
      .subscribe((value: PhoneNumber) => fn(value));
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.phoneNumberForm.disable();
    } else {
      this.phoneNumberForm.enable();
    }
  }

  public writeValue(data: PhoneNumber): void {
    this.phoneNumberForm.setValue(data);
  }
  private getFormData(): PhoneNumber {
    return {
      prefix: this.phoneNumberForm.controls.prefix.value,
      number: this.phoneNumberForm.controls.number.value,
    };
  }

  public formatPhoneNumber(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const pattern = /^[0-9]*$/;

    if (!pattern.test(inputValue)) {
      this.phoneNumberControl.patchValue(
        inputValue.replace(/[^0-9]/g, StringUtil.empty)
      );
    }
  }
}
