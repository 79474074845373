import { Injectable } from '@angular/core';
import { StateToken, State, Selector, Action, StateContext } from '@ngxs/store';
import { Observable, map, take, tap, withLatestFrom } from 'rxjs';
import { LocationsService } from '../services';
import { LocationsStateModel } from './locations.state-model';
import { CustomerLocationsListDto } from '../dtos';
import { Locations } from './locations.actions';
import { UiContext, UiContextService } from '@xspot-app/common';

const LOCATIONS_STATE_TOKEN = new StateToken<LocationsStateModel>('locations');

@State<LocationsStateModel>({
  name: LOCATIONS_STATE_TOKEN,
  defaults: {
    all: [],
    filteredByContext: [],
  },
})
@Injectable()
export class LocationsState {
  constructor(
    private locationsService: LocationsService,
    private uiContextService: UiContextService
  ) {}

  @Selector()
  public static all(state: LocationsStateModel): CustomerLocationsListDto[] {
    return state.filteredByContext;
  }

  @Action(Locations.GetAll)
  public getAllLocations(
    ctx: StateContext<LocationsStateModel>
  ): Observable<CustomerLocationsListDto[]> {
    const state = ctx.getState();

    if (state.all.length > 0) {
      return this.uiContextService.currentContext$.pipe(
        take(1),
        map(context => this.filterLocationsByContext(state.all, context)),
        tap(filteredLocations => {
          ctx.patchState({
            filteredByContext: filteredLocations,
          });
        })
      );
    }

    return this.locationsService.getLocationsList().pipe(
      withLatestFrom(this.uiContextService.currentContext$),
      tap(([allLocations]) => {
        ctx.patchState({
          all: allLocations,
        });
      }),
      map(([allLocations, context]) =>
        this.filterLocationsByContext(allLocations, context)
      ),
      tap(filteredLocations => {
        ctx.patchState({
          filteredByContext: filteredLocations,
        });
      })
    );
  }

  private filterLocationsByContext(
    locations: CustomerLocationsListDto[],
    context: UiContext
  ): CustomerLocationsListDto[] {
    const contextLowerCaseData = context.toLocaleLowerCase();

    return locations.filter(
      location => location.format.toLocaleLowerCase() === contextLowerCaseData
    );
  }
}
