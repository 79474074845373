import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BookingDto,
  GetBookingMembersDto,
  UpdateBookingMembersDto,
} from '../dtos';
import { environment } from '@xspot-app/common';

@Injectable({
  providedIn: 'root',
})
export class BookingMembersService {
  constructor(private http: HttpClient) {}

  public updateBookingMembers(
    orderItemId: string,
    payload: UpdateBookingMembersDto
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.apiUrl}/api/bookingMembers/${orderItemId}`,
      payload
    );
  }

  public getBookingMembers(
    orderItemId: string,
    params: GetBookingMembersDto
  ): Observable<BookingDto[]> {
    const queryParams: HttpParams = new HttpParams()
      .set('date', params.date)
      .set('from', params.from)
      .set('to', params.to);

    return this.http.get<BookingDto[]>(
      `${environment.apiUrl}/api/bookingMembers/${orderItemId}`,
      {
        params: queryParams,
      }
    );
  }
}
