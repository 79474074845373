<ng-container *transloco="let t">
  <ng-container *ngLet="currentStep$ | async as currentStep">
    <ng-container *ngLet="selectedDate$ | async as selectedDate">
      <ng-container *ngLet="isDaySelected() as isSelected">
        <ng-container *ngLet="isLoading$ | async as isLoading">
          <div
            class="calendar-day"
            [ngClass]="{
              'calendar-day--loading': isLoading && currentStep,
              'calendar-day--grayed-out':
                (calendarDay.isCurrentMonth === false &&
                  currentStep === CalendarStepEnum.Days) ||
                calendarDay.status !== CalendarDayStatus.Available,
              'calendar-day--current':
                currentStep === CalendarStepEnum.DaySlots &&
                selectedDate &&
                selectedDate.equals(calendarDay.date),
            }"
            [isItemSelected]="isSelected"
            (click)="calendarDayClick.emit()">
            <div class="text-oswald text-700 font-semibold">
              {{ calendarDay.date.day }}
            </div>
            <div class="icons">
              @if (!!calendarDay.productPromotionName) {
                <i class="pi pi-gift"></i>
              }
            </div>

            @switch (calendarDay.status) {
              @case (CalendarDayStatus.Closed) {
                <span class="hidden xl:block text-sm">
                  {{ t('calendar.closed') }}
                </span>
              }
              @case (CalendarDayStatus.Unavailable) {
                <span class="hidden xl:block text-sm">
                  {{ t('calendar.unavailable') }}
                </span>
              }
              @case (CalendarDayStatus.Available) {
                <span class="hidden xl:block text-sm text-600 font-semibold">
                  @if (calendarDay.actualPrice) {
                    <xspot-app-slot-price
                      [originalPrice]="calendarDay.originalPrice!"
                      [actualPrice]="calendarDay.actualPrice"
                      [isMinimalPrice]="calendarDay.isMinimalPrice" />
                  }
                </span>
              }
            }

            @if (calendarDay.hasAnyPromotion) {
              <div class="promotion-bar xl:hidden"></div>
            }
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
