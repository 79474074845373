import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, tap } from 'rxjs';
import {
  ErrorMessage,
  getErrorMessage,
  highlightFormErrors,
  MaxFileSize_MB,
  MediaFileKindEnum,
  scopeLoaderFactory,
  StringUtil,
  YoutubeUtil,
} from '@xspot-app/common';
import { Nullable } from 'primeng/ts-helpers';
import { TableModule } from 'primeng/table';
import { provideTranslocoScope, TranslocoDirective } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadErrorCodes, FileUploadErrorMessages } from '../../consts';
import { DragAndDropDirective } from '../../directives';
import { LibraryFilesDto } from '../../dtos';
import { FilesLibraryUploadService } from '../../services';
import { FormControlErrorsComponent } from '../form-control-errors/form-control-errors.component';
import { MediaFileControlGroup } from '../../models';

@UntilDestroy()
@Component({
  selector: 'xspot-app-media-files',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    TranslocoDirective,
    DragAndDropDirective,
    ReactiveFormsModule,
    FormControlErrorsComponent,
  ],
  templateUrl: './media-files.component.html',
  providers: [
    provideTranslocoScope({
      scope: 'ui',
      loader: scopeLoaderFactory(
        (lang: string) => import(`../../i18n/${lang}.json`)
      ),
    }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaFilesComponent implements OnInit {
  @Input({ required: true })
  public formArray!: FormArray<FormGroup<MediaFileControlGroup>>;
  @Input({ required: true })
  public maxFilesCount!: number;
  @Input({ required: true })
  public maxMovieUrlLength!: number;

  protected selectedElement: Nullable<FormGroup<MediaFileControlGroup>>;
  protected MediaFileKindEnum = MediaFileKindEnum;
  protected MaxFileSize_MB = MaxFileSize_MB;
  protected errorMessage$ = new BehaviorSubject<Nullable<ErrorMessage>>(null);
  protected isLoading$ = new BehaviorSubject<boolean>(false);
  protected movieForm: FormControl<string | null> = this.fb.control(null, [
    Validators.maxLength(this.maxMovieUrlLength),
    Validators.required,
  ]);

  constructor(
    private filesLibraryUploadService: FilesLibraryUploadService,
    private fb: FormBuilder,
    private nfb: NonNullableFormBuilder,
    public sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.formArray.valueChanges
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  protected removeMedia(index: number): void {
    if (this.formArray.at(index) === this.selectedElement) {
      this.selectedElement = null;
    }
    this.formArray.removeAt(index);
    this.onRowReorderHandler();
  }

  protected onRowReorderHandler(): void {
    this.formArray.controls.forEach((group, index) => {
      group.patchValue({ ordinal: index });
    });
  }

  protected uploadFile(event: Event): void {
    const fileInput: HTMLInputElement = event.target as HTMLInputElement;

    if (!fileInput.files || !fileInput.files[0]) return;

    const file: File = fileInput.files[0];
    this.sendFile(file);
    fileInput.value = StringUtil.empty;
  }

  protected uploadFiles(files: FileList): void {
    const file: File = files[0];
    this.sendFile(file);
  }

  protected submitMovieForm(): void {
    this.errorMessage$.next(null);
    if (this.movieForm.invalid) {
      highlightFormErrors(this.movieForm);
      return;
    }
    if (this.formArray.length >= this.maxFilesCount) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.TooManyFiles,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      return;
    }
    const videoId = YoutubeUtil.getVideoId(this.movieForm.value!);
    if (!videoId) {
      this.movieForm.setErrors({
        invalidYoutubeMovieUrl:
          'The provided URL is invalid. Please ensure it is a valid YouTube video URL',
      });
      return;
    }

    const uri = `https://www.youtube.com/embed/${videoId}`;
    const media = this.fb.group<MediaFileControlGroup>({
      id: this.fb.control(null),
      fileId: this.fb.control(null),
      ordinal: this.nfb.control(this.formArray.length),
      uri: this.nfb.control(uri),
      safeUri: this.nfb.control(
        this.sanitizer.bypassSecurityTrustResourceUrl(uri)
      ),
      name: this.fb.control(null),
      kind: this.nfb.control(MediaFileKindEnum.Movie),
    });
    this.formArray.push(media);
    this.selectedElement = this.formArray.at(this.formArray.length - 1);
    this.movieForm.reset();
  }

  private sendFile(file: File): void {
    this.errorMessage$.next(null);
    if (this.formArray.length >= this.maxFilesCount) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.TooManyFiles,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      return;
    }

    if (file.size / 1024 / 1024 > MaxFileSize_MB) {
      this.errorMessage$.next(
        getErrorMessage(
          FileUploadErrorCodes.ValidationFailed,
          FileUploadErrorMessages,
          () => 'error'
        )
      );
      return;
    }

    this.isLoading$.next(true);
    this.filesLibraryUploadService
      .upload([file])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (result: LibraryFilesDto) => {
          this.isLoading$.next(false);
          const media = this.fb.group<MediaFileControlGroup>({
            id: this.fb.control(null),
            fileId: this.fb.control(result[0].id),
            ordinal: this.nfb.control(this.formArray.length),
            uri: this.nfb.control(result[0].uri),
            safeUri: this.nfb.control(result[0].uri),
            name: this.fb.control(result[0].originalFileName),
            kind: this.nfb.control(MediaFileKindEnum.Picture),
          });
          this.formArray.push(media);
          this.selectedElement = this.formArray.at(this.formArray.length - 1);
        },
        error: ({ error }) => {
          this.isLoading$.next(false);
          this.errorMessage$.next(
            getErrorMessage(
              error.errorCode,
              FileUploadErrorMessages,
              () => 'error'
            )
          );
        },
      });
  }
}
