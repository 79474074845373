import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringUtil, ZeroOrTwoDecimalDigitsPipe } from '@xspot-app/common';
import { CurrencyState } from '../state/currency';
import { CurrencyEnum } from '../enums';

@Pipe({
  name: 'currencyFormatPipe',
  standalone: true,
  pure: false,
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(
    private store: Store,
    private zeroOrTwoDecimalDigitsPipe: ZeroOrTwoDecimalDigitsPipe
  ) {}

  public transform(value?: number | string | null): Observable<string | null> {
    if (value == null) return of(null);

    const numericValue = typeof value === 'string' ? parseFloat(value) : value;

    return combineLatest([
      this.store.select(CurrencyState.getSelectedCurrency),
      this.store.select(CurrencyState.getCurrencyRate),
    ]).pipe(
      map(([selectedCurrency, currencyRate]) => {
        const amount =
          selectedCurrency === CurrencyEnum.EUR
            ? numericValue / currencyRate
            : numericValue;
        const currency = selectedCurrency === CurrencyEnum.EUR ? '€' : 'zł';
        const formattedValue =
          this.zeroOrTwoDecimalDigitsPipe.transform(amount);
        return formattedValue
          ? `${formattedValue}${StringUtil.nonBreakingSpace}${currency}`
          : null;
      })
    );
  }
}
