<ng-container *transloco="let t">
  <div class="form-control">
    <span class="p-float-label">
      <p-dropdown
        #dropdown
        [options]="options"
        [formControl]="formControl"
        [virtualScroll]="true"
        [virtualScrollItemSize]="40"
        [lazy]="true"
        [emptyMessage]="t('form.noOffers')"
        [emptyFilterMessage]="t('form.noOffers')"
        [overlayOptions]="getOverlayOptions()"
        [filter]="true"
        (onLazyLoad)="loadLazy($event)"
        styleClass="w-full">
        <ng-template pTemplate="filter">
          <xspot-app-search-bar
            class="w-full"
            [clearOnDestroy]="true"
            (search)="search($event)">
          </xspot-app-search-bar>
        </ng-template>
      </p-dropdown>
      <label>{{ t('form.offer') }}</label>
    </span>
    <xspot-app-form-control-errors
      [control]="formControl"
      [prefix]="'form'"></xspot-app-form-control-errors>
  </div>
</ng-container>
