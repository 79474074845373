import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TranslocoService } from '@ngneat/transloco';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'xspot-app-offer-basket-error-dialog',
  standalone: true,
  imports: [ButtonModule, CommonModule],
  templateUrl: './offer-basket-error-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferBasketErrorDialogComponent {
  protected buttonText =
    this.dynamicDialogConfig.data?.buttonText ??
    this.translocoService.translate(
      'offers.details.selectOfferDetailsPanel.backToOffers'
    );

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private translocoService: TranslocoService,
    private router: Router
  ) {}

  protected handleError(): void {
    this.dynamicDialogRef.close();

    if (this.dynamicDialogConfig.data?.buttonAction) {
      this.dynamicDialogConfig.data.buttonAction();
    } else {
      this.router.navigate(['/offers']);
    }
  }
}
