import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { SidebarNotificationTypeEnum } from './sidebar-notification-type.enum';
import { StringUtil } from '@xspot-app/common';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'xspot-app-sidebar-notification',
  standalone: true,
  templateUrl: './sidebar-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNotificationComponent implements OnInit {
  @Input({ required: true }) public message!: string;
  @Input() public sidebarNotificationType: SidebarNotificationTypeEnum =
    SidebarNotificationTypeEnum.Information;
  @Input() public iconClassName: string = 'pi pi-exclamation-circle';

  public SidebarNotificationTypeEnum = SidebarNotificationTypeEnum;
  public colorClass: string = StringUtil.empty;

  public ngOnInit(): void {
    this.colorClass = this.getColorClass();
  }

  private getColorClass(): string {
    switch (this.sidebarNotificationType) {
      case SidebarNotificationTypeEnum.Default:
        return 'text-600';
      case SidebarNotificationTypeEnum.Information:
        return 'text-info';
      default:
        return StringUtil.empty;
    }
  }
}
