import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackOfficeCalendarService {
  public currentDate: Date = new Date();

  public getMonthData(
    year: number,
    month: number
  ): {
    year: number;
    month: number;
    days: { date: Date; isInCurrentMonth: boolean }[];
  } {
    const startOfMonth: Date = new Date(year, month, 1);
    const endOfMonth: Date = new Date(year, month + 1, 0);

    const daysInMonth: { date: Date; isInCurrentMonth: boolean }[] =
      this.getDaysInCurrentMonth(startOfMonth, endOfMonth);

    const daysInPrevMonth: { date: Date; isInCurrentMonth: boolean }[] =
      this.getDaysFromPreviousMonth(startOfMonth).map(d => ({
        date: d,
        isInCurrentMonth: false,
      }));
    const daysInNextMonth: { date: Date; isInCurrentMonth: boolean }[] =
      this.getDaysForNextMonth(endOfMonth).map(d => ({
        date: d,
        isInCurrentMonth: false,
      }));

    return {
      year,
      month,
      days: [...daysInPrevMonth, ...daysInMonth, ...daysInNextMonth],
    };
  }

  private getDaysFromPreviousMonth(startOfMonth: Date): Date[] {
    const year = startOfMonth.getFullYear();
    const month = startOfMonth.getMonth();
    const firstDayOfWeek = startOfMonth.getDay() || 7;

    const daysToDisplay = firstDayOfWeek - 1;

    const prevMonthEndDate = new Date(year, month, 0);
    const startDate = new Date(prevMonthEndDate);
    startDate.setDate(prevMonthEndDate.getDate() - daysToDisplay + 1);

    return Array.from(
      { length: daysToDisplay },
      (_, i) => new Date(year, month - 1, startDate.getDate() + i)
    );
  }

  private getDaysInCurrentMonth(
    startOfMonth: Date,
    endOfMonth: Date
  ): { date: Date; isInCurrentMonth: boolean }[] {
    const days: { date: Date; isInCurrentMonth: boolean }[] = [];
    for (
      let day: Date = new Date(startOfMonth);
      day <= endOfMonth;
      day.setDate(day.getDate() + 1)
    ) {
      days.push({ date: new Date(day), isInCurrentMonth: true });
    }
    return days;
  }

  private getDaysForNextMonth(endOfMonth: Date): Date[] {
    const lastDayOfWeek: number = endOfMonth.getDay() || 7;
    const days: Date[] = [];
    if (lastDayOfWeek < 7) {
      for (let i = 1; i <= 7 - lastDayOfWeek; i++) {
        days.push(
          new Date(endOfMonth.getFullYear(), endOfMonth.getMonth() + 1, i)
        );
      }
    }

    return days;
  }

  public goToNextMonth(): Date {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    return new Date(this.currentDate);
  }

  public goToPreviousMonth(): Date {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    return new Date(this.currentDate);
  }
}
