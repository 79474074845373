import { FormControl } from '@angular/forms';
import { LocationControlModel, Nullable } from '@xspot-app/common';
import { CustomerOfferDetailsVariantModel } from './customer-offer-details.model';

export interface OfferFormModel {
  location: FormControl<Nullable<LocationControlModel>>;
  variant: FormControl<Nullable<CustomerOfferDetailsVariantModel>>;
  variantProducts: FormControl<Record<string, number>>;
  upsells: FormControl<Record<string, number>>;
  selectedDate: FormControl<Nullable<Date>>;
}
