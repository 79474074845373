<div class="flex sm:gap-2" *transloco="let t">
  <p-button
    [text]="true"
    [rounded]="true"
    severity="secondary"
    class="mr-2 sm:mr-0"
    styleClass="p-2 sm:p-3"
    (click)="op.toggle($event)">
    <div class="flag" [innerHTML]="selectedLangFlag$ | async"></div>
    <span
      class="lowercase text-inter sm:block hidden"
      *ngIf="selectedCurrencyObject$ | async as selectedCurrency">
      {{ selectedCurrency.flag }}
    </span>
    <i class="hidden sm:block pi pi-chevron-down"></i>
  </p-button>

  <p-overlayPanel #op>
    <div
      class="flex flex-column lagnuage-panel"
      *ngIf="selectedLang$ | async as selectedLang">
      <div class="font-semibold p-3 border-bottom-1 border-100 mb-2">
        {{ t('languageSelect.currency') }}
      </div>
      <p-button
        *ngFor="let currency of CURRENCIES"
        (click)="op.toggle($event); changeCurrency(currency)"
        [text]="true"
        severity="secondary"
        styleClass="w-full"
        [ngClass]="{
          highlight: currency.code === (selectedCurrencyObject$ | async)?.code,
        }">
        <span
          class="capitalize font-normal text-inter"
          [ngClass]="{
            highlight:
              currency.code === (selectedCurrencyObject$ | async)?.code,
          }">
          {{ t(currency.name) }}
          <span class="lowercase">({{ currency.flag }})</span>
        </span>
      </p-button>

      <div class="font-semibold p-3 border-bottom-1 border-100 mb-2">
        {{ t('languageSelect.language') }}
      </div>
      <p-button
        *ngFor="let lang of LANGUAGES"
        (click)="op.toggle($event); changeLanguage(lang.code)"
        [text]="true"
        severity="secondary"
        styleClass="w-full"
        [ngClass]="{ highlight: lang.code === selectedLang }">
        <div class="flag" [innerHTML]="lang.flag"></div>
        <span
          class="capitalize font-normal text-inter"
          [ngClass]="{ highlight: lang.code === selectedLang }">
          {{ lang.name }}
        </span>
      </p-button>
    </div>
  </p-overlayPanel>
</div>
