<ng-container *transloco="let t">
  <div class="bg-white">
    <div
      class="pb-4 text-base lg:text-2xl text-oswald font-semibold flex justify-content-between align-items-center">
      <div class="h-full flex align-items-center my-3">
        <button
          class="flex align-items-center px-0 bg-white text-color-secondary text-left text-sm border-none cursor-pointer"
          (click)="changeMonth(-1)">
          <i class="pi pi-arrow-left"></i>
        </button>
      </div>

      <div class="flex justify-content-center align-items-center gap-2">
        <ng-container *ngIf="currentYearMonth$ | async as currentYearMonth">
          <span>
            {{
              t(
                'calendar.months.' + Months[currentYearMonth.month - 1]
                  | lowercase
              ) | uppercase
            }}
            {{ currentYearMonth.year }}
          </span>
        </ng-container>

        <p-button
          icon="pi pi-chevron-down"
          [rounded]="true"
          [text]="true"
          severity="secondary"
          (click)="op.toggle($event)"></p-button>

        <p-overlayPanel #op styleClass="border-round-2xl">
          <p-calendar
            appendTo="body"
            [inline]="true"
            [defaultDate]="calendarMinAndDefaultDate"
            [minDate]="calendarMinAndDefaultDate"
            (onMonthChange)="handleMonthChange($event)"
            (onSelect)="onSelectDate($event)"></p-calendar>
        </p-overlayPanel>
      </div>

      <div class="h-full flex align-items-center my-3">
        <button
          class="flex align-items-center px-0 bg-white text-color-secondary text-left text-sm border-none cursor-pointer"
          (click)="changeMonth(1)">
          <i class="pi pi-arrow-right"></i>
        </button>
      </div>
    </div>

    <xspot-app-weekday-abbreviations></xspot-app-weekday-abbreviations>

    <ng-container *ngIf="calendarDays$ | async as calendarDays; else spinner">
      <ng-container *ngIf="(isLoading$ | async) === false; else spinner">
        <div class="calendar-grid lg:gap-2">
          <xspot-app-calendar-day
            *ngFor="let day of calendarDays.days"
            [calendarDay]="day"
            (calendarDayClick)="onSelectDay(day)"></xspot-app-calendar-day>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="spinner justify-content-center flex align-items-center">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>
