import { AbstractControl } from '@angular/forms';
import { formDateFormat } from '../consts';
import { DateTime } from 'luxon';

export function DateLessThanValidator(compareTo: DateTime) {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const inputValue: string = control.value;
    const compareToValue: string = compareTo.toFormat(formDateFormat);

    if (!inputValue || !compareToValue) {
      return null;
    }

    const parsedControlDate = DateTime.fromFormat(inputValue, formDateFormat);
    const parsedCompareToDate = DateTime.fromFormat(
      compareToValue,
      formDateFormat
    );

    if (
      parsedControlDate.isValid &&
      parsedCompareToDate.isValid &&
      parsedControlDate >= parsedCompareToDate
    ) {
      return {
        dateLessThan: true,
      };
    }

    return null;
  };
}
